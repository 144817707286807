import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import CookieConsent from 'react-cookie-consent'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom'
import KycPallete from './assets/images/kyc-pallete.gif'
import { loginStatus } from './redux/actions'
import SignIn from './views/Account/SignIn'
import SignUp from './views/Account/SignUp'
import Merci from './views/Account/Merci'

import KycDone from './views/Presale/KycDone'
import KycStart from './views/Presale/KycStart'
import Wallet from './views/Presale/Wallet'
import NFTWallet from './views/Presale/NFTWallet'

import Layout from './views/Layout'

const wrappedRoutes = () => (
  <Switch>
    <Route path='/merci' component={Merci} />
    <Route path='/kyc-step-2' component={KycDone} />
    <Route path='/kyc-step-1' component={KycStart} />
    <Route path='/wallet' component={Wallet} />
    <Route path='/nft-wallet' component={NFTWallet} />
  </Switch>
)

function PrivateRoute ({ component: Component, user, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (authed && user && user.validated) === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/log_in', state: { from: props.location } }} />}
    />
  )
}

const AppRouter = (props) => {
  const history = useHistory()
  const { t } = useTranslation('common')
  const { authed, onCheckLoginStatus, authenticatingUser, user, loading } = props
  const consentBtnStyle = { color: '#F29FFF', background: '#141452', borderRadius: 4, fontSize: '13px', marginRight: 100 }
  
  useEffect(() => {
    if(!authenticatingUser && authed) {
      onCheckLoginStatus(history)
    }
  }, [])
  

  return (
    <Router>
      <div className='App'>
        {
          loading &&
          <div className='loader-wrapper'>
            {
              <img src={KycPallete} alt='img here' height={140} style={{ opacity: '1' }}/>
            }
          </div>
        }
        <Layout>
          <CookieConsent
            location='bottom'
            buttonText={t('ternoaCookieButtonText')}
            cookieName='ternoaCookieConsent'
            style={{ background: '#05043E' }}
            buttonStyle={consentBtnStyle}
            expires={150}
          >
            {t('ternoaCookieTitle')}
          </CookieConsent>
          
          <Switch>
            <Route exact path='/' component={SignUp} />
            <Route exact path='/sign_up' component={SignUp} />
            <Route exact path='/log_in' component={SignIn} />              
            <PrivateRoute authed={authed} user={user} path='/' component={wrappedRoutes} />
          </Switch>        
        </Layout>
      </div>
    </Router>
  )
}

const mapStateToProps = ({ auth, global }) => {
  // ...
  const { isLogged, checkingUserStatus, user } = auth
  const { isFetching } = global
  return {
    authed: isLogged,
    user,
    authenticatingUser: checkingUserStatus,
    loading: isFetching
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckLoginStatus: (history) => dispatch(loginStatus(history))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRouter)