import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-responsive-modal'
import { useTranslation } from 'react-i18next'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3 from 'web3'
import img from '../../../assets/images/Vector3.png'
import { addMetamaskAddressAction } from '../../../redux/actions'
require('dotenv').config()

const walletConector = null// new WalletConnectProvider({ infuraId: process.env.REACT_APP_INFURA_KEY, bridge:'https://uniswap.bridge.walletconnect.org' })

const Wallet = (props) => {
  const { history, user, onAddMetamaskAddressAction } = props
  const { t } = useTranslation('common')
  const [openModal, setOpenModal] = useState(false)

  const onOpenModal = () => setOpenModal(true)
  const onCloseModal = () => setOpenModal(false)

  const gotoWallet = (chainId, accounts, isWallet) => {
    history.push({
      pathname: '/nft-wallet',
      state: { accounts: accounts, chainId: chainId, isWallet: isWallet },
      wallConnect: walletConector
    })
  }

  const subscribeToEvents = () => {
    let chainTypye = '0x1'
    let accts = []
    if (!walletConector) {
      return
    }
    walletConector.on('accountsChanged', (accounts) => {
      accts = accounts
      gotoWallet(chainTypye, accts, true)
    })

    // Subscribe to chainId change
    walletConector.on('chainChanged', (chainId) => {
      chainTypye = '0x' + chainId
      gotoWallet(chainTypye, accts, true)
    })

    walletConector.on('connect', () => {
      // eslint-disable-next-line no-console
      console.log('connector.on(\'connect\')')
    })
    walletConector.on('disconnect', (code, reason) => {
      // eslint-disable-next-line no-console
      console.log('on disconnect', code, reason)
    })
  }



  const walletConnectInit = async () => {
    setTimeout(function () {
      if (walletConector) {
        // subscribe to events
        subscribeToEvents()
      }
    }, 1000)
  }

  const onWalletConnect = async () => {
    // if does not have a connector
    if (!walletConector) {
      const walletConector = new WalletConnectProvider({ infuraId: process.env.REACT_APP_INFURA_KEY })
      subscribeToEvents()
      // create new session
      try {
        await walletConector.enable()
      } catch (err) {
        //if user closes modal
        window.location.reload()
      }
    } else if (walletConector) {
      try {
        await walletConector.enable()
      } catch (err) {
        window.location.reload()
      }
    } else {
      // console.log('onWalletConnect else', walletConector.accounts)
      let chainId = '0x' + walletConector.chainId
      let accounts = walletConector.accounts
      gotoWallet(chainId, accounts, true)
    }
  }

  const metamaskInit = () => {
    setTimeout(function () {
      const ethereumButton = document.querySelector('.enableEthereumButton')
      if (ethereumButton) {
        ethereumButton.addEventListener('click', () => {
          //Will Start the metamask extension
          if (window.ethereum) {
            window.ethereum.request({ method: 'eth_requestAccounts' }).then(resp => {
              const formatedAddress = Web3.utils.toChecksumAddress(resp[0])
              onAddMetamaskAddressAction({ address: formatedAddress, userid: user.user_id })
              gotoWallet('', resp, false)
            })
          } else {
            onOpenModal()
          }
        })
      }
    }, 3000)
  }

  useEffect(() => {
    metamaskInit()
    walletConnectInit()
  }, [])

  return (
    <div className='wallet-container my-8 sm:my-0'>
      <Modal open={openModal} onClose={onCloseModal} center classNames={{
        overlay: 'modalOverlay',
        modal: 'commonModal',
      }}>
        <div className='modalContainer'>
          <div className='header'>
            <h3>{t('walletPopTitle')}</h3>
            <p>{t('walletPopPara')}</p>
            <button><a href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en' target='_blank' rel='noreferrer'>{t('walletPopLink')}</a></button>
          </div>
        </div>
      </Modal>
      <div className='wallet-wrapper'>
        <img src={img} alt='Ternoa-Wallet' />
        <h1>
          <span>{t('walletConnectTitle1')}</span>
          <span>{t('walletConnectTitle2')}</span>
        </h1>
        <p>
          <span>{t('walletConnectPara1')}</span>
          <span>{t('walletConnectPara2')}</span>
          <span>{t('walletConnectPara3')}</span>
        </p>
        <div className='wallet-buttons'>
          <button className='enableEthereumButton'>{t('metamaskConnectButton')}</button>
          {/* <button onClick={onWalletConnect}>{t('walletConnectButton')}</button> */}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  // ...
  const { user } = auth
  return {
    user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddMetamaskAddressAction: (payload) => dispatch(addMetamaskAddressAction(payload)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wallet)
