import React from 'react'
import { useTranslation } from 'react-i18next'
import Discord from '../../assets/images/footer/discord'
import Telegram from '../../assets/images/footer/telegram'
import Instagram from '../../assets/images/footer/instagram'
import Twitter from '../../assets/images/footer/twitter'
import EnFlag from '../../assets/images/en.png'
import FrFlag from '../../assets/images/fr.png'
import { connect } from 'react-redux'

const Footer = ({ isLogged }) => {
  const { i18n } = useTranslation('common')
  const { t } = useTranslation('common')
  const changeLanguage = (event) => {
    let lng = event.target.value
    i18n.changeLanguage(lng)
  }
  return (
    <footer className='footer pb-4'>
      <div className='flex flex-col md:flex-row md:justify-between items-center'>
        <div className='flex items-center py-2'>
          <a href='https://discord.com/invite/cNZTGtGJNR' target="_blank" rel="noreferrer noopener" className='px-2'>
            <Discord />
          </a>
          <a href='https://t.me/ternoadiscussions' target="_blank" rel="noreferrer noopener" className='px-2'>
            <Telegram />
          </a>
          <a href='https://twitter.com/ternoa_' target="_blank" rel="noreferrer noopener" className='px-2'>
            <Twitter />
          </a>
          <a href='https://www.instagram.com/ternoa_/' target="_blank" rel="noreferrer noopener" className='px-2'>
            <Instagram />
          </a>
        </div>
        <div className='flex flex-col md:flex-row items-center gap-4 py-2 text-white'>
          <a className="text-center hover:no-underline hover:text-white" target="_blank" rel="noreferrer noopener" href='https://ternoa-2.gitbook.io/terms-of-use-ternoa'>{t('footer-terms')}</a>
          {/* <a className="text-center hover:no-underline hover:text-white" target="_blank" rel="noreferrer noopener" href='https://ternoahelp.zendesk.com/hc/en-150/articles/360018924977-General-conditions-of-use'>{t('footer-privacy')}</a> */}
          {isLogged &&
          <div className='select-lang-wrapper'>
            <img src={i18n.language === 'en' ? EnFlag : FrFlag} alt='en flag'/>
            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
            <select value={i18n.language} onChange={changeLanguage}>
              <option value='en'>{t('headerSelectLangEn')}</option>
              <option value='fr'>{t('headerSelectLangFr')}</option>
            </select>
          </div>}
        </div>
      </div>
      <hr className='custom-y-divider my-4'/>
      <div className='text-sm text-white'>
        <p className='text-gray-400 text-sm text-center pb-4'>{t('footer-tagline')}</p>
      </div>
    </footer>
  )
}
const mapStateToProps = ({ auth }) => {
  const { user, isLogged } = auth
  return {
    user,
    isLogged
  }
}

export default connect(mapStateToProps)(Footer)
