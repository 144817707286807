import React from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import img from '../../../assets/images/Vector.png'
import { useTranslation } from 'react-i18next'

const getInfoBySessionId = async (history, sessionId) => {
  const headers = {
    'Api-Key': '2tNYsfio11UnpXnHo3U2PTjzDISzRPWD',
    'Session-Id': sessionId
  }
  await fetch('https://workflow-api.synaps.io/v2/session/info?sandbox=false&alias=mika', {
    method: 'GET',
    headers: headers
  }).then(
    response => response.json()
  ).then(() => {
    history.push('/wallet')
    return
  })
}

const KycDone = (props) => {
  const { history } = props
  const { t } = useTranslation('common')
  const search = useLocation().search
  const sessionId = new URLSearchParams(search).get('sess-id')

  const onStartKyc = () => {
    getInfoBySessionId(history, sessionId)
  }

  return (
    <div className='sign-in-wrapper'>
      <div className='form-wrapper contents'>
        <img src={img} alt='img here' />
        <div className='title mb20'>{t('kycFinishTitle')}</div>
        <p className='mb100'>{t('kycFinishPara1')}
          <br />{t('kycFinishPara2')}
          <br />{t('kycFinishPara3')}</p>
        <div className='contain'>
          <button className='sign-in-btn' onClick={onStartKyc}>{t('kycFinishButton')}</button>
        </div>
      </div>
    </div>
  )

}

const mapStateToProps = ({ auth }) => {
  const { user } = auth
  return {
    user
  }
}

export default connect(
  mapStateToProps,
)(KycDone)
