import { hexToU8a, isHex } from '@polkadot/util'
import { decodeAddress, encodeAddress } from '@polkadot/keyring'

export const verifyResponse = response => {
  return response.ok ? response : Promise.reject(response)
}

export const handleError = error => {
  return error.ok ? error : Promise.reject(error)
}

export function ellipsis(s, n = 10) {
  if (s.length < n) return s
  const start = s.slice(0, n / 2 - 1)
  const end = s.slice(-(n / 2 - 2))
  return start + '...' + end
}

export const isValidTernoaAddress = (address) => {
  try {
    encodeAddress(isHex(address) ? hexToU8a(address) : decodeAddress(address))
    return true
  } catch (error) {
    return false
  }
}