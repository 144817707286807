import React from 'react'
import { connect } from 'react-redux'
import { SynapsClient } from '@synaps-io/synaps-client-sdk-js'
import { useTranslation } from 'react-i18next'
import { logoutUser, updateUserAccount, loginStatus } from '../../../redux/actions'
import capsImg from '../../../assets/images/caps-image.png'
import validationImg from '../../../assets/images/validation-picto.png'

const Merci = (props) => {
  const { history, user, onUpdateUserProfile } = props
  const { t } = useTranslation('common')
  const onStartKyc = async () => {
    if (user && user.status === 'CREATED' && user.sessionkyc) {
      const Synaps = new SynapsClient(user.sessionkyc, 'workflow')
      Synaps.init()
      Synaps.on('finish', () => {
        // Do something
        onUpdateUserProfile({ id: user.id, status: 'PENDING' })
      })
      Synaps.on('close', () => {
        onUpdateUserProfile({ id: user.id, status: 'PENDING' })
      })
    } else {
      history.push('/wallet')
    }
  }

  const onContinueMyKyc = async () => {
    if (user && user.sessionkyc) {
      const Synaps = new SynapsClient(user.sessionkyc, 'workflow')
      Synaps.init()
      Synaps.on('finish', () => {
        // Do something
        onUpdateUserProfile({ id: user.id, status: 'PENDING' })
      })
      Synaps.on('close', () => {
        onUpdateUserProfile({ id: user.id, status: 'PENDING' })
      })
    }
  }

  return (
    <div className='bg-custom-primary thanks-hero my-10 sm:my-0'>
      <div className='h-screen flex'>
        <div className='align-middle text-center m-auto merci text-white px-4 sm:px-0'>
          <div className='flex justify-center'>
            <img src={capsImg} alt='img here' className='items-center'/>
          </div>
          <h1 className='text-5xl font-semibold'>{t('privateSale')}</h1>
          <a href='/' className='text-gray-400 pt-2'>{t('thankYouSubtitle')}</a>
          <div className='thanks-card'>
            <div className='thanks-card-container py-12 px-8'>
              <div className='flex justify-center'>
                <img src={validationImg} alt='img here' />
              </div>
              <h1 className='text-5xl font-semibold mt-4'>{t('thankYou')}</h1>
              <p className='pt-12 text-lg font-bold'>{t('walletConnectTitle')}</p>
              <div className='pt-2 text-gray-400 text-sm'>
                <span>{t('walletConnectPara1')}</span>
                <p>{t('walletConnectPara2')}</p>
              </div>
              <div className='mt-12'>
                {
                  user && user.status === 'PENDING' &&
                  <button className='btn-rounded btn-primary p-4 w-4/5' id='synaps-btn' onClick={onContinueMyKyc}>{t('continueMyKyc')}</button>
                }
                <button id={user && user.status === 'CREATED' ? 'synaps-btn' : 'non-synaps'} className='btn-rounded btn-primary p-4 w-4/5' onClick={onStartKyc}>
                  {user && user.status === 'FINISHED' ? t('synchronizeWallet') : (user && user.status === 'CREATED') ? t('thanksButton') : t('synchronizeWallet')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth
  return {
    user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (history) => dispatch(logoutUser(history)),
    onUpdateUserProfile: (payload) => dispatch(updateUserAccount(payload)),
    checkKycStatus: (history) => dispatch(loginStatus(history))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Merci)

