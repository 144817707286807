import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import arrowImg from '../../../assets/images/arrow.svg'
import checkoutImg from '../../../assets/images/checkout.svg'
import pendingClaim from '../../../assets/images/pending-claim.svg'

const getWaitingCapsAmount = (vestings) => {
  let amount = 0
  vestings.before.map(vesting => {
    if(vesting.claim) amount += parseFloat(vesting.caps)
  })
  vestings.after.map(vesting => {
    amount += parseFloat(vesting.caps)
  })
  return amount
}
const getClaimedCapsAmount = (tokens) => {
  let amount = 0
  if(tokens.length) {
    for(var i = 0; i < tokens.length; i++) amount += tokens[i].claim ? 0 : parseFloat(tokens[i].caps)
  }
  return amount
}

const VestingBlock = ({ title, data, exchangeRate, claimToken }) => {
  const [expend, setExpend] = useState(true)
  const { t } = useTranslation('common')
  const displayVestingDatas = (data) => {
    let elementsArray = []
    if(data.vestings.before) {
      data.vestings.before.forEach((item, index) => {
        if(item.claim) {
          elementsArray.push(
            <div className='card-claim-outline flex justify-between p-8 text-white' key={`before-${index}`}>
              <div className='flex items-center'>
                <div className="flex flex-col">
                  <span>{item.date}</span>
                  <span className="text-xl font-semibold">{item.caps} CAPS</span>
                </div>
              </div>
              <div className='flex justify-center items-center'>
                <button className='btn btn-claim text-white' onClick={() => {claimToken(item.claim)}}>Claim</button>
              </div>
            </div>
          )
        } else {
          elementsArray.push(
            <div className='card-claim-outline flex justify-between p-8 text-white opacity-50' key={`before-${index}`}>
              <div className='flex items-center'>
                <div className="flex flex-col">
                  <span>{item.date}</span>
                  <span className="text-xl font-semibold">{item.caps} CAPS</span>
                </div>
              </div>
              <div className='flex justify-center items-center'>
                <img src={checkoutImg} alt='checkout'/>
              </div>
            </div>
          )
        }
      })
    }
    if(data.vestings.after) {
      data.vestings.after.forEach((item, index) => {
        elementsArray.push(
          <div className='card-claim-empty-outline flex justify-between p-8 text-white opacity-50' key={`after-${index}`}>
            <div className='flex items-center'>
              <div className="flex flex-col">
                <span>{item.date}</span>
                <span className="text-xl font-semibold">{item.caps} CAPS</span>
              </div>
            </div>
            <div className='flex justify-center items-center'>
              <img src={pendingClaim} className='object-contain' alt='unlock'/>
            </div>
          </div>
        )
      })
    }
    return elementsArray
  }

  return(
    <div className='mt-16 card-primary-100 p-6 sm:p-8 rounded-3xl airdrop-body'>
      <div className='xl:flex justify-between items-center relative'>
        <h3 className='text-white text-2xl font-bold text-left'>{title}</h3>
        <div className='flex sm:gap-x-2 pt-2'>
          <div className='flex flex-col md:flex-row xl:divide-x-2 divide-blue-700'>
            <div className='text-white py-2 md:pr-4 flex flex-wrap items-center'>
              <p className='text-bold'>{t('Home-CAPS-Claimed')}</p>
              <span className='pl-2 text-gradient text-xl sm:text-2xl xl:text-3xl font-bold'>{data?.vestings?.before ? getClaimedCapsAmount(data.vestings.before) : 0}</span>
              <span className='pl-2 text-gray-600 text-sm'> ~${data?.vestings?.before && exchangeRate ? (getClaimedCapsAmount(data.vestings.before) * exchangeRate).toFixed(2) : 0}</span>
            </div>
            <div className='text-white py-2 md:pl-4 flex flex-wrap items-center'>
              <p className='text-bold'>{t('Home-CAPS-To-Claim')}</p>
              <span className='pl-2 text-gradient text-xl sm:text-2xl xl:text-3xl  font-bold'>{data?.vestings ? getWaitingCapsAmount(data.vestings) : 0}</span>
              <span className='pl-2 text-gray-600 text-sm'> ~${data?.vestings && exchangeRate ? (getWaitingCapsAmount(data.vestings) * exchangeRate).toFixed(2) : 0}</span>
            </div>
          </div>
          <button className='px-4 hidden xl:block' onClick={() => {setExpend (!expend )}}>
            <img className={`${expend ? 'transform rotate--180 duration-500' : ' transform rotate-180 duration-500'}`} src={arrowImg} alt='accordian expand icon'/>
          </button>
        </div>
        <button className='mb-collapse-button block xl:hidden' onClick={() => {setExpend (!expend )}}>
          <img className={`${expend ? 'transform rotate--180 duration-500' : ' transform rotate-180 duration-500'}`} src={arrowImg} alt='accordian expand icon'/>
        </button>
      </div>
      <div className={`accordion ${expend ? 'collapse-active' : 'collapse-disable'} `}>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 gap-y-8 pt-8'>
          {data && displayVestingDatas(data)}
        </div>
      </div>
    </div>
  )
}



export default VestingBlock