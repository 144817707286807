import { 
  loginUserRequest, 
  initToken, 
  getUserProfile,
} from '../../services/api'

import { loginUserRequestInit, loginUserSuccess, loginUserFailure, loginStatusSuccess, loginStatusFailure, logoutUserSuccess, showLoader, hideLoader } from '../actions'

export const loginUser = (cred, history) => async (dispatch) => {
  try {  
    cred.username = cred.email
    dispatch(showLoader())
    dispatch(loginUserRequestInit())
    const response = await loginUserRequest(cred)
    if(response) {
      dispatch(hideLoader())
      initToken(response.accessToken)
      localStorage.setItem('kyc_token', response.accessToken)
      dispatch(loginUserSuccess(response))
      if(response.validated) {
        setTimeout(function(){
          history.push('/merci')    
        }, 2000)  
      }
    } else {
      dispatch(hideLoader())
    }
  } catch (error) {
    dispatch(hideLoader())
    if(error) {
      error.json().then(json => dispatch(loginUserFailure(json.message)))  
    }
  }
}

export const logoutUser = (history) => (dispatch) => {
  try {  
    localStorage.setItem('kyc_token', null)
    dispatch(logoutUserSuccess())
    history.push('/log_in')
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('logout user exception: ', error)
    throw error
  }
}

export const loginStatus = (history) => async (dispatch) => {
  try {  
    dispatch(showLoader())
    const response = await getUserProfile()
    dispatch(loginStatusSuccess(response))
    dispatch(hideLoader())
  } catch (error) {
    dispatch(hideLoader())
    if(error && error.status === 401 ) {
      dispatch(logoutUser(history))
    }
  }
}

export const getMe = (history) => async (dispatch) => {
  try {  
    dispatch(showLoader())
    const response = await getUserProfile()
    dispatch(loginStatusSuccess(response))
    dispatch(hideLoader())
  } catch (error) {
    dispatch(hideLoader())
    if(error && error.status === 401 ) {
      dispatch(logoutUser(history))
    }
    dispatch(loginStatusFailure())
  }
}