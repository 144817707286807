/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Web3 from 'web3'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import _ from 'lodash'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch, useSelector, connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import VestingBlock from './vestingBlock'
import { addMetamaskAddressAction, showLoader, hideLoader } from '../../../redux/actions'
import { ellipsis } from '../../../services/utils'
import coinImg from '../../../assets/images/caps-image.png'
import ternoaWhiteSvg from '../../../assets/images/ternoa-white.svg'
import ternoaRedSvg from '../../../assets/images/ternoa-red.svg'
import ethereumWhiteSvg from '../../../assets/images/etherium-white.svg'
import ethereumRedSvg from '../../../assets/images/etherium-red.svg'
import saveSvg from '../../../assets/images/save.svg'
import arrowImg from '../../../assets/images/arrow.svg'
import warning from '../../../assets/images/warning.svg'
import TokenJson from '../../../contract/token.json'
import Caps8 from '../../../assets/gif/caps01.gif'
import Caps7 from '../../../assets/gif/caps02.gif'
import Caps6 from '../../../assets/gif/caps03.gif'
import Caps5 from '../../../assets/gif/caps04.gif'
import Caps4 from '../../../assets/gif/caps05.gif'
import Caps3 from '../../../assets/gif/caps06.gif'
import Caps2 from '../../../assets/gif/caps7.gif'
import Caps1 from '../../../assets/gif/caps8.gif'
import { nftData } from './nftQuantities'
import styles from './Homepage.module.scss'

const WarningSVGIcon = ({ className }) => (
  <svg className={className} width="95" height="87" viewBox="0 0 95 87" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M51.7051 2.66084C49.8837 -0.657487 45.1163 -0.657492 43.2949 2.66083L0.900165 79.8947C-0.854646 83.0916 1.45845 87 5.10529 87H89.8947C93.5415 87 95.8546 83.0916 94.0998 79.8948L51.7051 2.66084ZM47.5 14.32L82.0695 77.3158H12.9305L47.5 14.32ZM42.9091 67.3804C42.9091 64.8449 44.9645 62.7895 47.5 62.7895C50.0355 62.7895 52.0909 64.8449 52.0909 67.3804V67.8828C52.0909 70.4183 50.0355 72.4737 47.5 72.4737C44.9645 72.4737 42.9091 70.4183 42.9091 67.8828V67.3804ZM42.9091 38.3278C42.9091 35.7923 44.9645 33.7368 47.5 33.7368C50.0355 33.7368 52.0909 35.7923 52.0909 38.3278V48.5144C52.0909 51.0498 50.0355 53.1053 47.5 53.1053C44.9645 53.1053 42.9091 51.0498 42.9091 48.5144V38.3278Z"
      fill="white"
    ></path>
  </svg>
)

require('dotenv').config()

let realTimeInterval = null
const getListOfVestingItems = (data) => {
  var items = {
    before: [],
    after: []
  }
  for (let i = 1; i <= 20; i++) {
    if (data[`vesting${i}`] && data[`vesting${i}price`]) {
      dayjs.extend(isSameOrBefore)
      if (dayjs(data[`vesting${i}`]).isSameOrBefore(dayjs()))
        items.before.push({
          date: dayjs(data[`vesting${i}`]).format('DD/MM/YYYY'),
          caps: data[`vesting${i}price`].toFixed(1),
          epoch: data[`vesting${i}Epoch`],
        })
      else if (dayjs(data[`vesting${i}`]).isAfter(dayjs()))
        items.after.push({
          date: dayjs(data[`vesting${i}`]).format('DD/MM/YYYY'),
          caps: data[`vesting${i}price`].toFixed(1),
          epoch: data[`vesting${i}Epoch`],
        })
    }
  }
  return items
}
const getAmountOfNfts = (myPocket) => {
  if (myPocket >= 1 && myPocket < 250) return 1
  else if (myPocket >= 250 && myPocket < 750) return 2
  else if (myPocket >= 750 && myPocket < 1500) return 3
  else if (myPocket >= 1500 && myPocket < 2000) return 4
  else if (myPocket >= 2000 && myPocket < 5000) return 5
  else if (myPocket >= 5000 && myPocket < 10000) return 6
  else if (myPocket >= 10000 && myPocket < 15000) return 7
  else if (myPocket >= 15000) return 8
}

const NFTWallet = (props) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const { history, showLoader, hideLoader } = props
  const { user } = useSelector((state) => state.auth)
  //myPocket below will be used later to display NFTs
  const [myPocket, setMyPocket] = useState(0)
  const [myPocketNFT, setMyPocketNFT] = useState(0)
  const [openClaimModal, setOpenClaimModal] = useState(false)
  const [connectedAccountAddress, setConnectedAccountAddress] = useState('')
  const [connector, setConnector] = useState(null)
  const [network, setNetwork] = useState('ethereum')
  //chainType below will be used later to check if user is on ETH metamask network
  const [chainType, setChainType] = useState('')
  const [vestingAirdrop, setVestingAirdrop] = useState(null)
  const [vestingSeed, setVestingSeed] = useState(null)
  const [vestingCommunity, setVestingCommunity] = useState(null)
  const [vestingPrivate, setVestingPrivate] = useState(null)
  const [vestingStrategic, setVestingStrategic] = useState(null)
  const [vestingPublic, setVestingPublic] = useState(null)
  const [exchangeRate, setExchangeRate] = useState(0)
  const [ternoart, setTernoart] = useState(true)
  let myEuros = 0
  let myCaps = 0
  const onOpenClaimModal = () => setOpenClaimModal(true)
  const onCloseClaimModal = () => setOpenClaimModal(false)
  const [rewardData, setRewardData] = useState({
    totalClaimed: 0.0,
    totalRewarded: 0.0,
    lastDayReward: 0,
    noReward: true,
  })
  const [topBlockData, setTopBlockData] = useState({
    totalClaimed: 0.0,
    APR: 0.0,
    capsToHarvest: 0.0,
    capsInWallet: 0.0
  })
  const getClaimableInfo = async (address, srcType) => {
    const base_url = process.env.REACT_APP_BASE_URL
    const claim_endpoint = `${base_url}/user/getClaimInfo?address=${address}&source=${srcType}`
    const response = await fetch(claim_endpoint)
    const data = await response.json()
    return data
  }
  const subscribeToEvents = (walletConector) => {
    if (!walletConector) {
      return
    }
    walletConector.on('disconnect', (code, reason) => {
      console.log('on disconnect', code, reason)
      history.push('/wallet')
    })
  }
  const loadNftWalletDatas = () => {
    if (props.location.state.isWallet) {
      if (props && props.location && props.location.state && props.location.state.isWallet && props.location.state.accounts[0]
        && props.location.state.accounts[0] !== '0xad99684C7FE0b8CF5C0809C1443554FcB4FCE48B'
        && props.location.state.accounts[0] !== '0xBE97034b366c81e2081b46bf703315aa4e981b76') {
        setConnectedAccountAddress(Web3.utils.toChecksumAddress(props.location.state.accounts[0]))
        setChainType(props.location.state.chainId)
        if (props.location.wallConnect) {
          setConnector(props.location.wallConnect)
          subscribeToEvents(props.location.wallConnect)
        }
      }
    } else {
      if (window.ethereum) {
        // get connected chain id from metamask extension
        window.ethereum.request({ method: 'eth_chainId' }).then(resp => {
          if (resp && resp === '0x1') {
            setChainType('0x1')
          } else if (resp && resp === '0x38') {
            setChainType('0x38')
          }
        })

        // get connected metamask accounts form extention
        window.ethereum.request({ method: 'eth_accounts' }).then(resp => {
          if (resp && resp.length > 0) {
            setConnectedAccountAddress(Web3.utils.toChecksumAddress(resp[0]))
            //SET HERE FOR DEV TESTING
            // setConnectedAccountAddress(resp[0].toLowerCase());
          }
        })
      }
    }
  }
  const initEventsMetamask = () => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        if (accounts && accounts.length > 0
          && accounts !== '0xad99684C7FE0b8CF5C0809C1443554FcB4FCE48B'
          && accounts !== '0xBE97034b366c81e2081b46bf703315aa4e981b76') {
          if (connectedAccountAddress !== accounts[0]) {
            setConnectedAccountAddress(Web3.utils.toChecksumAddress(accounts[0]))
          }
        } else {
          history.push('/wallet')
        }
      })
      window.ethereum.on('chainChanged', function (chain) {
        setChainType(chain.toString())
      })
    }
  }
  const resetMyPockets = () => {
    myEuros = 0
    myCaps = 0
    setMyPocket(0)
  }
  const getExchangeRate = async () => {
    try {
      let res = await axios.get(`${process.env.REACT_APP_BASE_URL}/claim/getExchangeRate`)
      if (res && res.data && res.data.exchangeRate) {
        setExchangeRate(res.data.exchangeRate)
      }
    } catch (err) {
      console.log('unable to get exchange rate')
    }
  }
  const claimToken = async (claim) => {
    const contract_address = process.env.REACT_APP_CONTRACT_ADDRESS
    let web3 = ''
    if (connector && connector.connected) {
      web3 = new Web3(connector)
    } else {
      web3 = new Web3(window.ethereum)
    }
    const capsContract = new web3.eth.Contract(TokenJson['abi'], contract_address)
    const accounts = await web3.eth.getAccounts()
    const account = accounts[0]
    const { amount, from, nonce, proof, to, validity } = claim

    try {
      return await capsContract.methods.claimOffchainGrant(proof, from, to, amount, validity, nonce).send({ from: account })
    } catch (err) {
      return err
    }
  }
  // const claimTokenAll = async (srcType) => {
  //   const contract_address = process.env.REACT_APP_CONTRACT_ADDRESS
  //   let web3 = ''
  //   if (connector && connector.connected) {
  //     web3 = new Web3(connector)
  //   } else {
  //     web3 = new Web3(window.ethereum)
  //   }
  //   const capsContract = new web3.eth.Contract(TokenJson['abi'], contract_address)
  //   const accounts = await web3.eth.getAccounts()
  //   const account = accounts[0]
  //   const base_url = process.env.REACT_APP_BASE_URL
  //   const claim_endpoint = `${base_url}/user/getClaimInfo`
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ address: account, source: srcType })
  //   }
  //   setIsFetchingType(srcType)
  //   const response = await fetch(claim_endpoint, requestOptions)
  //   const data = await response.json()
  //   setIsFetchingType('')

  //   if (data && (data.result === undefined || data.result.length === 0)) {
  //     onOpenClaimModal()
  //   } else if (data && data.result && data.result.length) {
  //     const pendingClaims = data.result
  //     let res = await Promise.all(
  //       pendingClaims.map(async claim => {
  //         const { amount, from, nonce, proof, to, validity } = claim
  //         try {
  //           return await capsContract.methods.claimOffchainGrant(proof, from, to, amount, validity, nonce)
  //             .send({ from: account })
  //         } catch (err) {
  //           return err
  //         }

  //       })
  //     )
  //     //TODO: check each transaction result and proceed accordingly
  //     //console.log('all claims res', res)
  //   }
  // }

  const loadAirdropVestingBlock = (address) => {
    setVestingAirdrop(null)
    fetch(`${process.env.REACT_APP_BASE_URL}/user/getClaimData/?address=${address}&source=airdrop`, {
      method: 'GET',
    })
      .then(resp => resp.json())
      .then(async data => {
        const vestings = getListOfVestingItems(data)
        if (data) {
          const claimAble = await getClaimableInfo(address, 'airdrop')
          claimAble.result.forEach(item => {
            vestings.before[_.findIndex(vestings.before, { epoch: item.epoch })].claim = item
          })
          data.vestings = vestings
          setVestingAirdrop(data)
          myEuros = myEuros + data.valueEur
          setMyPocket(myEuros)
          let tokens = parseFloat(data.tokens)
          myCaps = myCaps + tokens
        }
      })
      .catch(err => err)
  }
  const loadSeedVestingBlock = (address) => {
    setVestingSeed(null)
    fetch(`${process.env.REACT_APP_BASE_URL}/user/getClaimData/?address=${address}&source=seed`, {
      method: 'GET',
    })
      .then(resp => resp.json())
      .then(async data => {
        const vestings = getListOfVestingItems(data)
        if (data) {
          const claimAble = await getClaimableInfo(address, 'seed')
          claimAble.result.forEach(item => {
            vestings.before[_.findIndex(vestings.before, { epoch: item.epoch })].claim = item
          })
          data.vestings = vestings
          setVestingSeed(data)
          myEuros = myEuros + data.valueEur
          setMyPocket(myEuros)
          let tokens = parseFloat(data.tokens)
          myCaps = myCaps + tokens
        }
      })
      .catch(err => err)
  }
  const loadCommunityVestingBlock = (address) => {
    setVestingCommunity(null)
    fetch(`${process.env.REACT_APP_BASE_URL}/user/getClaimData/?address=${address}&source=community`, {
      method: 'GET',
    })
      .then(resp => resp.json())
      .then(async data => {
        const vestings = getListOfVestingItems(data)
        if (data) {
          const claimAble = await getClaimableInfo(address, 'community')
          claimAble.result.forEach(item => {
            vestings.before[_.findIndex(vestings.before, { epoch: item.epoch })].claim = item
          })
          data.vestings = vestings
          setVestingCommunity(data)
          myEuros = myEuros + data.valueEur
          setMyPocket(myEuros)
          let tokens = parseFloat(data.tokens)
          myCaps = myCaps + tokens
        }
      })
      .catch(err => err)
  }
  const loadPrivateVestingBlock = (address) => {
    setVestingPrivate(null)
    fetch(`${process.env.REACT_APP_BASE_URL}/user/getClaimData/?address=${address}&source=private`, {
      method: 'GET',
    })
      .then(resp => resp.json())
      .then(async data => {
        const vestings = getListOfVestingItems(data)
        if (data) {
          const claimAble = await getClaimableInfo(address, 'private')
          claimAble.result.forEach(item => {
            vestings.before[_.findIndex(vestings.before, { epoch: item.epoch })].claim = item
          })
          data.vestings = vestings
          setVestingPrivate(data)
          myEuros = myEuros + data.valueEur
          setMyPocket(myEuros)
          let tokens = parseFloat(data.tokens)
          myCaps = myCaps + tokens
        }
      })
      .catch(err => err)
  }
  const loadStrategicVestingBlock = (address) => {
    setVestingStrategic(null)
    fetch(`${process.env.REACT_APP_BASE_URL}/user/getClaimData/?address=${address}&source=strategic`, {
      method: 'GET',
    })
      .then(resp => resp.json())
      .then(async data => {
        const vestings = getListOfVestingItems(data)
        if (data) {
          const claimAble = await getClaimableInfo(address, 'strategic')
          claimAble.result.forEach(item => {
            vestings.before[_.findIndex(vestings.before, { epoch: item.epoch })].claim = item
          })
          data.vestings = vestings
          setVestingStrategic(data)
          myEuros = myEuros + data.valueEur
          setMyPocket(myEuros)
          let tokens = parseFloat(data.tokens)
          myCaps = myCaps + tokens
        }
      })
      .catch(err => err)
  }
  const loadPublicVestingBlock = (address) => {
    setVestingPublic(null)
    fetch(`${process.env.REACT_APP_BASE_URL}/user/getClaimData/?address=${address}&source=public`, {
      method: 'GET',
    })
      .then(resp => resp.json())
      .then(async data => {
        const vestings = getListOfVestingItems(data)
        if (data) {
          const claimAble = await getClaimableInfo(address, 'public')
          claimAble.result.forEach(item => {
            vestings.before[_.findIndex(vestings.before, { epoch: item.epoch })].claim = item
          })
          data.vestings = vestings
          setVestingPublic(data)
          myEuros = myEuros + data.valueEur
          setMyPocket(myEuros)
          let tokens = parseFloat(data.tokens)
          myCaps = myCaps + tokens
        }
      })
      .catch(err => err)
  }
  const onAccountAddressUpdated = async () => {
    if (connectedAccountAddress) {
      resetMyPockets()
      dispatch(addMetamaskAddressAction({ address: connectedAccountAddress, userId: user.user_id }))
      setMyPocketNFT(0)
      await loadAirdropVestingBlock(connectedAccountAddress)
      await loadSeedVestingBlock(connectedAccountAddress)
      await loadCommunityVestingBlock(connectedAccountAddress)
      await loadPrivateVestingBlock(connectedAccountAddress)
      await loadStrategicVestingBlock(connectedAccountAddress)
      await loadPublicVestingBlock(connectedAccountAddress)

      if (connectedAccountAddress) {
        const nftDataObject = _.find(nftData, (item) => connectedAccountAddress == Web3.utils.toChecksumAddress(item.addr))
        if (nftDataObject) {
          const NFTQuantity = Number(nftDataObject.qyt)
          setMyPocketNFT(NFTQuantity)
        }
      }


      // Development Data
      // await loadAirdropVestingBlock('0x080c6007ff724A096C03462D148234159Ff29f8a')
      // await loadSeedVestingBlock('0xF6836ba9F9DD364a67AA67f2029631387929e750')
      // await loadCommunityVestingBlock('0xC818540616058edD93a0f44aD38096aEacF8434D')
      // await loadPrivateVestingBlock('0x1FeFaaD64ee529495cE583Ed88456222b9515088')
      // await loadStrategicVestingBlock('0x6cBBe51598d0aBA176B8e2A6FFFf70a4962d3452')
      // await loadPublicVestingBlock('0x2ABF4a35f501Ae64d20c0BF52034f65D5B038589')
    }
  }
  const calculateRealTimeReward = async () => {
    const { totalClaimed, totalRewarded, expectedTodayCaps, currentAPR, noReward } = rewardData
    if (noReward) {
      clearInterval(realTimeInterval)
    } else {
      setTopBlockData(({ capsInWallet }) => {

        let realtimeReward = 0

        if (expectedTodayCaps !== 0) {
          let perSecReward = expectedTodayCaps / 86400
          const secondsPassed = (dayjs() - dayjs().startOf('day')) / 1000
          realtimeReward = perSecReward * secondsPassed
        }
        return ({
          totalClaimed: parseFloat(totalClaimed).toFixed(0),
          capsToHarvest: parseFloat(realtimeReward).toFixed(2),
          APR: parseFloat(currentAPR).toFixed(0),
          capsInWallet: parseFloat(totalRewarded).toFixed(0),
        })
      })
    }
  }
  const fetchRewardData = async () => {
    if (connectedAccountAddress) {
      try {
        let res = await axios.post(`${process.env.REACT_APP_BASE_URL}/claim/dataForRealTime`, { address: connectedAccountAddress })
        //let res = await axios.post(`${process.env.REACT_APP_BASE_URL}/claim/dataForRealTime`, { address: '0x057C4226efF9f7eD64F2D50Cd866d5CB95C5bFdC' })
        if (res && res.data) {
          setRewardData({ ...res.data })
        }
      }
      catch{
        () => { }
      }
    }
  }
  const initComponent = async () => {
    showLoader()
    await Promise.all([
      loadNftWalletDatas(),
      initEventsMetamask()
    ])
    hideLoader()
  }
  const accountConnectedUpdate = async () => {
    showLoader()
    await Promise.all([
      onAccountAddressUpdated(),
      getExchangeRate(),
      fetchRewardData()
    ])
    hideLoader()
  }
  useEffect(() => {
    initComponent()
  }, [])

  useEffect(() => {
    accountConnectedUpdate()
  }, [connectedAccountAddress])

  useEffect(() => {
    clearInterval(realTimeInterval)
    realTimeInterval = setInterval(() => {
      calculateRealTimeReward()
    }, 1000)
  }, [rewardData])

  return (
    <div className='min-h-screen mx-4 sm:mx-16 my-16'>
      <Modal open={openClaimModal} onClose={onCloseClaimModal} center classNames={{ overlay: 'modalOverlay', modal: 'commonModal' }}>
        <div className="modalContainer">
          <div className="header">
            <h3>{t('claimPopupTitle')}</h3>
          </div>
          <button className='active' type="button" onClick={onCloseClaimModal}>{t('depositPopbtn')}</button>
        </div>
      </Modal>
      <main>
        <div className='text-xl'>
          <div className={`wrapper ${styles.rootDisclaimer}`}>
            <div className={styles.disclaimer}>
              <div className={styles.warning}>
                <WarningSVGIcon className={styles.warningIcon} />
                <div className={styles.warningLabel}>{t('Home-Warning')}</div>
              </div>
              <div className={styles.message}>
                <div>
                  {t('Home-Warning-1')}
                  <a className={styles.link} href="https://bridge.ternoa.network/" title="Bridge Mainnet">
                    Token Bridge
                  </a>
                </div>
                <div>
                  {t('Home-Warning-2')}
                </div>
                <div>
                  {t('Home-Warning-3')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="hero">
          <div className='mt-20 text-white text-2xl mb-12 text-center sm:text-left'>
            <h1 className='text-5xl font-semibold mb-8'>{t('Home-Hero')}</h1>
            <div className='my-2'>
              <p>{t('Home-Hero-p1')}</p>
              <p>{t('Home-Hero-p2')}</p>
            </div>
          </div>
        </section>
        {chainType && chainType !== '0x1' &&
          <div className="metamask-network-wrapper">
            <div className="metamask-network-alignBox">
              <div className="metamask-network-container_error">
                <div className="flex justify-center pt-5 pb-4">
                  <img src={warning} alt="Please select your ETH network" />
                </div>
                <div className="metamask-network-text text-center px-5 pb-5">
                  {t('metamaskWrongNetwork')}
                </div>
              </div>
            </div>
          </div>
        }
        {!rewardData.noReward &&
          <section id="staking">
            <div className='grid sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-4 sm:gap-8'>
              <div className='flex flex-col col-span-1 md:col-span-3 xl:col-span-2 sm:flex-row rounded-xl p-8 justify-between card-primary'>
                <div className='flex flex-col sm:flex-row gap-4 items-center'>
                  <img src={coinImg} width="100px" alt='ternoa caps' />
                  <div className="text-center sm:text-left mb-4 sm:mb-0">
                    <h4 className='text-lg text-white font-bold'>{t('Home-Reward-card1-title')}</h4>
                    <h5 className='text-3xl text-blue-700 font-bold py-2'>{Number(topBlockData.totalClaimed).toLocaleString()}</h5>
                    <p className='text-gray-500 text-sm'>~${Number((topBlockData.totalClaimed * exchangeRate).toFixed(2)).toLocaleString()}</p>
                  </div>
                </div>
                <div className="flex flex-col justify-center">
                  <div className='mx-auto text-center px-4 py-2 rounded-full bg-green-300'>APR: {topBlockData.APR}%</div>
                  <p className='text-gray-500 text-center pt-4'>{t('Home-Reward-card1-hold')}</p>
                  <p className='text-gray-500 text-center'>{t('Home-Reward-card1-reward')}</p>
                </div>
              </div>
              <div className='flex rounded-xl col-span-1 p-8 justify-center md:justify-start card-secondary'>
                <div className="text-center md:text-left">
                  <h4 className='text-lg text-white font-bold'>{t('Home-Reward-card2')}</h4>
                  <h5 className='text-3xl text-red-400 font-bold py-2'>{Number(topBlockData.capsInWallet).toLocaleString()}</h5>
                  <p className='text-gray-500 text-sm'>~${Number((topBlockData.capsInWallet * exchangeRate).toFixed(2)).toLocaleString()}</p>
                </div>
              </div>
              <div className='flex rounded-xl col-span-1 p-8 justify-center md:justify-start card-secondary'>
                <div className="text-center md:text-left">
                  <h4 className='text-lg text-white font-bold'>{t('Home-Reward-card3')}</h4>
                  <h5 className='text-3xl text-pink-600 font-bold py-2'>{topBlockData.capsToHarvest.toLocaleString()}</h5>
                  <p className='text-gray-500 text-sm'>~${Number((topBlockData.capsToHarvest * exchangeRate).toFixed(2)).toLocaleString()}</p>
                </div>
              </div>
              <div className='flex rounded-xl col-span-1 p-8 justify-center md:justify-start card-secondary'>
                <div className="text-center md:text-left">
                  <h4 className='text-lg text-white font-bold'>{t('Home-Reward-card4')}</h4>
                  <div className='flex justify-center md:justify-start gap-x-2 py-3'>
                    {user && user.wallet_address &&
                      <button
                        onClick={() => { setNetwork('ternoa') }}>
                        <img src={network === 'ternoa' ? ternoaRedSvg : ternoaWhiteSvg} alt='ternoa network' />
                      </button>
                    }
                    <button onClick={() => setNetwork('ethereum')}>
                      <img src={network === 'ethereum' ? ethereumRedSvg : ethereumWhiteSvg} alt='ethereum network' />
                    </button>
                  </div>
                  <div className='text-gray-500 text-sm flex gap-x-2'>
                    <span>{ellipsis(network === 'ethereum' ? connectedAccountAddress : (user && user.wallet_address ? user.wallet_address : ''), 19)}</span>
                    <CopyToClipboard text={network === 'ethereum' ? connectedAccountAddress : user.wallet_address} onCopy={() => { }}>
                      <img className="cursor-pointer" src={saveSvg} alt='save address' />
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        <div>
          <div className='text-center mt-16'>
            <p className='text-white text-4xl font-semibold'>{t('Home-Participations')}</p>
          </div>
          {vestingAirdrop && <VestingBlock title="Airdrop" data={vestingAirdrop} exchangeRate={exchangeRate} claimToken={claimToken} />}
          {vestingSeed && <VestingBlock title="Seed" data={vestingSeed} exchangeRate={exchangeRate} claimToken={claimToken} />}
          {vestingCommunity && <VestingBlock title="Community" data={vestingCommunity} exchangeRate={exchangeRate} claimToken={claimToken} />}
          {vestingPrivate && <VestingBlock title="Private" data={vestingPrivate} exchangeRate={exchangeRate} claimToken={claimToken} />}
          {vestingStrategic && <VestingBlock title="Strategic" data={vestingStrategic} exchangeRate={exchangeRate} claimToken={claimToken} />}
          {vestingPublic && <VestingBlock title="Public" data={vestingPublic} exchangeRate={exchangeRate} claimToken={claimToken} />}
          {/* <section id="NFTs">
            <div className='mt-16 card-primary-100 p-6 sm:p-8 rounded-3xl ternoart-body'>
              <div className='flex flex-col sm:flex-row justify-between sm:items-center'>
                <h3 className='text-white text-2xl font-bold'>{t('Home-Ternoart')}</h3>
                <div className='flex gap-x-2 items-center justify-between sm:justify-start'>
                  <div className='flex justify-start divide-x-2 divide-blue-700'>
                    <div className='text-white py-1 flex items-center'>
                      <p className='text-bold'>{t('Home-Ternoart-NFT')}</p>
                      <p className='pl-4 text-gradient text-xl sm:text-3xl font-bold'>{ myPocketNFT && myPocketNFT >= 1 ? myPocketNFT: 0} / 8</p>
                    </div>
                  </div>
                  { myPocket >= 1 && 
                  <button className='px-4 lg:px-8' onClick={() => {setTernoart (!ternoart )}}>
                    <img className={`${ternoart ? 'transform rotate--180 duration-500' : ' transform rotate-180 duration-500'}`} src={arrowImg} alt='accordian expand icon'/>
                  </button>
                  }
                </div>
              </div>
              <div className={`accordion ${ternoart ? 'collapse-active' : 'collapse-disable'} `}>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 gap-y-8 pt-8'>
                  {
                    myPocketNFT >= 1 && <div className="text-white card-claim-empty-outline"><img src={Caps8} className='w-full rounded-2xl' alt='' /></div>
                  }
                  {
                    myPocketNFT >= 2 && <div className="text-white card-claim-empty-outline"><img src={Caps7} className='w-full rounded-2xl' alt='' /></div>
                  }
                  {
                    myPocketNFT >= 3 && <div className="text-white card-claim-empty-outline"><img src={Caps6} className='w-full rounded-2xl' alt='' /></div>
                  }
                  {
                    myPocketNFT >= 4 && <div className="text-white card-claim-empty-outline"><img src={Caps5} className='w-full rounded-2xl' alt='' /></div>
                  }
                  {
                    myPocketNFT >= 5 && <div className="text-white card-claim-empty-outline"><img src={Caps4} className='w-full rounded-2xl' alt='' /></div>
                  }
                  {
                    myPocketNFT >= 6 && <div className="text-white card-claim-empty-outline"><img src={Caps3} className='w-full rounded-2xl' alt='' /></div>
                  }
                  {
                    myPocketNFT >= 7 && <div className="text-white card-claim-empty-outline"><img src={Caps2} className='w-full rounded-2xl' alt='' /></div>
                  }
                  {
                    myPocketNFT >= 8 && <div className="text-white card-claim-empty-outline"><img src={Caps1} className='w-full rounded-2xl' alt='' /></div>
                  }
                </div>
                <p className='text-gray-400 my-8 text-center sm:text-left'>{t('Home-Ternoart-ETH')}</p>
              </div>
              
            </div>
          </section> */}
          <section id='condition'>
            <div className='mt-16 card-primary-100 p-8 rounded-3xl'>
              <div className='text-white text-center sm:text-left'>
                <p className='text-2xl'>{t('Home-Conditions')}</p>
                <p className='py-4 text-center sm:text-left'>{t('Home-Conditions-p')}</p>
                <a href='https://ternoa-2.gitbook.io/general-conditions-of-sale' className='text-blue-800 underline' target="_blank" rel="noreferrer noopener" >{t('Home-Conditions-GCS')}</a>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div >
  )
}


const mapStateToProps = ({ global }) => {
  // ...
  const { isFetching } = global
  return {
    loading: isFetching
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NFTWallet)

