/* eslint-disable no-console */
import { createAction } from 'redux-actions'
import 'react-toastify/dist/ReactToastify.css'

import {  updateUserPasswordApi, validateUserApi, passwordRecoveryApi, updateUserProfile, createUserRequest, checkKycStatus, addMetamaskAddress } from '../../services/api'
import { showLoader, hideLoader, loginUser, getMe, loginUserSuccess } from '../actions'

export const fetchUserProfileRequest = createAction('FETCH_USER_PROFILE_REQUEST')
export const fetchUserProfileSuccess = createAction('FETCH_USER_PROFILE_SUCCESS')
export const fetchUserProfileFailure = createAction('FETCH_USER_PROFILE_FAILURE')

export const checkKycStatusRequest = createAction('CHECK_KYC_REQUEST')
export const checkKycStatusSuccess = createAction('CHECK_KYC_STATUS_SUCCESS')
export const checkKycStatusFailure = createAction('CHECK_KYC_STATUS_FAILURE')

export const updateUserProfileRequest = createAction('UPDATE_USER_PROFILE_REQUEST')
export const updateUserProfileSuccess = createAction('UPDATE_USER_PROFILE_SUCCESS')
export const updateUserProfileFailure = createAction('UPDATE_USER_PROFILE_FAILURE')

export const registerUserError = createAction('REGISTER_USER_ERROR')
export const verficationError = createAction('VERIFICATION_ERROR')

export const passwordRecoverSuccess = createAction('PASSWORD_RECOVER_SUCCESS')
export const passwordRecoverFailure = createAction('PASSWORD_RECOVER_FAILURE')

export const createUserAccount = (payload, history) => async (dispatch) => {
  try {
    payload.username = payload.email
    payload.status = 'CREATED'
    dispatch(showLoader())
    const response = await createUserRequest(payload)
    if (response) {
      history.push('/log_in')
      dispatch(loginUser({ email: payload.email , password: payload.password }, history))
    }
    dispatch(hideLoader())
  } catch (error) {
    dispatch(hideLoader())
    if(error) {
      error.json().then(json => dispatch(registerUserError(json.message)))	
    }
    throw error
  }
}

export const checkKyc = () => async (dispatch) => {
  try {  
    dispatch(showLoader())
    await checkKycStatus()
    dispatch(hideLoader())
  } catch (error) {
    dispatch(hideLoader())
    throw error
  }
}

export const passwordRecoverAction = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader())
    const response = await passwordRecoveryApi(payload)
    dispatch(passwordRecoverSuccess(response.message))
    dispatch(hideLoader())
  } catch (error) {
    dispatch(hideLoader())
    if (error){
      error.json().then(json => dispatch(passwordRecoverFailure(json.message)))
    }
  }
}

export const updateUserPassword = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader())
    await updateUserPasswordApi(payload)
    dispatch(hideLoader())
  } catch (error) {
    dispatch(hideLoader())
    throw error
  }
}

export const verifyUserByPin = (payload, history) => async (dispatch) => {
  try {
    dispatch(showLoader())
    const response = await validateUserApi(payload)
    if(response) {
      dispatch(loginUserSuccess(response))	
      if(response.validated) {
        history.push('/merci') 
      }
    }
    dispatch(hideLoader())
  } catch (error) {
    dispatch(hideLoader())
    dispatch(verficationError('Invalid pin'))
    throw error
  }
}

export const updateUserAccount = (payload) => async (dispatch) => {
  try {
    dispatch(updateUserProfileRequest())
    const response = await updateUserProfile(payload)
    if (response) {
      dispatch(getMe())
    }
  } catch (error) {
    console.log('update User account excpetion: ', error)
    throw error
  }
}


export const addMetamaskAddressAction = (payload) => async (dispatch) => {
  try {
    const response = await addMetamaskAddress(payload)
    if (response) {
      dispatch(getMe())
    }
    // console.log('add adr resp', response)
  } catch (error) {
    console.log('add Metamask address action exception: ', error)
    throw error
  }
}

