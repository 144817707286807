import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Modal } from 'react-responsive-modal'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { updateUserAccount, logoutUser } from '../../redux/actions'
import { ellipsis, isValidTernoaAddress } from '../../services/utils'
import Logo from '../../assets/images/ternoa-logo.svg'
import EnFlag from '../../assets/images/en.png'
import FrFlag from '../../assets/images/fr.png'

const Header = (props) => {
  const {
    user,
    onUpdateUserPassword,
    buttonText,
    navLink,
    onButtonClick,
    isLogged,
  } = props
  const passPattern = new RegExp('^(?=.*?[#?!@$%^&*-])(?=.*?[0-9]).{8,}')
  const { i18n } = useTranslation('common')
  const { t } = useTranslation('common')
  const changeLanguage = (event) => {
    let lng = event.target.value
    i18n.changeLanguage(lng)
  }
  const [openModal, setOpenModal] = useState(false)
  const [openDeadLineModal, setOpenDeadLineModal] = useState(false)
  const [error, setError] = useState(null)
  const [newPassword, setNewPassword] = useState('')
  const [passWordNotValid, setPassWordNotValid] = useState('')
  const [ternoaWalletAddress, setTernoaWalletAddress] = useState('')
  const [ternoaWalletPlaceholder, setTernoaWalletPlaceholder] = useState()

  const onOpenModal = () => setOpenModal(true)
  const onCloseModal = () => setOpenModal(false)

  const onOpenDeadLineModal = () => setOpenDeadLineModal(true)
  const onCloseDeadLineModal = () => setOpenDeadLineModal(false)

  const onUpdateTernoaWalletAddress = async () => {
    //TODO: check if address is valid (need to look in web3 document for it, address type: 'sr25519')
    if (user && user.id && ternoaWalletAddress) {
      // onUpdateUserPassword({userId: user.id, password : newPassword })
      await onUpdateUserPassword({
        id: user.id,
        wallet_address: ternoaWalletAddress,
      })
      setTernoaWalletAddress('')
      onCloseModal()
    }
  }
  const notify = (success) => {
    if (success) {
      toast.success(`${t('accountPopPasswordToastSuccess')}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      toast.warning(`${t('accountPopPasswordToastError')}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  useEffect(() => {
    if (newPassword.length < 8) {
      setPassWordNotValid(
        `${t('accountPopPasswordError')} ${t(
          'accountPopPasswordError1'
        )}`
      )
    } else if (newPassword.search(/[#?!@$%^&*-]/) < 0) {
      setPassWordNotValid(
        `${t('accountPopPasswordError')} ${t(
          'accountPopPasswordError2'
        )}`
      )
    } else if (newPassword.search(/[0-9]/i) < 0) {
      setPassWordNotValid(
        `${t('accountPopPasswordError')} ${t(
          'accountPopPasswordError3'
        )}`
      )
    } else {
      setPassWordNotValid('')
    }
  }, [newPassword])

  const onUpdatePassword = async () => {
    if (user && user.id && newPassword) {
      await onUpdateUserPassword({ id: user.id, password: newPassword })
      notify(true)
      setNewPassword('')
      onCloseModal()
    } else {
      notify(false)
    }
  }

  useEffect(() => {
    if (isLogged && user && user.wallet_address) {
      if(user.wallet_address) setTernoaWalletPlaceholder(ellipsis(user.wallet_address, 20))
      else setTernoaWalletPlaceholder(t('accountPopWalletAddress'))
    }
  }, [isLogged, user])

  const onChangeTernoaWalletAddress = (event) => {
    setTernoaWalletAddress(event.target.value)
  }

  useEffect(()=>{
    setError('')
    if (ternoaWalletAddress && !isValidTernoaAddress(ternoaWalletAddress)){
      setError(t('accountPopAddressError'))
    }
  },[ternoaWalletAddress])

  const onChangePassword = (event) => {
    setNewPassword(event.target.value)
  }

  return (
    <>
      <div className='header-container'>
        <Modal open={openModal} onClose={onCloseModal} center classNames={{ overlay: 'modalOverlay', modal: 'commonModal' }}>
          <div className='modalContainer'>
            <div className='header'>
              <h3>{t('headerMyAccPopTitle')}</h3>
            </div>
            <div className='info'>
              <div className='info-main'>
                <p>Email:</p>
                <div className='notranslate'>
                  {(user && user.email) || 'user@email.com'}
                </div>
              </div>
              {user && user.wallet_address &&
              <div className='info-main'>
                <p>{t('accountPopWalletAddress')}:</p>
                <div className='notranslate'>
                  {ellipsis(user && user.wallet_address, 20)}
                </div>
              </div>}
              {user && user.address &&
              <div className='info-main'>
                <p>{t('accountPopETHAddress')}:</p>
                <div className='notranslate'>
                  {ellipsis(user && user.address, 20)}
                </div>
              </div>}
              <div className='info-main'>
                <p>KYC:</p>
                <button onClick={onOpenDeadLineModal}>
                  <span className='cursor'>
                    {t('accountPopVerify')}
                  </span>
                </button>
              </div>
            </div>
            <div className='info-group'>
              <p className='pb-2'>{t('accountPopWalletAddress')}:</p>
              <div className='info-group-main'>
                <div className='input-section'>
                  <input
                    type='text'
                    autoComplete='off'
                    name='outhSms'
                    placeholder={ ternoaWalletPlaceholder ? ternoaWalletPlaceholder : t('accountPopWalletAddress') }
                    value={ternoaWalletAddress}
                    minLength={6}
                    onChange={onChangeTernoaWalletAddress}
                  />
                  <button 
                    className={`${error ? 'button-section-disabled':'button-section'}`} type='button' onClick={onUpdateTernoaWalletAddress} disabled={
                      error ? true : false
                    }>
                    {t('accountPopSave')}
                  </button>
                </div>
              </div>
              <div className='info-group-error_redmax text-center py-4'>{error}</div>
            </div>
            <div className='info-group'>
              <p className='pb-2'>{t('accountPopPass')}:</p>
              <div className='info-group-main'>
                <div className='input-section'>
                  <input
                    type='text'
                    autoComplete='off'
                    name='outhSms'
                    placeholder={t('accountPopPlace')}
                    value={newPassword}
                    minLength={6}
                    onChange={onChangePassword}
                  />
                  <button
                    className={`${
                      passPattern.test(newPassword)
                        ? 'button-section'
                        : 'button-section-disabled'
                    }`}
                    type='button'
                    disabled={
                      passPattern.test(newPassword)
                        ? false
                        : true
                    }
                    onClick={onUpdatePassword}
                  >
                    {t('headerMyAccPopButton')}
                  </button>
                </div>
              </div>
              {!newPassword && (
                <div className='secondary-content'>
                  {t('accountPopSubLine')}
                </div>
              )}
              {newPassword && (
                <div className='secondary-content'>
                  {passWordNotValid}
                </div>
              )}
            </div>
            <button 
              className='active'
              onClick={() => {
                onButtonClick()
                setOpenModal(false)
              }}>
              {t('logOut')}
            </button>
          </div>
        </Modal>
        <Modal open={openDeadLineModal} onClose={onCloseDeadLineModal} center classNames={{ overlay: 'customOverlay', modal: 'customModal', }}>
          <div className='modalContainer'>
            <div className='header'>
              <h3>{t('headerMyAccPopTitle')}</h3>
              <p>{t('kycVerificationDeadlineEnded')}</p>
              <button className='logout-button' onClick={onCloseDeadLineModal}>{t('ok')}</button>
            </div>
          </div>
        </Modal>
        <div className='header-container-logo'>
          <a href='https://ternoa.com/' className=' cursor-pointer' target="_blank" rel="noreferrer noopener">
            <img src={Logo} alt='Ternoa Logo'/>
            {/* <div className='ml-3 mt-1 flex items-center header-testnet px-2'>
              <div className='rounded-full w-2 h-2 self-center'></div>
              <span className='pl-1 font-semibold self-center'>Testnet</span>
            </div> */}
          </a>
        </div>
        <div className='header-container-account'>
          {navLink && (
            <NavLink to={navLink}>
              <button className='menu-item-header' onClick={onButtonClick}>
                {buttonText || ''}
              </button>
            </NavLink>
          )}
          {isLogged && user && user.validated ? (
            <div className="flex items-center">
              <button className='menu-item-header' onClick={onOpenModal}>
                {t('myAccountBtn')}
              </button>
            </div>
          ) : 
            (<div className='select-lang-wrapper'>
              <img src={i18n.language === 'en' ? EnFlag : FrFlag} alt='en flag'/>
              {/* eslint-disable-next-line jsx-a11y/no-onchange */}
              <select value={i18n.language} onChange={changeLanguage}>
                <option value='en'>{t('headerSelectLangEn')}</option>
                <option value='fr'>{t('headerSelectLangFr')}</option>
              </select>
            </div>)}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ auth }) => {
  const { user, isLogged } = auth
  return {
    user,
    isLogged
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogoutUser: (history) => dispatch(logoutUser(history)),
    onUpdateUserPassword: (payload) => dispatch(updateUserAccount(payload)),
    onUpdateTernoaWalletAddress: (payload) => dispatch(updateUserAccount(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
