import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Modal } from 'react-responsive-modal'
import { useTranslation } from 'react-i18next'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { loginUser, passwordRecoverAction, verifyUserByPin, resetLoginError } from '../../../redux/actions'
import { LoginValidator } from '../../../shared/formValidator'
require('dotenv').config()
const walletConector = null// new WalletConnectProvider({ infuraId: process.env.REACT_APP_INFURA_KEY, bridge:'https://uniswap.bridge.walletconnect.org' })

const SignIn = (props) => {
  const { history, onLogin, error, errorUserReducer, successMessage, user, onPasswordRecoverySubmit, onValidateUser, onResetLoginError } = props
  const { t } = useTranslation('common')
  const { i18n } = useTranslation('common')
  const [openModal, setOpenModal] = useState(false)
  const [openModalRecovery, setOpenModalRecovery] = useState(false)
  const [verificationPin, setVerificationPin] = useState('')
  const [recoveryEmail, setRecoveryEmail] = useState('')
  const [values, setValues] = useState({ email: '', password: '' })
  const [errors, setErrors] = useState({ email: '', password: '' })
  const emailPattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]{1,})*\.[a-zA-Z]{1,}$/)
  const showToast = (error) => {
    toast(error, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  useEffect(() => {
    if (error && error.length > 0) {
      showToast(error)
    }
  }, [error])

  useEffect(() => {
    if (errorUserReducer && errorUserReducer.length > 0) {
      showToast(errorUserReducer)
    }
  }, [errorUserReducer])

  useEffect(() => {
    if (successMessage && successMessage.length > 0) {
      showToast(successMessage)
    }
  }, [successMessage])

  useEffect(() => {
    onResetLoginError()
  }, [])

  const gotoWallet = (chainId, accounts, isWallet) => {
    history.push({
      pathname: '/nft-wallet',
      state: { accounts: accounts, chainId: chainId, isWallet: isWallet },
      wallConnect: walletConector
    })
  }
  useEffect(() => {
    if (user && !user.validated) {
      onOpenModal()
    } else if (user && user.validated && user.status === 'CREATED') {
      history.push('/merci')
    } else if (user && user.validated) {
      if (window.ethereum) {
        window.ethereum.request({ method: 'eth_accounts' }).then(resp => {
          if (resp && resp.length > 0) {
            gotoWallet('', resp, false)
            return
          }
        })
      }
      if (walletConector && walletConector.wc.connected) {
        (async function () {
          await walletConector.enable()
          let chainId = '0x' + walletConector.chainId
          let accounts = walletConector.accounts
          gotoWallet(chainId, accounts, true)
        }())
        return
      }

      history.push('/merci')
    }
  }, [user])

  const handleSubmit = () => {
    const validationErrors = LoginValidator(values, i18n.language)
    setErrors(validationErrors)
    const noErrors = Object.keys(validationErrors).length === 0
    if (noErrors) {
      const payload = values
      onLogin(payload, history)
    }
  }
  const onOpenModal = () => {
    setVerificationPin('')
    setOpenModal(true)
  }
  const onCloseModal = () => setOpenModal(false)
  const onVerifyPin = () => {
    onValidateUser({ verificationPin: verificationPin }, history)
  }
  const onChangePin = (event) => {
    setVerificationPin(event.target.value)
  }
  const onOpenModalRecovery = (e) => {
    e.preventDefault()
    setOpenModalRecovery(true)
  }
  const onCloseModalRecovery = () => setOpenModalRecovery(false)
  const onSubmitRecovery = () => {
    onPasswordRecoverySubmit({ email: recoveryEmail })
    setRecoveryEmail('')
    onCloseModalRecovery()
  }
  const onChangeRecoveryEmail = (event) => {
    setRecoveryEmail(event.target.value)
  }
  const onChangeValues = (event) => {
    setErrors({
      ...errors,
      email: event.target.name === 'email' ? '' : errors.email,
      password: event.target.name === 'password' ? '' : errors.password
    })
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  return (
    <>
      <div className='sign-in-wrapper'>
        <Modal open={openModal} onClose={onCloseModal} center classNames={{ overlay: 'modalOverlay', modal: 'commonModal' }}>
          <div className='modalContainer mt-8 mx-4'>
            <div className='header'>
              <h3>{t('loginPopTitle1')}</h3>
              <p className='pt-2'>{t('loginPopDes1')}</p>
            </div>
            <div className='mainInput'>
              <input
                type='text'
                name='outhSms'
                placeholder={t('loginPopPlace1')}
                value={verificationPin}
                maxLength={6}
                minLength={6}
                onChange={onChangePin}
              />
            </div>
            <button className={verificationPin.length < 6 ? 'disabled':'active'} type='button' disabled={verificationPin.length < 6 ? true : false} onClick={onVerifyPin}>{t('loginPopButton1')}</button>
          </div>
        </Modal>
        <Modal open={openModalRecovery} onClose={onCloseModalRecovery} center classNames={{ overlay: 'modalOverlay', modal: 'commonModal' }}>
          <div className='modalContainer'>
            <div className='header'>
              <h3>{t('loginPopTitle')}</h3>
              <p>{t('loginPopDes')}</p>
            </div>
            <div className='mainInput'>
              <input
                type='email'
                name='recoverEmail'
                placeholder={t('loginPopPlace')}
                value={recoveryEmail}
                onChange={onChangeRecoveryEmail}
              />
            </div>
            <button className={!emailPattern.test(recoveryEmail) ? 'disabled':'active'} type='button' disabled={!emailPattern.test(recoveryEmail) ? true : false} onClick={onSubmitRecovery}>{t('loginButton')}</button>
          </div>
        </Modal>
        <div className='sign-in-form'>
          <div className='form-wrapper'>
            <h1 className='title'>{t('loginPage')}</h1>
            <h2 className='subtitle'>{t('loginSignin')}</h2>
            <div className='fields-wrapper'>
              <div className='field-item-main'>
                <div className='field-item'>
                  <input
                    type='email'
                    name='email'
                    placeholder={t('signUpEmail')}
                    value={values.email}
                    onChange={onChangeValues}
                  /></div>
                <span className='error'>{errors && errors.email}</span>
              </div>
              <div className='field-item-main'>
                <div className='field-item'>
                  <input
                    type='password'
                    name='password'
                    placeholder={t('signUpPass')}
                    value={values.password}
                    onChange={onChangeValues}
                  /></div>
                <span className='error'>{errors && errors.password}</span>
              </div>
              <button className='login-btn' onClick={handleSubmit}>{t('signUpLogin')}</button>
              <div className="flex flex-col items-center">
                <a href="/" className='forgot-password-text' onClick={(e) => {onOpenModalRecovery(e)}}>{t('loginForgetPass')}</a>
                <div className="create-account">
                  <a href='/sign_up' >
                    {t('loginCreateAccount')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = ({ auth, userReducer }) => {
  const { error, user } = auth
  const errorUserReducer = userReducer.error
  const { successMessage } = userReducer
  return {
    error,
    errorUserReducer,
    successMessage,
    user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (payload, history) => dispatch(loginUser(payload, history)),
    onPasswordRecoverySubmit: (payload) => dispatch(passwordRecoverAction(payload)),
    onValidateUser: (payload, history) => dispatch(verifyUserByPin(payload, history)),
    onResetLoginError: () => dispatch(resetLoginError())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn)