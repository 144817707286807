import { 
  REGISTER_USER_INIT,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  RESET_SIGNUP_ERROR,
  PASSWORD_RECOVER_FAILURE,
  PASSWORD_RECOVER_SUCCESS,
} from '../actions'

const defaultState = {
  user: null,
  isFetching: false,
  error: '',
  successMessage: '',
}

export default function userReducer(state = defaultState, action) {
  switch (action.type) {
  /* BUSINESS LIST CASES */
  case REGISTER_USER_INIT:
    return { 
      ...state, 
      isFetching: true,
      error: ''
    }
  case REGISTER_USER_SUCCESS:
    return { 
      ...state,
      isFetching: false,
      error: '',
      user: action.payload
    }
  case RESET_SIGNUP_ERROR:
    return { 
      ...state,
      error: ''
    }
  case REGISTER_USER_ERROR:
    return { 
      ...state,
      isFetching: false,
      error: action.payload
    }
  case PASSWORD_RECOVER_FAILURE:
    return { 
      ...state,
      isFetching: false,
      error: action.payload,
      successMessage:''
    }
  case PASSWORD_RECOVER_SUCCESS:
    return {
      ...state,
      isFetching: false,
      error: '',
      successMessage: action.payload,
    }
    /* DEFAULT CASE */
  default: return { ...state }
  }
}