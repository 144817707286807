import React, { Component } from 'react'

class KycStart extends Component {
  render() {

    return (
      <div>
        KycStart
      </div>
    )
  }
}

export default KycStart
