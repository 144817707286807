import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useTranslation } from 'react-i18next'
import { createUserAccount, resetSignupError } from '../../../redux/actions'
import { SignUpValidator } from '../../../shared/formValidator'
import WalletConnectProvider from '@walletconnect/web3-provider'
require('dotenv').config()

const walletConector = null //new WalletConnectProvider({ infuraId: process.env.REACT_APP_INFURA_KEY, bridge: 'https://uniswap.bridge.walletconnect.org' })

const SignUp = (props) => {
  const { history, onCreateUser, error, user, onResetSignupError } = props
  const { t } = useTranslation('common')
  const { i18n } = useTranslation('common')
  const search = useLocation().search
  const affiliate_id = new URLSearchParams(search).get('refId')
  const [values, setValues] = useState({ email: '', password: '', confirmPassword: '', cgu: false, saft: false })
  const [errors, setErrors] = useState({ email: '', password: '', confirmPassword: '', cgu: '', saft: '' })
  const showToast = (error) => {
    toast(error, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  useEffect(() => {
    onResetSignupError()
  }, [])

  useEffect(() => {
    if (error && error.length > 0) {
      showToast(error)
    }
  }, [error])

  useEffect(() => {
    if (user && user.validated) {
      history.push('/merci')
    }
  }, [user])

  const handleSubmit = async () => {
    const validationErrors = SignUpValidator(values, i18n.language)
    setErrors(validationErrors)
    const noErrors = Object.keys(validationErrors).length === 0
    const payload = values
    if (noErrors) {
      const headers = {
        'Api-Key': '2tNYsfio11UnpXnHo3U2PTjzDISzRPWD',
      }
      await fetch('https://claim-details.ternoa.com/create', {
        method: 'GET',
        headers: headers,
        body: undefined,
      }).then(
        response => response.json()
      ).then(() => {
        payload.sessionkyc = 'xxxx-xxxxx-xxxx'
        if (affiliate_id && affiliate_id.length > 0) {
          payload.affiliate = affiliate_id + ''
        }
        onCreateUser(payload, history)
      })
    }
  }

  const onChangeValues = (event) => {
    setErrors({
      ...errors,
      email: event.target.name === 'email' ? '' : errors.email,
      password: event.target.name === 'password' ? '' : errors.password,
      confirmPassword: event.target.name === 'confirmPassword' ? '' : errors.confirmPassword,
    })
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const onChangeRadios = (event) => {
    if (event === 'cgu') {
      setValues({ ...values, cgu: !values.cgu })
      setErrors({ ...errors, cgu: '' })
    } else {
      setValues({ ...values, saft: !values.saft })
      setErrors({ ...errors, saft: '' })
    }
  }

  const gotoWallet = (chainId, accounts, isWallet) => {
    history.push({
      pathname: '/nft-wallet',
      state: { accounts: accounts, chainId: chainId, isWallet: isWallet },
      wallConnect: walletConector
    })
  }

  useEffect(() => {
    if (user && !user.validated) {
      return
    } else if (user && user.validated && user.status === 'CREATED') {
      history.push('/merci')
    } else if (user && user.validated) {
      if (window.ethereum) {
        window.ethereum.request({ method: 'eth_accounts' }).then(resp => {
          if (resp && resp.length > 0) {
            gotoWallet('', resp, false)
            return
          }
        })
      }
      if (walletConector&& walletConector.wc.connected) {
        (async function () {
          console.log = () => { }
          await walletConector.enable()
          let chainId = '0x' + walletConector.chainId
          let accounts = walletConector.accounts
          gotoWallet(chainId, accounts, true)
        }())
        return
      }
      history.push('/merci')
    }
  }, [user])

  return (
    <>
      <div className='sign-up-wrapper my-14 sm:my-0'>
        <div className='sign-up-form'>
          <div className='form-wrapper'>
            <h1 className='title'>{t('signUpHead3')}</h1>
            <div className="text-center text-gray-400 border border-gray-500 bg-gray-900 rounded-xl p-4 my-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="mx-2 mx-auto" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
              {t('signUpWarning')}
            </div>
            <div className='fields-wrapper'>
              <div className='field-item-main'>
                <div className='field-item'>
                  <input
                    type='email'
                    name='email'
                    placeholder={t('signUpEmail')}
                    value={values.email}
                    onChange={onChangeValues}
                  /></div>
                <span className='error'>{errors && errors.email}</span>
              </div>
              <div className='field-item-main'>
                <div className='field-item'>
                  <input
                    type='password'
                    name='password'
                    placeholder={t('signUpPass')}
                    value={values.password}
                    onChange={onChangeValues}
                  /></div>
                <span className='error'>{errors && errors.password}</span>
              </div>
              <div className='field-item-main'>
                <div className='field-item'>
                  <input
                    type='password'
                    name='confirmPassword'
                    placeholder={t('signUpConfirmPass')}
                    value={values.confirmPassword}
                    onChange={onChangeValues}
                  /></div>
                <span className='error'>{errors && errors.confirmPassword}</span>
              </div>
              <div className='radion-btns'>
                <div className='cgu'>
                  <input type='checkbox' onClick={() => onChangeRadios('cgu')} />
                  <div className='label'>
                    <a
                      href='https://ternoa-2.gitbook.io/general-conditions-of-use'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {t('signUpCheck1')}
                    </a>
                  </div>
                </div>
                <div className='saft'>
                  <input type='checkbox' onClick={() => onChangeRadios('saft')} />
                  <div className='label'>
                    <a
                      href='https://ternoa-2.gitbook.io/general-conditions-of-sale'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {t('signUpCheck2')}
                    </a>
                  </div>
                </div>
              </div>
              <span className='error'>
                {errors && errors.cgu ? errors.cgu : errors.saft}
              </span>
              <button className='login-btn' onClick={handleSubmit}>{t('signUpButton')}</button>
              <div className='account-created-link'>
                <a href='/signIn'>
                  {t('signUpAccountAlreadyDone')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ auth, userReducer }) => {
  const { error } = userReducer
  const { user } = auth
  return {
    error,
    user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateUser: (payload, history) => dispatch(createUserAccount(payload, history)),
    onResetSignupError: () => dispatch(resetSignupError())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp)