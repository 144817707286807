export const nftData = [
  {
    addr: '0x73abc5bd7283c7baadc705c9994e13ede1c9b4f2',
    qyt: '2'
  },
  {
    addr: '0x24915582009f9049d1a6647f0fbd9f420ae01fe1',
    qyt: '3'
  },
  {
    addr: '0xdb30fa8455a07f317c6c944c34ea88c858ce5058',
    qyt: '1'
  },
  {
    addr: '0xdec9ad3379f2c17145d12c1e963cf3a3db96eb62',
    qyt: '2'
  },
  {
    addr: '0x111ba861b11594746fc37770b192a39b415a6305',
    qyt: '2'
  },
  {
    addr: '0xf82eb061da63fd4d06c3dcf2ed0d66aaa9af822d',
    qyt: '2'
  },
  {
    addr: '0x4f184251c40056fe7b8abbf040f8f30803357799',
    qyt: '2'
  },
  {
    addr: '0xc6d612812b78e9224abb6ca99dd8b6bbc04e303f',
    qyt: '1'
  },
  {
    addr: '0x3b464c069a714f4d9a12b349b6120af74c817baa',
    qyt: '3'
  },
  {
    addr: '0xe1a3d059b5875dae2b9773011b9b8dee3923e600',
    qyt: '1'
  },
  {
    addr: '0x836f044d85d00408798764d6a6f432fe2e6d1c66',
    qyt: '1'
  },
  {
    addr: '0x07b20a085f409ebb7d92685693b95e71b55d95cf',
    qyt: '3'
  },
  {
    addr: '0x323ec0f05086bfe1fc02690cc9e1f407e3965116',
    qyt: '1'
  },
  {
    addr: '0xf7021e8f0e7bea9c155bc19510fe190c6e0398be',
    qyt: '1'
  },
  {
    addr: '0xda3fde8669aa5e143f1b5e05b5d90e273fb997e5',
    qyt: '2'
  },
  {
    addr: '0xfacfa0f063dcdc22d2645ba9d17722413b40b776',
    qyt: '2'
  },
  {
    addr: '0x818741f3729a9049bc911c8960e696e3172b54f7',
    qyt: '3'
  },
  {
    addr: '0x080c6007ff724a096c03462d148234159ff29f8a',
    qyt: '1'
  },
  {
    addr: '0xb2de47a7ca65f0d1fa85072b306ad45026bb898b',
    qyt: '1'
  },
  {
    addr: '0xd9d8e83231797d729763567241d74f550d73ef0b',
    qyt: '1'
  },
  {
    addr: '0xd9d33639fe70d4cf708594c720f487a927463a45',
    qyt: '1'
  },
  {
    addr: '0xDe9d3f8F0b78F8f590B0c8D72234ED6f4CDF3578',
    qyt: '2'
  },
  {
    addr: '0xce3ee22104df79aa5cfab3183c6af035bf4c1b4a',
    qyt: '1'
  },
  {
    addr: '0xb1e8d5d1c3a36bf4b7ca170338781be61292a635',
    qyt: '2'
  },
  {
    addr: '0x9ad39d292808c3175a5dd0e20ab778b440fcff21',
    qyt: '1'
  },
  {
    addr: '0xb454f6b536899ce8c5e9880bb4bf64c712594a71',
    qyt: '1'
  },
  {
    addr: '0x1f8873ac8ba3b89bc858effa51a133c0188969a4',
    qyt: '2'
  },
  {
    addr: '0xc6dceb6e2c45245f1191a05e88dd62b9508bf5bf',
    qyt: '4'
  },
  {
    addr: '0xd7c242e19d5e7f8898dc142dbc6afd87ac66362f',
    qyt: '5'
  },
  {
    addr: '0x49a338c8a8c92f3f7e5a4700a191bb41595591b2',
    qyt: '1'
  },
  {
    addr: '0x1faa760a5629d3743e4980fbfd2416e0e50b39c5',
    qyt: '1'
  },
  {
    addr: '0x130C075c6728e1740954EF97F9697BcD5efeCe7F',
    qyt: '2'
  },
  {
    addr: '0xfb9683d2f3502b6d47e85f2152261066a3c8784f',
    qyt: '3'
  },
  {
    addr: '0xb0609b9e10f27a4ccf8ef07ccb13659990a24137',
    qyt: '1'
  },
  {
    addr: '0x88d7d1640be66f30fd5025d229732192de182a16',
    qyt: '4'
  },
  {
    addr: '0xc497f1615647570c7227eee35512110368b7d19d',
    qyt: '2'
  },
  {
    addr: '0xf2848277C7FfE6CeEFDB4ac431482C194aa5d135',
    qyt: '5'
  },
  {
    addr: '0x8c2413136af2c312012da6c72cfaed00409340ff',
    qyt: '1'
  },
  {
    addr: '0x79c113a3c51db6c6a4c308a646edbe079f02b634',
    qyt: '2'
  },
  {
    addr: '0xa9afeb62a55183a659742519c9555c5863840cdd',
    qyt: '2'
  },
  {
    addr: '0x3f889f764386413b29a0d79709ca77adda27ea82',
    qyt: '1'
  },
  {
    addr: '0xC818540616058edD93a0f44aD38096aEacF8434D',
    qyt: '3'
  },
  {
    addr: '0x348e96ccc6d6a8add41169b4557e12752bf8242c',
    qyt: '4'
  },
  {
    addr: '0xf6836ba9f9dd364a67aa67f2029631387929e750',
    qyt: '1'
  },
  {
    addr: '0xe941af5e5d6948b64301e596e4cf5acfc48ed31c',
    qyt: '2'
  },
  {
    addr: '0xff54f80a2007ae7df11a9f6b77057954588a7467',
    qyt: '2'
  },
  {
    addr: '0xb67b0DFEe991EE129D1EeB7ed5aa88937EB3210B',
    qyt: '5'
  },
  {
    addr: '0xb792763671dce81574195779190f778d261116db',
    qyt: '5'
  },
  {
    addr: '0xb1af0c6f086942e17e4a9275793cd64d09a549fc',
    qyt: '2'
  },
  {
    addr: '0xb322ae22bae289995b31a6e8698c5a1efae00eef',
    qyt: '1'
  },
  {
    addr: '0xc3e8f8911a8c02f6e5c9b9f90318671a3238fe19',
    qyt: '4'
  },
  {
    addr: '0x6188e9f0082922248241818390f7f8f786517913',
    qyt: '2'
  },
  {
    addr: '0x9130c63986cd21b1f12a4fe82ecd72fc307a140b',
    qyt: '5'
  },
  {
    addr: '0x8b3f9162b9505b0c1d2f835e984f8e9fb0360646',
    qyt: '3'
  },
  {
    addr: '0x51ca4776d313a4e07b2d61511f95198c643f8dc1',
    qyt: '2'
  },
  {
    addr: '0x588fa6205ff999f1e95cbb7962cec82bb41cddb0',
    qyt: '2'
  },
  {
    addr: '0xa4379c3b0edc7fd2c4042d9a2b0f5580c875b462',
    qyt: '2'
  },
  {
    addr: '0x7364abac5be8f12c5a337ac5ec8e883dc4032912',
    qyt: '2'
  },
  {
    addr: '0xc4aed09c38b35775a77331ee0f7d4ad886e4e777',
    qyt: '5'
  },
  {
    addr: '0x4cbee0ce7a6a9ecd0187f40f33fd386e44ed48c8',
    qyt: '1'
  },
  {
    addr: '0x3390301ee017046aa0cf50be48f89308e0a9b745',
    qyt: '2'
  },
  {
    addr: '0xc0ea1773b09054c04f8b9d805dd427c33f6661a7',
    qyt: '2'
  },
  {
    addr: '0x4be4dceef0886ec87f118e7a79b5eb51338475ab',
    qyt: '2'
  },
  {
    addr: '0x0f1df28216fef0e7cfdf564d41ac2b941ab9a298',
    qyt: '5'
  },
  {
    addr: '0x2E1651B8f615F2959AA2daa8aACABE4E3D4b483D',
    qyt: '2'
  },
  {
    addr: '0xb1e2558bea5e7adb292c15d844205d1e72ed3f72',
    qyt: '2'
  },
  {
    addr: '0x5e4E4e0caF7975C84df544615d8571e8D3617093',
    qyt: '1'
  },
  {
    addr: '0xfA5ba7057546C73fF538627D4Ac74028f5aC286f',
    qyt: '2'
  },
  {
    addr: '0x08be7dF0AA516DdC5FDe47C2f3fEc88d2eD727d3',
    qyt: '1'
  },
  {
    addr: '0xf3ec29042a46bdf1859f00e6db68b528f7a1886e',
    qyt: '3'
  },
  {
    addr: '0xe48605f1c8939c7fd3ec358290b175b9664685e7',
    qyt: '2'
  },
  {
    addr: '0x8bebfd88d48cca9e2e271a4293effc052937cd37',
    qyt: '2'
  },
  {
    addr: '0xd34bc82b9092f5334056dac1af8cd5d32abc6802',
    qyt: '1'
  },
  {
    addr: '0x36c734521e29af1722f70cb4f4266d3a5b5183de',
    qyt: '2'
  },
  {
    addr: '0xae25a223ff1d8516558a8a3fd5a46779d47f7a17',
    qyt: '2'
  },
  {
    addr: '0x8cd32134847e764a3784a87c5a5b4c13474f516f',
    qyt: '3'
  },
  {
    addr: '0xfcfa49ad9d9884de29b197c501413b01a3361e1e',
    qyt: '3'
  },
  {
    addr: '0x17936accf6f51a9c1658f0f070b8bb3ae1ea16ed',
    qyt: '3'
  },
  {
    addr: '0x8C1D6c18E536efA634F8C75fD453A7657476C515',
    qyt: '1'
  },
  {
    addr: '0xe2ad14f95a07652c8113719334c5e244343cd7bd',
    qyt: '2'
  },
  {
    addr: '0x0c605f5e1444715FEb98D77B3db6153D6E8D881A',
    qyt: '1'
  },
  {
    addr: '0x67ddbD4b366896291470aD1aF9db375D9Cd270eA',
    qyt: '5'
  },
  {
    addr: '0x2c06c597c41754f7986fcb96c9e0831836b20979',
    qyt: '1'
  },
  {
    addr: '0x1835080cabe682971fe8ce87866146ccc4589b64',
    qyt: '3'
  },
  {
    addr: '0x3be41be04d559cebf93af8d657c5484fa1521861',
    qyt: '1'
  },
  {
    addr: '0xe054aadc95358ec29aadfc745abed40a5a8d3132',
    qyt: '8'
  },
  {
    addr: '0x33f5b5fe3cfec3a05fb1d06933542317f6d78205',
    qyt: '2'
  },
  {
    addr: '0x18cA774e4732a994FDb0c04b0100D82896714AC3',
    qyt: '2'
  },
  {
    addr: '0x157dd18cf70815df7c25948ced9760aa61f6df17',
    qyt: '2'
  },
  {
    addr: '0xb78dfae0fd33121a2a2751c599d6c35041940b02',
    qyt: '1'
  },
  {
    addr: '0x6baee966b06a7ed4d0dfe70b3bfaeaeaa0078ed8',
    qyt: '1'
  },
  {
    addr: '0x8816fbab5916eb5869de3e1da418be515953cf63',
    qyt: '4'
  },
  {
    addr: '0xfec2b8ccd8a57631a341a3ef4fe884b9751abf83',
    qyt: '1'
  },
  {
    addr: '0x991793676d709bf929f39ae6c0366cd5153a1786',
    qyt: '2'
  },
  {
    addr: '0x36c71b546f2e8d209594790ac2eddb5c969544b4',
    qyt: '1'
  },
  {
    addr: '0xCc8BB61DF7B805e3c9e200037Fc0dc1Aa5E431E3',
    qyt: '3'
  },
  {
    addr: '0x772F9c12A44c1D745500a4A1B38bB3474d082FD5',
    qyt: '1'
  },
  {
    addr: '0xe6d337a4ab4e9435d0cceda0b2a5e6f4e0a5c326',
    qyt: '2'
  },
  {
    addr: '0x7c638d0a6e8db0fa9a8305434096c90b59cd9ea4',
    qyt: '5'
  },
  {
    addr: '0xb35C33f403712d3367F5fc6cB24295E47bcCE628',
    qyt: '3'
  },
  {
    addr: '0xfbc33c5834ab88f6aa84e55ad8fe2dbf66250252',
    qyt: '1'
  },
  {
    addr: '0x88b0affd726af484fd687206e7a470c48c1bfc19',
    qyt: '5'
  },
  {
    addr: '0x411ec43529d6523A7F08e82135Fd3d17EC55390a',
    qyt: '5'
  },
  {
    addr: '0xc9c159e004073d66d048c4983c2b8f8ee440ce16',
    qyt: '4'
  },
  {
    addr: '0x2029cbfb9b4ce90fa0c596965d91c4f9759c7fa7',
    qyt: '2'
  },
  {
    addr: '0xc5f16770dcc9ce6e6f2e02f7afdc101b28926308',
    qyt: '8'
  },
  {
    addr: '0x51b472f07e601a06af481ca4cb8f218c15d155ef',
    qyt: '2'
  },
  {
    addr: '0x2083dcaefc99339551ea3989500cf9a7e24b0a1a',
    qyt: '3'
  },
  {
    addr: '0x85a904e996de55a4c3311de678917168083f44c7',
    qyt: '1'
  },
  {
    addr: '0x95b929941e5581a7d65dba4d319279c5c693bd15',
    qyt: '1'
  },
  {
    addr: '0x7e12418d351b36eee56ef34b00609146f550a09c',
    qyt: '1'
  },
  {
    addr: '0x9cba4916ab066795f57d2cd596981479e19ba0fd',
    qyt: '1'
  },
  {
    addr: '0x37c4A0C200087304b216625A2c6Dec644f425B3b',
    qyt: '1'
  },
  {
    addr: '0xec57915526a7869b9b5539b31c90bc81a060a171',
    qyt: '5'
  },
  {
    addr: '0x756e24515b4338d0fa9be2ffe344a1a02955ac51',
    qyt: '2'
  },
  {
    addr: '0xfb0f4b55450fd090ec4fd9d945689e3b16f334c9',
    qyt: '1'
  },
  {
    addr: '0x155cd1e6fb41630b0677e95be88b1424e87cf7c7',
    qyt: '3'
  },
  {
    addr: '0xafDfa7E9618c39CE38c6A6C6e93E6ad243e477f8',
    qyt: '2'
  },
  {
    addr: '0x5825e272941dc9b505710f48e224e0ffaa669ba9',
    qyt: '8'
  },
  {
    addr: '0xb58c56A87738D1845f350C6cB3668d5cA3D0f422',
    qyt: '1'
  },
  {
    addr: '0xbe2413bcafbf870139a229d37a29698ffe771c1d',
    qyt: '2'
  },
  {
    addr: '0xc4Eb02F8ADAf60535A45344B3b4dD18964109b2f',
    qyt: '1'
  },
  {
    addr: '0x7a3f32f04e1b5551c429f69c34f9bc238514d895',
    qyt: '1'
  },
  {
    addr: '0x203Ffb733708652CC71F63D28017f3da16038063',
    qyt: '3'
  },
  {
    addr: '0x061288856e426e566773AB2f2894B9Dbc20Fe78f',
    qyt: '1'
  },
  {
    addr: '0x530e81d8e4c72eb2446b92cbe371d7014276954a',
    qyt: '3'
  },
  {
    addr: '0x11d747c8faa501d5ef6593c70b8cdfa0524498e0',
    qyt: '5'
  },
  {
    addr: '0x624a43334f80613a695a0a080819f4e033fce428',
    qyt: '1'
  },
  {
    addr: '0xfee886f47719050226ca7e17efe7bfed3da8bc46',
    qyt: '3'
  },
  {
    addr: '0x1ed553c1ae7bb3009c1347197b2f80ab2d5f1a51',
    qyt: '2'
  },
  {
    addr: '0x81edcc5fcffe5812863e950cc99a939b6f6ca02e',
    qyt: '2'
  },
  {
    addr: '0xf123d83b576f9527846abcec04059183c79ad11c',
    qyt: '2'
  },
  {
    addr: '0x71331b8ffe8a35aa774ecf6475b10f2d78c509bd',
    qyt: '2'
  },
  {
    addr: '0x508B91400918fD3d10B28D117Bd308BED035E0c8',
    qyt: '1'
  },
  {
    addr: '0xdd249a4982bff8c17da24f3d5ab3896fccf56936',
    qyt: '2'
  },
  {
    addr: '0x1624e7cf6a98a3b8b4a55376cb9c4a88808e21bf',
    qyt: '2'
  },
  {
    addr: '0x850f776c88e7e2162ed598f455d8a6f4c0f5cc1d',
    qyt: '2'
  },
  {
    addr: '0xC9cC567bd46bECe16CaAC29A64991AE856cEA585',
    qyt: '1'
  },
  {
    addr: '0xaf8c109a7bee997beb400d03b20e38e13bfc2d2f',
    qyt: '1'
  },
  {
    addr: '0x3fbb61BEdaEA1C12430E6EE6D5EC62Ab23a4678B',
    qyt: '2'
  },
  {
    addr: '0x915e24decc8c33e0b2be0cfbf457ec71c0547a88',
    qyt: '1'
  },
  {
    addr: '0x057c4226eff9f7ed64f2d50cd866d5cb95c5bfdc',
    qyt: '5'
  },
  {
    addr: '0x959ea851c4d5c68abaa7812abff5af2088337dd2',
    qyt: '1'
  },
  {
    addr: '0xEe1c5421B9Ecd053FCaC0c5726471E195A759AA8',
    qyt: '1'
  },
  {
    addr: '0x8ac4578839382d74b6df86fa07f509975ffaf7d6',
    qyt: '2'
  },
  {
    addr: '0x343cbedf1a53e8a8d38e16dd9e25e56a2e711a0b',
    qyt: '1'
  },
  {
    addr: '0xe97e529158a9b72749ba0d95b224e19d56746814',
    qyt: '1'
  },
  {
    addr: '0x205409e8e0db31fb52d364773196625d3dffae51',
    qyt: '1'
  },
  {
    addr: '0x431023cea7739c04b77399e62f1f9af09fbbe2e3',
    qyt: '1'
  },
  {
    addr: '0xe8b009f0fab6d45970f1d5a874c92a3eb978e013',
    qyt: '5'
  },
  {
    addr: '0xa9681124f09eb4d8f64289bd654796e722ee482d',
    qyt: '3'
  },
  {
    addr: '0x045b57227552df007696c0392d5f5c4172d072ab',
    qyt: '5'
  },
  {
    addr: '0x5A20d32A24efC1aC361f16393F2e5a0914B77470',
    qyt: '2'
  },
  {
    addr: '0x489e00e8fb84225548fcd84f0aa42fcac62b73e7',
    qyt: '3'
  },
  {
    addr: '0xd727d178be7b9f6354d01974a396d6f37440605d',
    qyt: '1'
  },
  {
    addr: '0xa68d5b4a52836a2206be2a0bafaaccab5ae32463',
    qyt: '1'
  },
  {
    addr: '0x565cd0319fec2261ebde9e9e163e7ea625afe529',
    qyt: '1'
  },
  {
    addr: '0xb844a9241f46b2bf85f6268be98795d14a06166c',
    qyt: '2'
  },
  {
    addr: '0xdeb5a5db15379a24ca46cb8c473d0cc179855f52',
    qyt: '5'
  },
  {
    addr: '0x9d60fc843e87efeebd66a6420e7aeb4f6c5a4bda',
    qyt: '2'
  },
  {
    addr: '0xc73e51b4ac8a32d0b08f22ff53b9eddb94016675',
    qyt: '3'
  },
  {
    addr: '0x5b9e8bdd8b6ea310fd16230ebc6c093e6a0fb562',
    qyt: '2'
  },
  {
    addr: '0xa9429f238a4e2aafa37b8e72712dde89e64ed67c',
    qyt: '3'
  },
  {
    addr: '0xDe59972B741983F825c2F1fE41119bE62c12D879',
    qyt: '2'
  },
  {
    addr: '0xf03c1aaacefd47c0f16b81fa3ea75b00f50497ad',
    qyt: '1'
  },
  {
    addr: '0xe0affaa3f020062a9095750e187ce9818f73ed18',
    qyt: '2'
  },
  {
    addr: '0xae397e0a2f6a928e7ce935c13eb626cd0de35382',
    qyt: '1'
  },
  {
    addr: '0x9d6a41027568ffd408afe1c7e2a50861e0a0913e',
    qyt: '2'
  },
  {
    addr: '0xe8a5104b2aec9f8402b2eb2704f1740e5683a523',
    qyt: '2'
  },
  {
    addr: '0x280e76147abd2b385ed97fc9874f474bbfb67623',
    qyt: '1'
  },
  {
    addr: '0x89b3ca95b293331249140e6697145d5806b3976d',
    qyt: '2'
  },
  {
    addr: '0x86096686bbc522491ffd5ad694929d6fc0da952e',
    qyt: '2'
  },
  {
    addr: '0xe06F02Ee78DA6A53B96468817Dc0ac332aD2F816',
    qyt: '5'
  },
  {
    addr: '0xc034a7a871e995e69d7a9c288dfcec3b7a207c72',
    qyt: '4'
  },
  {
    addr: '0xaa312eb19552f924dfc47bfefe47f35a902fc595',
    qyt: '2'
  },
  {
    addr: '0xA226bB60c7B3f851D8eDd45C2f244dC03912188a',
    qyt: '2'
  },
  {
    addr: '0x8F16C91eF2D14c6358fF9763463583dc3DD041cD',
    qyt: '1'
  },
  {
    addr: '0x08ee0b5858ff0afbe729eb4ba127a2ee04a25eda',
    qyt: '2'
  },
  {
    addr: '0xd44334bcce983081d8b4b66e8b26a43736993fea',
    qyt: '2'
  },
  {
    addr: '0xb57de5a3cf8858746a5a1a8c7c8800c32a484842',
    qyt: '1'
  },
  {
    addr: '0x40559f40b0565a90d9803e6242c6403acc1225bd',
    qyt: '2'
  },
  {
    addr: '0xc0652bc42a407c4221267eeeffaf63fdcde3f086',
    qyt: '1'
  },
  {
    addr: '0x1a0cf07df88c8c2ed1f7ac1282be0360b0f1d42c',
    qyt: '3'
  },
  {
    addr: '0x33Cb7917f08a07467aaB26e07fd1120D839FacA2',
    qyt: '3'
  },
  {
    addr: '0xe5132511e0430ed53dcec6e25a022b52515651b2',
    qyt: '1'
  },
  {
    addr: '0x76ae13b7e1303a6d17d963eccfb23a5139dc8d3b',
    qyt: '6'
  },
  {
    addr: '0xe397ba68483fb1a90da07998d215c3a720bc9117',
    qyt: '4'
  },
  {
    addr: '0xc27cc1f85efedff5731f4607cfbbbfe2d7695117',
    qyt: '1'
  },
  {
    addr: '0x5Ac43717d7d5f03B53F9bf9Fe074cF25C446efb7',
    qyt: '2'
  },
  {
    addr: '0xb4529458db49cd07c57e5144b91f664a234c300b',
    qyt: '2'
  },
  {
    addr: '0xb5041baa25f84d26e79a67c849675ce2d473f144',
    qyt: '2'
  },
  {
    addr: '0x2f989ff4529832a4a20e121aa16afc511686ef4a',
    qyt: '2'
  },
  {
    addr: '0x668ce1ee8ef24426ea3f15a8955ef0515e584e26',
    qyt: '5'
  },
  {
    addr: '0xC3C87DE146A7A597be193aF4F31BFc27Ca20Fe74',
    qyt: '2'
  },
  {
    addr: '0x627d45c82d218b7e2ee5cec3784144f13367b323',
    qyt: '1'
  },
  {
    addr: '0xd645C3f4e4D9eA8BAf663aB13daC4F20B56e3b77',
    qyt: '1'
  },
  {
    addr: '0x5586a2ff1f70fbba43c507e17c1f08062637a184',
    qyt: '2'
  },
  {
    addr: '0xc331b092ead6e1b5167815a2385d059b683a37ac',
    qyt: '1'
  },
  {
    addr: '0x452e2ee0be3410396749a40dea8d96196f706593',
    qyt: '1'
  },
  {
    addr: '0xaCc99ADE3201faA3905B285065D6DF9b71Ec553F',
    qyt: '1'
  },
  {
    addr: '0x994bb7ed1a55014caa69de65ca09e0837976a8a0',
    qyt: '3'
  },
  {
    addr: '0x9c421d817d620c80cdbe65919d2441ff3560a640',
    qyt: '1'
  },
  {
    addr: '0xf78c830afbb45d5c440bd676de452c628ffeaef6',
    qyt: '2'
  },
  {
    addr: '0x77c20dbef3970e559ea465370a765fb1c32fbfb0',
    qyt: '2'
  },
  {
    addr: '0x0d3a7665ade9f03e7603e1c036d6ee0ca16761a3',
    qyt: '1'
  },
  {
    addr: '0xc1f73a1a8131ba2f0cb0d2fab3fdadd8779cb396',
    qyt: '1'
  },
  {
    addr: '0x51182c0f563002693598068f1F2aB540E082fD6a',
    qyt: '2'
  },
  {
    addr: '0xb128def6ffae58bd85ac65bafd0a22066e2867a7',
    qyt: '1'
  },
  {
    addr: '0x19775e1817bc7a1e41060f80e0d00eabae2959a7',
    qyt: '5'
  },
  {
    addr: '0x059cb7f78efe99ff928aafeab8746f9b23a2745a',
    qyt: '1'
  },
  {
    addr: '0x45daea3fc3607925eb87d2e47814ca7584cfeefd',
    qyt: '2'
  },
  {
    addr: '0x3b42df4d64623468349b817dc25d0b633855f1ea',
    qyt: '3'
  },
  {
    addr: '0x149A40d57c73FC7748dA6Ce9cC1f92204ed01726',
    qyt: '3'
  },
  {
    addr: '0x0edefa91e99da1eddd1372c1743a63b1595fc413',
    qyt: '8'
  },
  {
    addr: '0x6a3ab63a4b5f2a07230dc0c49105173e14b401f3',
    qyt: '2'
  },
  {
    addr: '0x56c70fe90ea38043b55a67a7ffd81f7309155e5c',
    qyt: '1'
  },
  {
    addr: '0xc5e1850d4b8315fa0b91ecf76aa6e4e1d5af1c0c',
    qyt: '2'
  },
  {
    addr: '0xf21fb214b2cda855e3545027180f94acc281b330',
    qyt: '2'
  },
  {
    addr: '0x6103b97d619d28a51fade902a7d8fbb2e29fa7b7',
    qyt: '3'
  },
  {
    addr: '0xb955915d267a7feca6b9781331e61fce4fbdd3ac',
    qyt: '4'
  },
  {
    addr: '0x94692594d24814ed116a904d2b86a667516d7590',
    qyt: '2'
  },
  {
    addr: '0xd75333966a343e875cd7e57f6cbce27721a9ced3',
    qyt: '1'
  },
  {
    addr: '0x4e356b8760b3bd49a97aead318ad712b2ddb5c64',
    qyt: '2'
  },
  {
    addr: '0xc94894b2f11f68cf41e493673c5ee6cdc52e28d4',
    qyt: '3'
  },
  {
    addr: '0x58e5913710A3B83E3825CE085e00Ec42472f5ebb',
    qyt: '6'
  },
  {
    addr: '0x9dea85ebcefd37e2426e9efe04a7ebb28676d61c',
    qyt: '3'
  },
  {
    addr: '0x9aa9db1ef6114e8089d3be985ae0a8b3be207a18',
    qyt: '2'
  },
  {
    addr: '0x9e651ced53fae8d3dce8c0f6f5889406b8602157',
    qyt: '1'
  },
  {
    addr: '0xa2f649d92c2f0f8bebbb5448276e9cbe08ed4825',
    qyt: '2'
  },
  {
    addr: '0xf00e545e01ca8b46c0a784c0f74d074fceec8c94',
    qyt: '2'
  },
  {
    addr: '0xb1c9309db8dddf9507939833e74044e319e75d6f',
    qyt: '3'
  },
  {
    addr: '0x984594c6d810f8af64641522afa0b99f69021dcc',
    qyt: '1'
  },
  {
    addr: '0xbf0b7bcb58ed411f702526c5307760d38a7682ce',
    qyt: '2'
  },
  {
    addr: '0xfad8b99f8e575e08a77390957b423e404108d1fa',
    qyt: '5'
  },
  {
    addr: '0x80b8dc40665a3f00069fb8a40f954a13360cfbcd',
    qyt: '2'
  },
  {
    addr: '0x2b71b81Dd292c62b9F117D3bbFdf92A76a4Fff48',
    qyt: '4'
  },
  {
    addr: '0x7602072eb173643c26d61f7007fb1457986b9af3',
    qyt: '5'
  },
  {
    addr: '0x9d9c68683c6486869b5cb1465ed7b4cb51d8520e',
    qyt: '4'
  },
  {
    addr: '0x3b5D9fA4Dc0bb87D44BC205352455E87a8Ebbd81',
    qyt: '1'
  },
  {
    addr: '0xdd8e898b37eaf390cf53790f2ea2cabedac7afa2',
    qyt: '4'
  },
  {
    addr: '0xeb9c583d265485063e1414cab9a3c0d0158f20ba',
    qyt: '5'
  },
  {
    addr: '0xAD6c009BD37465DB5a0452D1f159072715bcf49E',
    qyt: '5'
  },
  {
    addr: '0x3608cdd236c83b02ced7fb065e20b6715aaeba4a',
    qyt: '1'
  },
  {
    addr: '0x1b74819107fbdd61baf0b5cae7997d7b93d8f682',
    qyt: '2'
  },
  {
    addr: '0x908ac3dd406d973cab8caa7205ef5f0c6ffb7b5f',
    qyt: '3'
  },
  {
    addr: '0xe7eb4399ee20960bb530becc4ac0f516b60ecbd2',
    qyt: '2'
  },
  {
    addr: '0x8a8a7c77989e2d1a0b3dc63b503f8aeb956b71d4',
    qyt: '1'
  },
  {
    addr: '0xb8313d56b6cd69be70c512db95bf456e2e1b5d9b',
    qyt: '5'
  },
  {
    addr: '0xfc1D46fAE1Cd6fCfA2d34306aF2CB6D67A7d55D8',
    qyt: '3'
  },
  {
    addr: '0x8967c9ba38689368d2af2958854ede5d8b3916cc',
    qyt: '2'
  },
  {
    addr: '0x5e072207cbe26a008c032288bf6f16a1995e74f4',
    qyt: '1'
  },
  {
    addr: '0x2de98addfe9edda7b67a8e7e449aa3be0cbc357e',
    qyt: '1'
  },
  {
    addr: '0x462e9fd5ed5867fac974e0e2213a839afe75014b',
    qyt: '1'
  },
  {
    addr: '0xa9b7A9C306349AfFEa41Bea003743F4b43E1770d',
    qyt: '5'
  },
  {
    addr: '0xc8784cd37a7e532dc409f272055dbc81ba139cbf',
    qyt: '3'
  },
  {
    addr: '0x22160987512f283ac9a8786baa6c4ec2edaf1a8d',
    qyt: '2'
  },
  {
    addr: '0x08733a6178938E32B22A2CB9caf4b3C99C4Edb3A',
    qyt: '1'
  },
  {
    addr: '0x162CD02C51F327549B9068Ff11Fb3F2547d3707C',
    qyt: '1'
  },
  {
    addr: '0xfab38aeb174ef1c627b29c5b38017185a8d9612b',
    qyt: '7'
  },
  {
    addr: '0xfe5db85d99d7290d360342a79eb3e76cd8d425bb',
    qyt: '3'
  },
  {
    addr: '0x931206d3e5c540070f4ce40c9993e9af70790004',
    qyt: '1'
  },
  {
    addr: '0x8EA9251D6b39bf630116c2CC5212f788e9E58462',
    qyt: '2'
  },
  {
    addr: '0x5ea6819692e467afa8d708a6a2ec4de22a9d14b5',
    qyt: '5'
  },
  {
    addr: '0x929476543B6c37B6ef31f7DF044De7BC02870278',
    qyt: '1'
  },
  {
    addr: '0xcc203d4a48f94bcc4af700abcbfca0dffcd19573',
    qyt: '1'
  },
  {
    addr: '0x0864a177ee8a5d39809a46700b01d2eb51c84444',
    qyt: '1'
  },
  {
    addr: '0xA96B008991Fa7296aBfD46A1fC2E5c142eB9206b',
    qyt: '1'
  },
  {
    addr: '0x42d6E1C76F1e927e52660483FedE3a7739Ee927B',
    qyt: '1'
  },
  {
    addr: '0x15c884a2ad9742e0fb8c5d95303a24299aa7bf4c',
    qyt: '1'
  },
  {
    addr: '0x75d0775650ac060968b8fca94dcdffa813e79741',
    qyt: '2'
  },
  {
    addr: '0x2cd78968951150f35be45f586bc28562e2026baf',
    qyt: '1'
  },
  {
    addr: '0x48Bea1C1dAc1335dEA65989380Bf82D888256983',
    qyt: '3'
  },
  {
    addr: '0x77021BF8dc293628D341365B9018D18605B56dD5',
    qyt: '2'
  },
  {
    addr: '0x64bbae9c7753de2f630e567b480e8e86c3881e58',
    qyt: '7'
  },
  {
    addr: '0xE598838A35b74275db31db189D93448C76b436E7',
    qyt: '2'
  },
  {
    addr: '0xa4907172f94a12c17267e848138c387c06bd32a7',
    qyt: '2'
  },
  {
    addr: '0x734008778169de832e3dbadab25532a9d3bdde44',
    qyt: '2'
  },
  {
    addr: '0x5a8307968122d1df52ca1e6093d4221f318881ba',
    qyt: '1'
  },
  {
    addr: '0x76c5aed091d4915e9e68d928bae37e2b744c11b1',
    qyt: '2'
  },
  {
    addr: '0x5ce6e4b2df35e7ec28a0a72a8bf14e98ffba2b0b',
    qyt: '1'
  },
  {
    addr: '0x3688c35a22b801e20e0d363cbb0e285bca079fbb',
    qyt: '2'
  },
  {
    addr: '0x2f21b2a76dd4b49d2d4d6517c47064115c5cc045',
    qyt: '3'
  },
  {
    addr: '0x378F408560F2451E72569a7A357FfB437f0920B3',
    qyt: '2'
  },
  {
    addr: '0x27Ae3ac877D061e1C56bfAA680b83Cbe477A2d20',
    qyt: '2'
  },
  {
    addr: '0x935f1501d0f24bc3c52a5cbc59bf26db72a9d795',
    qyt: '1'
  },
  {
    addr: '0x3ed42537a583a12e02ecdea36e146d8dced73a87',
    qyt: '1'
  },
  {
    addr: '0x2039692a113a81716d046c14b0cc4f62d480e3a5',
    qyt: '6'
  },
  {
    addr: '0x61591BC21a08dfC77E949c707C8F5741547CC55d',
    qyt: '2'
  },
  {
    addr: '0xa1206bdb82af89c1b7ac0dd616b062e44e33d18c',
    qyt: '4'
  },
  {
    addr: '0x5369787575a1f238eac2dd1b3c982781a40e2e05',
    qyt: '2'
  },
  {
    addr: '0xbc4B909f8b1c41dE154A9DE3705d4B99B3C99aA7',
    qyt: '1'
  },
  {
    addr: '0x8a167df07d147db336bb32ed97e7d5ea0ba5c1b2',
    qyt: '3'
  },
  {
    addr: '0x3efEE43499530099360a718c92389E3a58673Ddb',
    qyt: '2'
  },
  {
    addr: '0x299f161675b9ff035437de06c14269bf05e40b28',
    qyt: '5'
  },
  {
    addr: '0xd7813a6c8ae0adfae06da74266e48310677e0e2f',
    qyt: '3'
  },
  {
    addr: '0x3A237FdAfBa995E4E9cD3BBEFF4Ada27062B0B4e',
    qyt: '1'
  },
  {
    addr: '0x9b378EcBbB7453173420990f47e3A5Abe3B2Cd0c',
    qyt: '5'
  },
  {
    addr: '0xB99085007779F225bEB7ea8FF8c950DC5AE54A1D',
    qyt: '1'
  },
  {
    addr: '0xb5a57c1d07cc2f0468764df68bfe45b91a62f440',
    qyt: '4'
  },
  {
    addr: '0xeb42579f7cac70159fecf2684697dcce28c4fcc6',
    qyt: '1'
  },
  {
    addr: '0x7c236a719c0af04c78ec0074b44734c3573d71a1',
    qyt: '1'
  },
  {
    addr: '0x5446287D13293132f865F2a637073DF0c9419c7c',
    qyt: '2'
  },
  {
    addr: '0xbd4fed4fcb8b6c5ce567d2c83cddeba76aefe20e',
    qyt: '2'
  },
  {
    addr: '0x389b69fa9f1cdaa3c2f5a678c10854b242df65e7',
    qyt: '2'
  },
  {
    addr: '0xfcc9aa19539ab6d423ca528f1c2e97ab2b9def7c',
    qyt: '1'
  },
  {
    addr: '0xd4c803a4761a8da89b66057d496865f3782c7868',
    qyt: '2'
  },
  {
    addr: '0x114b364fd6e67ae35d239c525ed0544cbfaa7a63',
    qyt: '1'
  },
  {
    addr: '0x44a17fd4eb1b37f6ed7ca3ea8e0fd5b8d41c2bcc',
    qyt: '2'
  },
  {
    addr: '0x830c5a932ad3a2ed335b88257a870eafaef84d5e',
    qyt: '2'
  },
  {
    addr: '0xfc39c352d3be71ff36febe099057aeafa271f904',
    qyt: '1'
  },
  {
    addr: '0xc4f6efdedf62a3a227f5e9abc03e029df68bff26',
    qyt: '1'
  },
  {
    addr: '0xA30374dDaA7e7F45c7a3B4D2BA5b7FdF73067d10',
    qyt: '1'
  },
  {
    addr: '0x92c56d66eA502f709E14CE5a5832AD98051fD561',
    qyt: '1'
  },
  {
    addr: '0xc95738e3294e2d16f23ec25aad936153643dd3af',
    qyt: '1'
  },
  {
    addr: '0x768491d96f598000015b68DbA93C54E8b75B7bCB',
    qyt: '1'
  },
  {
    addr: '0x0b8e59974cfaad109c05c786510b1fab813c1c80',
    qyt: '1'
  },
  {
    addr: '0xcf14d06d2591efeeac63a566189cdad4aab9aa42',
    qyt: '2'
  },
  {
    addr: '0x25594d159e5a6af651aaaf012a678211c01cd1ae',
    qyt: '1'
  },
  {
    addr: '0xA79fdaF12c2dE7020b336F67A4222893C0930039',
    qyt: '5'
  },
  {
    addr: '0xf13c64502ae7c95944c7feefe74f15e347299cc6',
    qyt: '3'
  },
  {
    addr: '0x15320af6a747a548ac800abe1486991ce79b4833',
    qyt: '2'
  },
  {
    addr: '0xFd730D544dB1846f19927FC0Dd8BeF21aeF4745b',
    qyt: '4'
  },
  {
    addr: '0xb3f0a1437f1F5Dd17c5D69d0DBaA58b55aA48420',
    qyt: '2'
  },
  {
    addr: '0xf53E32c6ec363EaF522df746c85FD97f5b71c9f5',
    qyt: '1'
  },
  {
    addr: '0x78D2f5De1D6E28e06bcDF77925a7d8720E1AF49a',
    qyt: '2'
  },
  {
    addr: '0xc9Ae5d6EcCFC7591f2d343bB31b9c2c1B60F1E8D',
    qyt: '1'
  },
  {
    addr: '0x950b8E53597759D5fA8cfEE3820b2e78A783EC50',
    qyt: '2'
  },
  {
    addr: '0xdf753332e296be3fcde0ea96cc53a368468d2b5e',
    qyt: '2'
  },
  {
    addr: '0xf840a6B8E3bDa639AfC41022De65CCFbdcd03B15',
    qyt: '2'
  },
  {
    addr: '0xD8cd2F0BdF4b5f6793287C4eDa45B87EF4229315',
    qyt: '2'
  },
  {
    addr: '0xDde520bCa677138130a924E52eB1674097B05e5b',
    qyt: '1'
  },
  {
    addr: '0x7e82FcC945F2e6ba9C01D9b29399427FB3b18C15',
    qyt: '2'
  },
  {
    addr: '0xe9615000e363c13f8c11311cEc2cB17825B13562',
    qyt: '1'
  },
  {
    addr: '0x908Ca4AE9BaB8D87Acf1BaEaD0eCbb92Da6619Bc',
    qyt: '3'
  },
  {
    addr: '0x9f07e90096d100935065f8602140dacf92adfa3d',
    qyt: '2'
  },
  {
    addr: '0x48d7f1C7789d03F88D61E59a1F97C0967aF6b168',
    qyt: '1'
  },
  {
    addr: '0x9a7a36e2c594ab5d702b6fb5fcf9a2b49f6b4a43',
    qyt: '2'
  },
  {
    addr: '0x53d2f7Cd3f1D41748F95FDc055B6Be300637c51B',
    qyt: '1'
  },
  {
    addr: '0x813e7c3e5AEDe78ccBc4455E41be881CA9600E5e',
    qyt: '1'
  },
  {
    addr: '0xC42177d6819793ef8be4e5D2f9315A16C8CBbc75',
    qyt: '2'
  },
  {
    addr: '0xF4502A508e955faa7E643b0e571b9682803F99Dd',
    qyt: '2'
  },
  {
    addr: '0xCd5C3716994b141d4AC2382e233B72549Ff3c84B',
    qyt: '5'
  },
  {
    addr: '0x8C6d2a0b184c1F4354aF7a6aB46Cce0Ca4E2e0B9',
    qyt: '1'
  },
  {
    addr: '0x64CB4e8ccc8B0800F49E84A11543cC03b50F7076',
    qyt: '3'
  },
  {
    addr: '0x1Dc15a2b6D0089A14C68dFB818B2B7548BD4E6Eb',
    qyt: '1'
  },
  {
    addr: '0x03e002Dd44185cBC34d0A1bbed977D53c8499fDB',
    qyt: '2'
  },
  {
    addr: '0x21c8C1A785F2Cfb7B15CA78e4c95d880CA305772',
    qyt: '5'
  },
  {
    addr: '0xdba5a2d01943cc165d8f03bfbb5da71592640e06',
    qyt: '2'
  },
  {
    addr: '0x83f37b93c28cff1f669d7cc5cede0fbc1d29e057',
    qyt: '2'
  },
  {
    addr: '0xd92eb2f364f73bbe222e5a83477523ea634644b3',
    qyt: '2'
  },
  {
    addr: '0x0c21909a33c67f27f9ebb47486d8a32f7290936e',
    qyt: '2'
  },
  {
    addr: '0x1beb509874d823e6bea7404b2f2793ec8659f63a',
    qyt: '2'
  },
  {
    addr: '0xad9bf69eac9cd22fc9120fa46c79f47a386009d8',
    qyt: '2'
  },
  {
    addr: '0xd6e2d2486b4344fee0fa84a63d81c053df579dba',
    qyt: '6'
  },
  {
    addr: '0x95b954ef9696A88BE66448Eb603cb514461516Ac',
    qyt: '2'
  },
  {
    addr: '0xb22342df0c8c07b26619843be004b89b35020ed3',
    qyt: '1'
  },
  {
    addr: '0x1b5fa8f2bccf49d65002df0e3c740fcfc005af73',
    qyt: '2'
  },
  {
    addr: '0x6821a47bdd8b56fb5f78f810cbbcada9f1a575c9',
    qyt: '1'
  },
  {
    addr: '0x55972b788d05b65075b5f7e56a3f14bc1bcfcc15',
    qyt: '1'
  },
  {
    addr: '0x691f8d30d16f75bbd443b2377219f70725a43e50',
    qyt: '6'
  },
  {
    addr: '0xC61209a4505Bc278e80b8F1910e80db3bea83fc0',
    qyt: '1'
  },
  {
    addr: '0x6809310f14a80ab32a30a15729574f90d694aa6f',
    qyt: '1'
  },
  {
    addr: '0xbAbF79a79922b50Deb5a690C4b726534e74fF787',
    qyt: '3'
  },
  {
    addr: '0x8f869a1f15ec377d0909fd454dca5f9982f482b6',
    qyt: '2'
  },
  {
    addr: '0xd63d225186adeb92f8f73f355ba0adf999b2bf56',
    qyt: '3'
  },
  {
    addr: '0x793d5ce6995269edb7ea0883e3797ef600a489ed',
    qyt: '2'
  },
  {
    addr: '0xa55f538412db16dfd281e81d147f489f05424d3a',
    qyt: '3'
  },
  {
    addr: '0x5B4EDaD5fb4f014D2cF91ba08a9C6202A84103d6',
    qyt: '1'
  },
  {
    addr: '0x6445F1DEd7a10Dc2367fB3e3d01931E62DC7e3EF',
    qyt: '1'
  },
  {
    addr: '0x9498cdfcae2fa50f4c5a98f2ff2224575e24dbcb',
    qyt: '1'
  },
  {
    addr: '0x8435f1d7804e7f825457ba47b3e86a10e551a509',
    qyt: '1'
  },
  {
    addr: '0x41FA7ef9e3DD14c5F6Bb59a604ffB64768801fAb',
    qyt: '4'
  },
  {
    addr: '0x81e7ef5219a34d8c009fb8c219ebad5cf8b9fc50',
    qyt: '1'
  },
  {
    addr: '0x96aa9a11931f09e8096b20a8d3b6cccca5e99bd1',
    qyt: '2'
  },
  {
    addr: '0x4A3b2D78D3928ae7C697E97681c09C5edaae148d',
    qyt: '2'
  },
  {
    addr: '0xC2753A6777a15473eD561EB76Da7d2F9851da35a',
    qyt: '1'
  },
  {
    addr: '0x3e1a73e74120f30997578ddcdcb573ba2e0fb180',
    qyt: '2'
  },
  {
    addr: '0x2b663146c0f678c06130e7992b71c11d8ae58994',
    qyt: '4'
  },
  {
    addr: '0xE5DfC6b34C725c931Daa7C502c96bCdecC8C6103',
    qyt: '1'
  },
  {
    addr: '0x06dcbe14ba1ac1d4f910b4a325bae06a4ffb4b92',
    qyt: '1'
  },
  {
    addr: '0x4cfd629a1ffc6272bc846b58a213fd622479a08e',
    qyt: '3'
  },
  {
    addr: '0x926ff2ee5fb25e46d2490ffbc71c5df3c0630cc7',
    qyt: '7'
  },
  {
    addr: '0xcd19515eA43CE11d01b93bF2e8709b9f2bC0bA08',
    qyt: '1'
  },
  {
    addr: '0x59efa89604f0c1cd390844b7108484d6ca7e4ca6',
    qyt: '2'
  },
  {
    addr: '0x92d95bc1c9a3857fb56f3e5faa38eff6e631fe27',
    qyt: '5'
  },
  {
    addr: '0x220C9A16b6CB9e35773399CE4B92aDDdbd2bDe3C',
    qyt: '3'
  },
  {
    addr: '0x52a3f1308fe0bd88dac88b6b2483694c2b3d7461',
    qyt: '5'
  },
  {
    addr: '0xac9cd1ff06a9ab25fc47bd23a05d61277883d991',
    qyt: '2'
  },
  {
    addr: '0x0B3974F67836C718db34Ddcd7d1C66a38AF8C430',
    qyt: '1'
  },
  {
    addr: '0xc23f9f5c58cb582478b9735d191e8bbdbe25902f',
    qyt: '2'
  },
  {
    addr: '0x95a6b6613b64876f3c2f142c8990bd4f06747c71',
    qyt: '1'
  },
  {
    addr: '0x9225ab271144fe3bd17fd9d0cede564a70a6aff9',
    qyt: '1'
  },
  {
    addr: '0x511918111A504539213d0D6D88D1516B88ffE5eB',
    qyt: '2'
  },
  {
    addr: '0x1f97915f43C082602aA06C27D954B830A948DF19',
    qyt: '2'
  },
  {
    addr: '0x5ec63ad5aa54164b4fa01f698161b630a2700257',
    qyt: '1'
  },
  {
    addr: '0xC578d86D265CA1afD120ffD000D2af21bf08fFe8',
    qyt: '2'
  },
  {
    addr: '0x60102c2b4614499eaa13c62a0c267577e6461bd1',
    qyt: '1'
  },
  {
    addr: '0x66f75ae7ec5ec26602ff3e2e470bfa81f5a41147',
    qyt: '1'
  },
  {
    addr: '0xa0ff1b39d456239249069efa631439c11b377a3d',
    qyt: '2'
  },
  {
    addr: '0x3fc638B051535F0f0e9486963C71c3f717Ac25d0',
    qyt: '3'
  },
  {
    addr: '0xc0a5a58de52477930d98085e7011b66a3468de33',
    qyt: '2'
  },
  {
    addr: '0xBBEe983D0917eE9f8c9F34b4d01d11Daa923f228',
    qyt: '1'
  },
  {
    addr: '0x99d3a5dd973cf2abe21038106d975275d66f3a94',
    qyt: '1'
  },
  {
    addr: '0xb5161e0ee5767cae0b6cfc63af92a78b3c0d17b1',
    qyt: '1'
  },
  {
    addr: '0xe7ee1064a49cb72627f44daa500262f2c01b9c7d',
    qyt: '4'
  },
  {
    addr: '0xEF04cD663045C47A9C04a0FF92BD748B193e4538',
    qyt: '2'
  },
  {
    addr: '0x8c1c50c72817f5bff32ca5bfeb2445fe596e8ce3',
    qyt: '3'
  },
  {
    addr: '0x1826e38bf7c8a79283d82e72e3a5e96a009287ab',
    qyt: '7'
  },
  {
    addr: '0x7a89e9044fa5959fb6006441fa1278975c777b9d',
    qyt: '3'
  },
  {
    addr: '0xa522b71ffeb8ee984a96aa8f10766e1369a01f95',
    qyt: '2'
  },
  {
    addr: '0x70f55cd90a052b1e8d371eb1c0b21ed3e0f23fce',
    qyt: '4'
  },
  {
    addr: '0xe1411f819b936046a34867621c1d15c434bd978e',
    qyt: '1'
  },
  {
    addr: '0x0DD669f1ED5FC90A582995968b8E31AA1788690e',
    qyt: '1'
  },
  {
    addr: '0xe20a0da5340ccd830394deb33167767016b151cb',
    qyt: '3'
  },
  {
    addr: '0x5fE1B00922eC40c3356C446fc890d45eAdFea43a',
    qyt: '2'
  },
  {
    addr: '0xce1e3b7399867ea2403bd0dac609111e9ffe171d',
    qyt: '3'
  },
  {
    addr: '0x9298f401225390d44a854e0b58f4e45e81fdd5db',
    qyt: '1'
  },
  {
    addr: '0xd9e25d1e517245ccb8bdd6c3dfcca4b44a262d67',
    qyt: '3'
  },
  {
    addr: '0x3721666f5cdd3878a779697f217c6a69156b06f1',
    qyt: '5'
  },
  {
    addr: '0x09bb57ad3d76c0cc0d4498bd4b9d6e60d95a1463',
    qyt: '1'
  },
  {
    addr: '0x17bB37B1e6607c2D4eBbd6a02ed013462B9a03AF',
    qyt: '2'
  },
  {
    addr: '0xf22e7877a33b211e9831cf577f62a60a7c202fe7',
    qyt: '2'
  },
  {
    addr: '0xc54A1160470e4a6bd21fe95A7c9b8AFa908b5426',
    qyt: '3'
  },
  {
    addr: '0xc9ea02554edc7b78fe36e5c49c2ef49ef1f2a4ac',
    qyt: '1'
  },
  {
    addr: '0xa3bc1b0c0205847b5fad18758571bddad56e5797',
    qyt: '1'
  },
  {
    addr: '0x7406a9a9f2e9c7b898497bba967a199f9f1e85d8',
    qyt: '2'
  },
  {
    addr: '0x8ad4D9C06C404c310cC9Cf6d343a2c81Bfe856FE',
    qyt: '5'
  },
  {
    addr: '0xb3d46fe7f6f732b89b99b70d0e681857710c1cee',
    qyt: '3'
  },
  {
    addr: '0xd8d462958fe3194cabb95d502f1ad7ddcf7e0558',
    qyt: '3'
  },
  {
    addr: '0x70f0096a58770619a8D0c8d90b15b0FDdC12F713',
    qyt: '3'
  },
  {
    addr: '0x32C0b3269ad817a324cE712E9352e824519335aC',
    qyt: '2'
  },
  {
    addr: '0x39a280DD2407F7D41f427fb63C4914d5d0EAF230',
    qyt: '2'
  },
  {
    addr: '0x64045b360BD75DA7BB073258fed472FaAcF3Dda9',
    qyt: '1'
  },
  {
    addr: '0x1B95Ae163a04c85F20E513a972135d651f2DDd8c',
    qyt: '1'
  },
  {
    addr: '0xa13f528B14668f2F12Cc424E96e2Ca7943815E08',
    qyt: '5'
  },
  {
    addr: '0x70f02712dffbedd3591e38d87885ff62191ca5b7',
    qyt: '5'
  },
  {
    addr: '0x416927E4Ce46BEAeFADa8441A98FE250d3cf21e9',
    qyt: '6'
  },
  {
    addr: '0xcf72ef2b93410041c86bd7f13b04b686e5bed30c',
    qyt: '2'
  },
  {
    addr: '0xc13d90a1a88553f55bbb10ff6897dee6d6478771',
    qyt: '2'
  },
  {
    addr: '0x3979adc0111a79999a1f457dcdf99e494cf66e70',
    qyt: '2'
  },
  {
    addr: '0x8b946e216f6c86b22cbdf24fc4b78a6823eb8404',
    qyt: '1'
  },
  {
    addr: '0xceaee6ca056dd80d6646ec81f29445ca7980f651',
    qyt: '1'
  },
  {
    addr: '0x94994d37c8e82110b67c01eab2921035eedde9fd',
    qyt: '1'
  },
  {
    addr: '0x1913d08b935a812688fb4991ed4ac364ada1fbe7',
    qyt: '3'
  },
  {
    addr: '0x9f8f5450182175bb5595edb3c0e5ec4021f0303f',
    qyt: '1'
  },
  {
    addr: '0x8e2f0a5167eadbaede49d25d293e5d54d1ba3e0f',
    qyt: '1'
  },
  {
    addr: '0x8cd6127c34b3ac7749430ae466a0e8f9ebe0d364',
    qyt: '4'
  },
  {
    addr: '0x3edd4feefd261350dc0782314137c2d0c8d19de8',
    qyt: '5'
  },
  {
    addr: '0xfe775534e01f3f67466bf0bd2f99e158dabe2335',
    qyt: '2'
  },
  {
    addr: '0xbaa320a61ecaae2f0972826bdf5bc8311ed05dc1',
    qyt: '1'
  },
  {
    addr: '0xa2eb7a658aceb542b1593d1c0d94bb15b2b45436',
    qyt: '1'
  },
  {
    addr: '0xb06F88cE61cDbCFCA27Db089B4411651AAE8F3bb',
    qyt: '2'
  },
  {
    addr: '0x9b3b5703ef308a62cada057305c58a86ba14a08b',
    qyt: '3'
  },
  {
    addr: '0x7bd5428f2aac44d52158411c9d093148d73943d6',
    qyt: '5'
  },
  {
    addr: '0xc49ffe353a2431fe6c08e4dd5e7b3e5bb033bd1b',
    qyt: '4'
  },
  {
    addr: '0x08bb0ee39136639f29e0e58f5f452a337b7f6116',
    qyt: '1'
  },
  {
    addr: '0x3eec75b57cd0e2812633a68b900f2b8269fbc47a',
    qyt: '3'
  },
  {
    addr: '0x1D89247ecc30F02C54E6aAdeB0A20975b91a2139',
    qyt: '1'
  },
  {
    addr: '0xe97c7808cf24dd6c4e058d85c6ba9e99ef611fb1',
    qyt: '1'
  },
  {
    addr: '0x721d4dec027c5df85745b5448e893dc4d6a11fcd',
    qyt: '2'
  },
  {
    addr: '0xfaea73cf3243da167dd2c0d8a1865a5570c08a10',
    qyt: '6'
  },
  {
    addr: '0xd857cc69c6b8728b24f7d776babf3f6a8a39057d',
    qyt: '5'
  },
  {
    addr: '0xd7c010a8b1cea08833077675fb9f5f023772207e',
    qyt: '3'
  },
  {
    addr: '0xa9498d15714b67851c370be63e3bd9345cffdfb7',
    qyt: '1'
  },
  {
    addr: '0x1E7F7884fa18C65b047DdE92552A0a76cbB4eA07',
    qyt: '2'
  },
  {
    addr: '0x0c6909b890507efae9bf5fd9b08729e850c6317e',
    qyt: '5'
  },
  {
    addr: '0xE642174cf6f7E0b924459fa8f93D533B5ABD9680',
    qyt: '1'
  },
  {
    addr: '0x801a2c676733ddda72ba1f55910833767a1a3f05',
    qyt: '3'
  },
  {
    addr: '0x4409279fbe40a05c437b887b44dab23bbc501e14',
    qyt: '1'
  },
  {
    addr: '0xd579554c4579e665f7492ad19f7477a7c047b08e',
    qyt: '4'
  },
  {
    addr: '0xe85407a91cec111d124e2b4909e98a82dda1e747',
    qyt: '1'
  },
  {
    addr: '0x5c5930e579387B2947f21De3fAD8b4855168f867',
    qyt: '1'
  },
  {
    addr: '0x737624dcd0d06a6123818ed9edf2b63f58e5413b',
    qyt: '2'
  },
  {
    addr: '0x7f4e21b39d6506e333b9b470b3fdedd4fcbbc6e8',
    qyt: '2'
  },
  {
    addr: '0xC67c56a8404229070e814C3B6e637558800D7Bb1',
    qyt: '5'
  },
  {
    addr: '0xf8F7Ae2618e3efe7dd1117f72Ef31b179a8eD338',
    qyt: '2'
  },
  {
    addr: '0x4c1f541039f50b67737BacECBf988E9692d3f64f',
    qyt: '1'
  },
  {
    addr: '0xf8cb43c5af2606d306e0215c2ebfd42d11337409',
    qyt: '2'
  },
  {
    addr: '0x98d6673b57fafa76b70b93ad8f2bca9f767576bf',
    qyt: '3'
  },
  {
    addr: '0x77c732924f88f8a2c71019251f87fe0cfa2c1810',
    qyt: '1'
  },
  {
    addr: '0xe74164e711534004b5f4bdc8dd37bbbb571c9ed0',
    qyt: '2'
  },
  {
    addr: '0x2760eae7339e550603d40b87324724c9e8a07df5',
    qyt: '3'
  },
  {
    addr: '0x02ec759b7ef77ea57f438a0b4da1ba19bbd63ebf',
    qyt: '2'
  },
  {
    addr: '0x292355818d27313b0cebf4ee3baf2c6bd222c1d3',
    qyt: '5'
  },
  {
    addr: '0x8060ac08f3daecd7c48c306d627c68523dcfa9b0',
    qyt: '2'
  },
  {
    addr: '0x41565e12eae80b5409f419172852325498481f94',
    qyt: '6'
  },
  {
    addr: '0xdfe18de47a30a1adf89c4c8434b37a809ca3a732',
    qyt: '1'
  },
  {
    addr: '0xe34f889b9adaf5e32fb6bfe8e4e1f96cb1e1cc8e',
    qyt: '2'
  },
  {
    addr: '0x6236080f80d086655583bd7d231c78b353070edc',
    qyt: '3'
  },
  {
    addr: '0x08df7e4671a7e3f33274d413805c712871cca103',
    qyt: '4'
  },
  {
    addr: '0x54b77fe2b807ddc4d4b11c3577dee9adf577dd62',
    qyt: '1'
  },
  {
    addr: '0x9ef8dedac6018f06016a247f26aaf97202a2184a',
    qyt: '2'
  },
  {
    addr: '0x475f675a6d651d1F3b22BedB79ae3C4B5FDbf385',
    qyt: '3'
  },
  {
    addr: '0x9670024f7a53868a7f06de00dd812ad56e322a1e',
    qyt: '2'
  },
  {
    addr: '0x26a1cd7eaa2eda64ccf3081f3bab18999f9d1eb3',
    qyt: '5'
  },
  {
    addr: '0x9902ffDc94Bfd6572A4D6d813e1Cc8f86f2965b5',
    qyt: '3'
  },
  {
    addr: '0x433179393017212f28b502a35be29ebbdbfccf35',
    qyt: '1'
  },
  {
    addr: '0x817a33e007afB85Ec23dA7dE231C1902CF4686C1',
    qyt: '5'
  },
  {
    addr: '0xde752f595297dee0155112232931661af03b6d19',
    qyt: '2'
  },
  {
    addr: '0x585e66488080c1048160bc081e8c6b954544315b',
    qyt: '3'
  },
  {
    addr: '0xeb086e989acebf9bc189f57a0d08485aa1b6440a',
    qyt: '6'
  },
  {
    addr: '0xc7014e77607681ceb42a09DA5Cce0f5DC8bE1323',
    qyt: '5'
  },
  {
    addr: '0xa573b157a904e7b1a705585ddc996a8ed77a047e',
    qyt: '5'
  },
  {
    addr: '0xb4b85112c5beaf4dee90916372c45e72548d2db2',
    qyt: '2'
  },
  {
    addr: '0x9ecfd654eb2896e19097aa79ea108d582417d8e7',
    qyt: '1'
  },
  {
    addr: '0x47d61d0e9d9b3ab2675b3689342281e613f7b5c3',
    qyt: '1'
  },
  {
    addr: '0x74951b3be4e0abef8d68fbddbcffc6fdf81705f8',
    qyt: '1'
  },
  {
    addr: '0x48348c803ac7e07347436755db05b2f3884ffbbc',
    qyt: '2'
  },
  {
    addr: '0x06395A9Ed4dF754d79b6a13823dEd996f5391f22',
    qyt: '1'
  },
  {
    addr: '0xcc3A4107A7f93F91d83deEB5F9D9a0E150222012',
    qyt: '2'
  },
  {
    addr: '0x51ef52da7106934cb07fd3f9a0acd82537dfa316',
    qyt: '2'
  },
  {
    addr: '0x9798ccff669096ca6ac83af5f63ffe46a6b6f69b',
    qyt: '3'
  },
  {
    addr: '0x93f913feb4352bc99b56e79f7921319f676ef806',
    qyt: '1'
  },
  {
    addr: '0x45f7acd9eeb8ab064120422718e0fa359f14b2ab',
    qyt: '3'
  },
  {
    addr: '0x539d853e273656511a89b6ae8fc5122fc71a029a',
    qyt: '2'
  },
  {
    addr: '0xb4B6fe345d48Ce592c1CA6944b0B78B58BEF25AD',
    qyt: '2'
  },
  {
    addr: '0xb643f6393171ccccbe72cce3cc7f867fe6fbf201',
    qyt: '1'
  },
  {
    addr: '0x18d78ad513454769cd8ee7bdaf0875499b3b6ddf',
    qyt: '1'
  },
  {
    addr: '0x9182869e647045d7405a8be2bbebd7a268e80f9e',
    qyt: '3'
  },
  {
    addr: '0x10a5ef1eb4000d1da89ce5e64444e6aa0a6caa7e',
    qyt: '2'
  },
  {
    addr: '0x01e03cd23c7727d995f6ec716c097266a4e68b29',
    qyt: '1'
  },
  {
    addr: '0x3d181847c3036ca6d31f4b3bc75df9e691f5f112',
    qyt: '8'
  },
  {
    addr: '0x78167681b9b8E3A21b1782D24922d79c2dD63904',
    qyt: '2'
  },
  {
    addr: '0xD7893A05e7304C5f7C26E516456Eca2F6871E617',
    qyt: '1'
  },
  {
    addr: '0x93a43ff001307165376e055300be6574eaab7bee',
    qyt: '2'
  },
  {
    addr: '0xd3dc1a46ab23b54e34762634db8ec539deca11e0',
    qyt: '1'
  },
  {
    addr: '0x55ffe18c322dee56736e58ede2e35953e75498e1',
    qyt: '5'
  },
  {
    addr: '0x8c17601db63abbf1ce20b4bcfaf7522442757ed9',
    qyt: '2'
  },
  {
    addr: '0x99a5c2e375f6e75f9d6227967eed997ad178bcd8',
    qyt: '2'
  },
  {
    addr: '0xd3e670fd34beedddc8b61c891b8be0ade86b558a',
    qyt: '4'
  },
  {
    addr: '0x2ecAa2e10d4d042B550f67180B58C931ef778666',
    qyt: '4'
  },
  {
    addr: '0x557A90Fae11ba1F7C47885252ddC7625D1555ef8',
    qyt: '2'
  },
  {
    addr: '0x074a546b21396dd5cb5dee1be043036c6b06cd0f',
    qyt: '5'
  },
  {
    addr: '0xdcca3ca59e226ee9b1ffff783ca98b399686c2e9',
    qyt: '1'
  },
  {
    addr: '0xe69022eF5400de726c6Fc92E763Dd42786C52394',
    qyt: '2'
  },
  {
    addr: '0x01082334d6001d82afead71577e6bbe3e470dcb5',
    qyt: '5'
  },
  {
    addr: '0xceb84a8021d42a821481ccac0e745a62271c258b',
    qyt: '3'
  },
  {
    addr: '0xa54cc43062313afe7e813345f375d3235dbc4d33',
    qyt: '1'
  },
  {
    addr: '0x6fA23fD153eFba87500268feEaE02bbF90430703',
    qyt: '1'
  },
  {
    addr: '0x4c47ab777f1f64d1f3d6efbf1cc7ab5a5224af4a',
    qyt: '2'
  },
  {
    addr: '0xc5f1c489cfca582c0d47fa2c036f84833e8ab700',
    qyt: '1'
  },
  {
    addr: '0x75907425C3B82aa1426E6d3cE41aEf8CA2C2446e',
    qyt: '2'
  },
  {
    addr: '0xB7E8D4D4c557E42AF87155B18562027FC9ED1767',
    qyt: '5'
  },
  {
    addr: '0x8105874e17b33b7a4f5089980cd11d2debacc2ff',
    qyt: '3'
  },
  {
    addr: '0xf1dacac664249ea725767b3197d5362c900e2810',
    qyt: '2'
  },
  {
    addr: '0xda2f4944afdd6177455bD2A6371F21699619ca6a',
    qyt: '2'
  },
  {
    addr: '0xd79cd4e8d302fdfb19d743ab85eda69227799adc',
    qyt: '5'
  },
  {
    addr: '0x780f05c9bc5dead060d3c6a0b3295f1d318beff9',
    qyt: '3'
  },
  {
    addr: '0xede38e560bC6ab4136F7D4419eEb5Ce1B518e5B7',
    qyt: '1'
  },
  {
    addr: '0xa196268569240b34abb109978eedf8f770edf104',
    qyt: '1'
  },
  {
    addr: '0x59f19895178b69f88916031ba61cd9983ef00fad',
    qyt: '2'
  },
  {
    addr: '0x74259C8a097D812C950571e25B8A0716CF89D0F5',
    qyt: '3'
  },
  {
    addr: '0xf0f8d6d84c81af3045a5864239e27a38641c2572',
    qyt: '3'
  },
  {
    addr: '0x2a699442f5c29587664a6c2551ad9b6e39d7d1b7',
    qyt: '2'
  },
  {
    addr: '0x84659efe03eefd291010542d0df053d5da1782ca',
    qyt: '2'
  },
  {
    addr: '0x187b3a9e27b41b3927dcb95b747532b3dc4fb93f',
    qyt: '4'
  },
  {
    addr: '0xf5efc0e52272e5299f9d23e314ce4f79e9e6af83',
    qyt: '2'
  },
  {
    addr: '0x25b326b290e21ae7c960ca16aaa8c059ab8f68b2',
    qyt: '2'
  },
  {
    addr: '0xf4c1d6be2d9e2550839892b4ab9e0a8027a00e45',
    qyt: '2'
  },
  {
    addr: '0xabad67aa0fdca951717b513cd38ba12c93471a17',
    qyt: '1'
  },
  {
    addr: '0x2b95b971ad4667ef97c6da75538fe5a74b42a595',
    qyt: '1'
  },
  {
    addr: '0xdA95535664e8DC760bdF88d525f8557C8fC92236',
    qyt: '1'
  },
  {
    addr: '0xb71ccefc7704dcfda5067a722e938a8e4768b0bd',
    qyt: '5'
  },
  {
    addr: '0xcb672d34339f91c1a9719fc25a12f1d1f7ecb0c7',
    qyt: '2'
  },
  {
    addr: '0x4c5d12da75a3a10ff1ede46001207aaee4ff74b1',
    qyt: '2'
  },
  {
    addr: '0xc057B385bDFf7FB8bBFcD1c6Dc1f87CE4D2399b2',
    qyt: '2'
  },
  {
    addr: '0x79e7685e7800fe5fd876b7f362943a9c16e51457',
    qyt: '2'
  },
  {
    addr: '0x198964A89698152196D75EB2dcd3cBf618d3ef35',
    qyt: '3'
  },
  {
    addr: '0xa0e167a7e2536882ed1c713790bc005e934ac12b',
    qyt: '2'
  },
  {
    addr: '0xa8f120918efa9edafd485f6d39f66a225371c60f',
    qyt: '2'
  },
  {
    addr: '0x15f4811073a07f75f731fac339ba05edda54e4f5',
    qyt: '3'
  },
  {
    addr: '0x62a84fc1ee568794d06ac26e0e74d6dc1ffa2f47',
    qyt: '3'
  },
  {
    addr: '0x2e333dAB03ea1387B697e54fe08694492ae899Be',
    qyt: '1'
  },
  {
    addr: '0x1359925d040317cfe9f278800d76f5f2bdf9df9c',
    qyt: '1'
  },
  {
    addr: '0x7320193ab4d78a6d0a92099ada20e7c99f024b63',
    qyt: '1'
  },
  {
    addr: '0x08947cc765e13054c3340ffbbf30b543760952ed',
    qyt: '1'
  },
  {
    addr: '0x2b95890732184b1017cf61cd92b0e42cb8b3ca17',
    qyt: '3'
  },
  {
    addr: '0x28317361cc6ea7358d01f6922f812c49a09d3435',
    qyt: '1'
  },
  {
    addr: '0x8b75eea32883C297aA89B935Fe7E858AC08AE431',
    qyt: '2'
  },
  {
    addr: '0x7134642483830705e78f47b2749af8fe1dd61d0a',
    qyt: '2'
  },
  {
    addr: '0xd8d332cd9effc9c4e30e53c4b6d2e22a9fff9117',
    qyt: '2'
  },
  {
    addr: '0x33306753D1Acf3a041c2BcF9fF3ca9055705bEE3',
    qyt: '1'
  },
  {
    addr: '0x2afdf2c6a2fefed4c86b785b1a7e1c1f927182e6',
    qyt: '1'
  },
  {
    addr: '0x6515c38a2d123ee22fc8fcf4db4f4bc470b70ead',
    qyt: '1'
  },
  {
    addr: '0xbb2c75af2ecf69984be9fe0146bf3b086bdfd82c',
    qyt: '1'
  },
  {
    addr: '0x8d30aab231bc1a14715adc75296ebf7a8fc81713',
    qyt: '1'
  },
  {
    addr: '0x6b825a2f4027893c8a0542d5233485a36feb4a0d',
    qyt: '2'
  },
  {
    addr: '0x7375a356F5948657Bf1DbF412b6B0202d822FF0c',
    qyt: '6'
  },
  {
    addr: '0x89a9F6C12255D0FA0aC36B77364DA210c3Ed7FeB',
    qyt: '2'
  },
  {
    addr: '0x6900e6577691cbde5463e9b04477223693258266',
    qyt: '2'
  },
  {
    addr: '0x664781E780dCc677d6e8E2FA26f3bA42A84e3339',
    qyt: '1'
  },
  {
    addr: '0xca36e3837f915d0308e89a584c70038666bea6a1',
    qyt: '1'
  },
  {
    addr: '0x135e0109e96cb885db973516ce37554d5764cab9',
    qyt: '3'
  },
  {
    addr: '0xC195AeD81e3DF50b361F465f3a6d757437e22F48',
    qyt: '1'
  },
  {
    addr: '0x8eb286c14d2bbb67cf847de0511df981c8cd6640',
    qyt: '2'
  },
  {
    addr: '0x11c3ad34f8e4ce9a15e3d715bb0a0cf03dd0a56e',
    qyt: '3'
  },
  {
    addr: '0x49a47992f661e38d01d323cd51ca473096ffac07',
    qyt: '1'
  },
  {
    addr: '0xbDF013B350CA02Aec8Cc70821099aC715756355b',
    qyt: '1'
  },
  {
    addr: '0xae8476a2fd2f71dbf9e321f0c0d0b6810c8eb548',
    qyt: '3'
  },
  {
    addr: '0xc7eF8e97AB9F8a3E61933B795D466f09eDd32108',
    qyt: '1'
  },
  {
    addr: '0x4Bf6C2894358F9ab9D5824feA80e86DA9C872226',
    qyt: '2'
  },
  {
    addr: '0x9EDBDA67b9A81240D49627d307A4B016Be60A9d0',
    qyt: '6'
  },
  {
    addr: '0xc5158bb46d48924ed0c87bef75f6f04dc61373ca',
    qyt: '2'
  },
  {
    addr: '0xd96561c8235690aec146ab207f9ce491ce939ad6',
    qyt: '3'
  },
  {
    addr: '0xd7a2527b400097Fe551421C432104edD1a753D97',
    qyt: '1'
  },
  {
    addr: '0xc0cf62dD9Eba8d19bFaED0595E07916e104B89e3',
    qyt: '1'
  },
  {
    addr: '0x8953abeec8c8b22d4524e6742f6b3cb0bfbcbc88',
    qyt: '2'
  },
  {
    addr: '0xb5258a5DeFa726732c5af1889b8abaEcE3D88b9d',
    qyt: '3'
  },
  {
    addr: '0xabcceb4e4fca44cd0cb79c398d9f1daed746e239',
    qyt: '5'
  },
  {
    addr: '0x844e1d3ba44fe0739bed9b01b7b6c3579e72350f',
    qyt: '1'
  },
  {
    addr: '0x0deee1a4aad3a79a3cf9dd5bc04f844e29dc52b7',
    qyt: '1'
  },
  {
    addr: '0x3E3d11fA568DE85aCc020cEA0fef8fb765283EBe',
    qyt: '2'
  },
  {
    addr: '0xa95e6b82c64be5607f71fbfd71596e9581377ecf',
    qyt: '5'
  },
  {
    addr: '0xC245554f143D36C01387A1c7B99BF5CBe84f6958',
    qyt: '2'
  },
  {
    addr: '0xd41596d62d2cCF43176a2797825a7711812258b7',
    qyt: '3'
  },
  {
    addr: '0x8f05969c739df5e406525a627e5dafe23f273fd3',
    qyt: '2'
  },
  {
    addr: '0xe29ee8d4d8f374e77ebcccef1bea228dc8a2d218',
    qyt: '1'
  },
  {
    addr: '0xBC0009dE09b40F50E7dC0Abe663AD04D52619e12',
    qyt: '3'
  },
  {
    addr: '0xc99333906D6D3A854f819617f6eF48870ae78435',
    qyt: '2'
  },
  {
    addr: '0x65225B36A039Cff6B32f2B16ba0b0d82F2DB7354',
    qyt: '2'
  },
  {
    addr: '0x6d7c4dc0bcfbf685b3383a3fc2cba4bc9394513d',
    qyt: '2'
  },
  {
    addr: '0xB08A1232b3C978293F797F4e8fa2E76B1ECCD086',
    qyt: '1'
  },
  {
    addr: '0xB0Ed7042DC76426C9E6fE741870d274733e34042',
    qyt: '2'
  },
  {
    addr: '0x472b6aca55a84727e6d658581ac1a51da97278d5',
    qyt: '1'
  },
  {
    addr: '0x20eafccc1b12db8493b62fc4faabc8501695eebb',
    qyt: '4'
  },
  {
    addr: '0xebf8da1268d9510c01e9b3ea362771625863aa41',
    qyt: '2'
  },
  {
    addr: '0xC43c932Afd7046d2b608405B6edc0F4E03F990Ba',
    qyt: '2'
  },
  {
    addr: '0xf656d06831b98adb82f78aa7ee06afae826e4644',
    qyt: '1'
  },
  {
    addr: '0x88a9504Fb3Bda5FCaeA5bcF300E1Cf793b831340',
    qyt: '1'
  },
  {
    addr: '0x6bd61958e3a5006ecd1cf4dfaa0a304fa5bb3693',
    qyt: '2'
  },
  {
    addr: '0x65345f749c70e64f86702d1e19f91a43462e14dd',
    qyt: '1'
  },
  {
    addr: '0xbe6f3ddb81c5a323a9719595621f2e6ab5a7cd88',
    qyt: '2'
  },
  {
    addr: '0x80dd5a127f375ed33d095a876bddba03bf92dcd4',
    qyt: '2'
  },
  {
    addr: '0x612696c24614bed7a4e87b5959b5e5fcdfe8023a',
    qyt: '2'
  },
  {
    addr: '0xeaa2f0a0728d31f449e13e825c7f074bf7c2e375',
    qyt: '1'
  },
  {
    addr: '0xea19b110974706bf82865b6856abb7f03d0ed258',
    qyt: '1'
  },
  {
    addr: '0x0e1e238a1d861856eb91dcb7985bd003a0d2d6f8',
    qyt: '3'
  },
  {
    addr: '0x8ce1786BF29B814b5c3Ebe30B486D7eE6eFace8e',
    qyt: '1'
  },
  {
    addr: '0x1a715366cd3a1cc66b32eab96eb03c24b14a4e56',
    qyt: '1'
  },
  {
    addr: '0x28b9667e89e8340b8cfacb35f85d18e2c81006d7',
    qyt: '6'
  },
  {
    addr: '0x4d6845f60967c8e8d13d46c5c1324b089278c8e5',
    qyt: '2'
  },
  {
    addr: '0x600741cbff4b65282663b1a85a4d9df6f6039b1d',
    qyt: '5'
  },
  {
    addr: '0x06dcbe14ba1ac1d4f910b4a325bae06a4ffb4b92',
    qyt: '1'
  },
  {
    addr: '0x6e2066972ec88c6abc1aae3b4456e5d68e84eca5',
    qyt: '1'
  },
  {
    addr: '0x9792f302597f0f229d71cc7d81324d8ad244d3d9',
    qyt: '3'
  },
  {
    addr: '0xCADF00027B1F630A6375AA3B93555Cb6fC352FA9',
    qyt: '2'
  },
  {
    addr: '0xb418db0aa7d6178c8d697bcd1d2b7e64d847aea0',
    qyt: '5'
  },
  {
    addr: '0xca12e2f3e6cc717bec5b7d6137aeff08fdb0e2ec',
    qyt: '1'
  },
  {
    addr: '0xe455c8a2b14c630cd6edc77bd269c9fc45ec180b',
    qyt: '1'
  },
  {
    addr: '0xf0c00026d40df1e2d366a113c9f463eb53475536',
    qyt: '5'
  },
  {
    addr: '0x1c7f35aa7f6d466624ee01c401e250470914649a',
    qyt: '1'
  },
  {
    addr: '0x3a4a6881efebfd8152ff4d1a3427830eaa4f50be',
    qyt: '6'
  },
  {
    addr: '0x866a986E4269221EAD6eEFfbAB6B190Ff2229e2D',
    qyt: '1'
  },
  {
    addr: '0x2640b469b5467a98c4fee08e3e37871e2ab1bdd2',
    qyt: '3'
  },
  {
    addr: '0x40a86bf7f56dc5a97857f87412d090340a533172',
    qyt: '2'
  },
  {
    addr: '0x443e1ee8022049a99c58e9916e8b351dba8b782b',
    qyt: '2'
  },
  {
    addr: '0xeee4f7a4cf919c26ad3ff9e259f9a69dcfe9c1c4',
    qyt: '4'
  },
  {
    addr: '0xe2651d78fc4ae0e648891f58426a4ba95a4b8351',
    qyt: '5'
  },
  {
    addr: '0x1e5a0f4cbd2b067f8b24ccc5d3b26e9ee9b61cbc',
    qyt: '6'
  },
  {
    addr: '0x7Da31A971EF01679208DB84C48458984881d8d46',
    qyt: '2'
  },
  {
    addr: '0x08fc900acc2739c12d9acb6cb4cbc7d2f89381b6',
    qyt: '5'
  },
  {
    addr: '0x691fd55f53acec43e65de10e5c2519d59232085e',
    qyt: '3'
  },
  {
    addr: '0x80dd3027fcb8090c8b436efa62f5520e798f866e',
    qyt: '2'
  },
  {
    addr: '0x312220284b0c9d157beea50b4df85ef62ba8ed59',
    qyt: '5'
  },
  {
    addr: '0xe3aec7727bfa9a57839a094d5da3562852ddcfad',
    qyt: '2'
  },
  {
    addr: '0xe57d59a08780498c4b77c6aa073c04ba29bb41a0',
    qyt: '1'
  },
  {
    addr: '0x6278d2d5a07713e516f08be9dff524bcc1d7dab5',
    qyt: '6'
  },
  {
    addr: '0x975283398b3d241b33433E21aB41ba54c6B6D3Eb',
    qyt: '1'
  },
  {
    addr: '0x0EAD50A34DA340aaF8A449205De17ED1fC1d930f',
    qyt: '1'
  },
  {
    addr: '0x27326Cc770acA8823c4e785E414Ffc9927FabfC1',
    qyt: '1'
  },
  {
    addr: '0x1c494f1919c1512ebe74a5dcc17dac9a64069023',
    qyt: '1'
  },
  {
    addr: '0x5d1f6Ab03c9d7a4EC9409FFd6C159F3e3Ed149a3',
    qyt: '1'
  },
  {
    addr: '0x123Ec5E8cee5a11d98D287b4EcB3ae9264f49Da3',
    qyt: '2'
  },
  {
    addr: '0xaC2CB7dFB64881F96ee35B57e2F28748e07C4eeC',
    qyt: '1'
  },
  {
    addr: '0x41AbFf920EE3f34E756E39A8654E7f3aaD9d40Dd',
    qyt: '1'
  },
  {
    addr: '0x8e09C20f960a49141518B3c60f0141356DF697fF',
    qyt: '1'
  },
  {
    addr: '0xC3298C6341f82468309302611e24D3003Bc79B46',
    qyt: '1'
  },
  {
    addr: '0xbaE8b160B69f9C47e5864439ecAa2e41B2A59bFd',
    qyt: '1'
  },
  {
    addr: '0x06834368BFb121cBCFB82922D4661b1d2e4d89e7',
    qyt: '3'
  },
  {
    addr: '0x8b19E55D056D3186A623a92022637E2f73935F60',
    qyt: '1'
  },
  {
    addr: '0x6f5e51Dd0F3fc82ec87cd2624bfce1B78cAe2F1b',
    qyt: '1'
  },
  {
    addr: '0x44e8ca73c1ea1BB629d0157F3a4fEBB08981C166',
    qyt: '1'
  },
  {
    addr: '0x3c853E215F905d1313F8F17Ee852Be157f75fFB9',
    qyt: '1'
  },
  {
    addr: '0x9a2349058EB845BA70B6b7C1d18D55D36569548D',
    qyt: '2'
  },
  {
    addr: '0x25C1eB863aA5B39DeE644096254b3a07f0AB9681',
    qyt: '1'
  },
  {
    addr: '0x03c1321313Aa0E5A13226Ec5246bC3324bC7d5d5',
    qyt: '1'
  },
  {
    addr: '0x349648f1885B45f62b03dde0809FDA16a589eC6E',
    qyt: '1'
  },
  {
    addr: '0x98C0626bCc848444379eDf8FdBc9548fd8412b12',
    qyt: '1'
  },
  {
    addr: '0x997472D6fed4C7f303678426dA187CBB812d2154',
    qyt: '1'
  },
  {
    addr: '0xf9e7d99c4fc9999e9c701cfd5f466c28b107241f',
    qyt: '1'
  },
  {
    addr: '0x067b57Be6Be163441B2c99A705CdEf7FC2494926',
    qyt: '1'
  },
  {
    addr: '0x1227fC7FC9FCdE4765d64078630a8093f545B8c2',
    qyt: '2'
  },
  {
    addr: '0x2f31e293326CAA45702a77dC496c13de5A484a06',
    qyt: '2'
  },
  {
    addr: '0x784Eb46d39bc864fCBfC64752b0CeE49A2E58f3A',
    qyt: '1'
  },
  {
    addr: '0xCA58F604f2181C77bD24c050be959aC09345DDbF',
    qyt: '1'
  },
  {
    addr: '0x8242F428C3053f3Aedef925A6497b4f3D9116E28',
    qyt: '1'
  },
  {
    addr: '0x19aB3d0eacdA6740698303b6C7aB77e0010b5dE3',
    qyt: '1'
  },
  {
    addr: '0x2c0d9f95dCa02cd55Fce0Ad039F77dF127FBB89F',
    qyt: '1'
  },
  {
    addr: '0xf7CE405886c1A0e6F9bAA2Fe50515957b418B39A',
    qyt: '5'
  },
  {
    addr: '0x57E9B23D270f4bA251706cEeDbbA7Ccf3EcbE6f0',
    qyt: '1'
  },
  {
    addr: '0x164E1F9c0fee428347039123C7690D2E87d38c73',
    qyt: '1'
  },
  {
    addr: '0x62CC1ba642Da43AC19A5E6777048107Fd1Df7939',
    qyt: '1'
  },
  {
    addr: '0x0B354ce20a7BE85AE7A08F23A5FF89383aa9342A',
    qyt: '3'
  },
  {
    addr: '0x55B332F2b2A92584B772640662EF96B75507f970',
    qyt: '1'
  },
  {
    addr: '0x1C1C32613446BFBAC14d54C475afC518DeB60399',
    qyt: '6'
  },
  {
    addr: '0x22DB5AbE18cd506B60F23012fD2299E1DbaEdCC5',
    qyt: '1'
  },
  {
    addr: '0xeD8d9b66AF9e1B139D71AA84E71E5636AB682eB3',
    qyt: '1'
  },
  {
    addr: '0x0ac1539906BAd2dEfEFb2727ECCd95d17534D770',
    qyt: '2'
  },
  {
    addr: '0x91E7d13Ec2d9f2daed63fD368607a997930c4EE4',
    qyt: '1'
  },
  {
    addr: '0x36BE89Bdf1f75D748909822De1F5aEF4d2417Ed9',
    qyt: '2'
  },
  {
    addr: '0x17a6b7FC4d460a9533B38D4e91955702e6323998',
    qyt: '1'
  },
  {
    addr: '0x3DaFD8871493ecA884DC01c47600efB495aF2885',
    qyt: '1'
  },
  {
    addr: '0x20185077498441D1C0F1E87914B7fBC6bB2eF0Af',
    qyt: '5'
  },
  {
    addr: '0x9fCa2245929fa7514eff6C9754384C5Dc7Ce1abE',
    qyt: '1'
  },
  {
    addr: '0x937d9ec2fcff4bebbdb4b940d1c83c6928b8bd4a',
    qyt: '1'
  },
  {
    addr: '0x8a76f2a8A8eF6f4359da6877874022A7531C4490',
    qyt: '3'
  },
  {
    addr: '0xd1c3DA264B8c8D599A65fb67351367e71267E346',
    qyt: '1'
  },
  {
    addr: '0xa2acdd1fff0822f36907f6f922853e1934fd78ee',
    qyt: '1'
  },
  {
    addr: '0x2f552Ed94b4A00c6bf431d7f4fA0bB88D66Cad8a',
    qyt: '1'
  },
  {
    addr: '0x39cD90C1a956d0eB30eb50071A28558E14d18299',
    qyt: '1'
  },
  {
    addr: '0xe7Dd49C7518c9DE2674faec43392a38868892374',
    qyt: '1'
  },
  {
    addr: '0x562254d106b146895a044A61E6C3A30D0BCb7931',
    qyt: '1'
  },
  {
    addr: '0x185b3B75a18A47C311041dE606513A8FFEa62035',
    qyt: '1'
  },
  {
    addr: '0x25D5817B8b3067f0b451C19401D89B56d17Fd32C',
    qyt: '1'
  },
  {
    addr: '0x8fEcCbD552C0e88E0a945327740FB628eF7e8915',
    qyt: '1'
  },
  {
    addr: '0xA00F00dd6F0697be5a4CE28d4D70f5242c776986',
    qyt: '1'
  },
  {
    addr: '0x8a7dfCf88b89979Ac39E72d09929df1428fae7DC',
    qyt: '1'
  },
  {
    addr: '0x8957abf2C92b4E62626A97581b6251c2c1Ce97d8',
    qyt: '1'
  },
  {
    addr: '0xd37d9Ab3B8776CA46ADF8A673Af3511a43138D13',
    qyt: '1'
  },
  {
    addr: '0x782f9d136ee4f89bD39F65336b9Cc69fA9A4fd34',
    qyt: '1'
  },
  {
    addr: '0x855db52e096DD4549FC0E3aE6167D17649Ea3000',
    qyt: '1'
  },
  {
    addr: '0x269fe7F38E1349Ac653b77c7685CcE80940AD2fC',
    qyt: '2'
  },
  {
    addr: '0xe74Dd83248fa5aC703A3AEe4Bb3C339cC7D31627',
    qyt: '1'
  },
  {
    addr: '0xF906B1c1CD6AF6245F1c5490Ae0d65ae02Ee3c94',
    qyt: '1'
  },
  {
    addr: '0xb16470013879f36d5341655035a4d8309dc002ec',
    qyt: '1'
  },
  {
    addr: '0x1Bbfa6fFCA88ce3DF6aAD1bb1A5bA82c3aa8341e',
    qyt: '1'
  },
  {
    addr: '0x1758aC3FaB3C0Eaba6314e098e23bCcF456d33A2',
    qyt: '1'
  },
  {
    addr: '0x3a713781E9870a3F9B99c79A49521f4EE173F8A0',
    qyt: '1'
  },
  {
    addr: '0x3aB7535b271d43D0713aF3C17876674DC3E95F64',
    qyt: '2'
  },
  {
    addr: '0xa23B9DE73f52F7444f41C4FdC15faf2Ed2a98Fdc',
    qyt: '1'
  },
  {
    addr: '0x1Dd1bD661da449912157eB9655111C7D6764af74',
    qyt: '1'
  },
  {
    addr: '0x56dCb709698dA06F86A48692FEA0FbED8e587219',
    qyt: '5'
  },
  {
    addr: '0x8f19465e81BBa3c2318E223773c7267aF9ec3Daf',
    qyt: '1'
  },
  {
    addr: '0xd5B11C6D8F3dc2a92AF14E87bC4FdAAe35C81F39',
    qyt: '1'
  },
  {
    addr: '0x51b6848aAcC703E189a55133a94Bebad3813390D',
    qyt: '3'
  },
  {
    addr: '0x7DBeB0dE51f062D369DE3aa1546673eE6057e17e',
    qyt: '1'
  },
  {
    addr: '0xB955DB051Bf09a645c26730752E28F5E3776088F',
    qyt: '1'
  },
  {
    addr: '0xa8B3992c4797d27f11d451487747D53DD397A0B3',
    qyt: '2'
  },
  {
    addr: '0xDf29Ee8F6D1b407808Eb0270f5b128DC28303684',
    qyt: '1'
  },
  {
    addr: '0x51b6f6d9d45edbedb33156CE29fDE0ffd35af1C7',
    qyt: '3'
  },
  {
    addr: '0x8dA8A8a209bA151e2907549B6AA642cBB443Ec70',
    qyt: '1'
  },
  {
    addr: '0x7b6c85688750BdCB8494359303021c8769aA7CBD',
    qyt: '1'
  },
  {
    addr: '0xACa108966673912B2B06267599498144a5b88f2A',
    qyt: '1'
  },
  {
    addr: '0x16cC9B526d16C87BaDe2178ad6fF11CC79E28c02',
    qyt: '1'
  },
  {
    addr: '0x87d2D2711317b70217B61eB3a221Ec042756A8F7',
    qyt: '1'
  },
  {
    addr: '0xB05Cf9fb4C85F4173FD54F0dE6fa73f9CFa08fBF',
    qyt: '1'
  },
  {
    addr: '0x95714e23c731Ef75c2e20F7B7cE18455AE4a9921',
    qyt: '1'
  },
  {
    addr: '0xc8d09b0da08A3B4736B7de744B86e533a525D4a7',
    qyt: '1'
  },
  {
    addr: '0xcfC5138eE31dFBD882cF9373a14612B3bb363b1A',
    qyt: '2'
  },
  {
    addr: '0xB7b1355018bD49226bB45B05D5FA6e86d3358BB6',
    qyt: '1'
  },
  {
    addr: '0x2B8a5E95D1c2F19eb1b49D8Dbfa7B26C7204561a',
    qyt: '1'
  },
  {
    addr: '0x35A47387Ef3f6e7803F43f90FdF8bd19489F8743',
    qyt: '1'
  },
  {
    addr: '0x20AF997d619d24915d24B624Dab26d64273936C5',
    qyt: '1'
  },
  {
    addr: '0x559F90276B1c9f2193891B23B3020E762EFBc8E6',
    qyt: '1'
  },
  {
    addr: '0xFF6c43A3Af4C107Bb5fb45bEC658bc3e919EdE24',
    qyt: '1'
  },
  {
    addr: '0xA466a025DBf9da8C499808e78C966D49cC56871f',
    qyt: '2'
  },
  {
    addr: '0x8Bf6ce2cEF310DDc3d7534D0523180AA5825AD33',
    qyt: '2'
  },
  {
    addr: '0x2d2Ae922c37aDa4Ab4f46747B253C3B9BfeF8CCC',
    qyt: '3'
  },
  {
    addr: '0xA205e4C29bfa385d032229489a83FEAB345134D4',
    qyt: '1'
  },
  {
    addr: '0x9fa84d3c5B4AE3b38bdee5e2161E87899557C770',
    qyt: '1'
  },
  {
    addr: '0x4beFe061913Ed8B0B14c6A0E5D0b5c63fFe67962',
    qyt: '1'
  },
  {
    addr: '0x7D9dF3714237F2665F74C633705Addb671f66dcC',
    qyt: '1'
  },
  {
    addr: '0x5e28d6D4246f596275C5D3D4a8e15973f66FAC97',
    qyt: '1'
  },
  {
    addr: '0x9a1Ebf88632A91CE106BdCcFcE37140B39c2d2b7',
    qyt: '1'
  },
  {
    addr: '0xD431F06941B374aEd9d8df9cf65487f465a31FDe',
    qyt: '1'
  },
  {
    addr: '0x51f6c99649c9bb66f9994BF645716e9394c99140',
    qyt: '7'
  },
  {
    addr: '0x9745132b8E85992B01020d719a9439667b5B2Deb',
    qyt: '1'
  },
  {
    addr: '0x69e2dF10Bc35935426b9eA7BdE3f33605fc76033',
    qyt: '5'
  },
  {
    addr: '0xdf8b436045b7223eb2c999Ec56B2557D358787d2',
    qyt: '1'
  },
  {
    addr: '0xe2d1b0F0D391D1B838067be2Da1Ca6AD39a61eEB',
    qyt: '3'
  },
  {
    addr: '0xD4A7101C9dc2626f19eCFDDc07961546506730e9',
    qyt: '1'
  },
  {
    addr: '0x5bbdB5290c9c738E1a12494f71836A8D62fa0730',
    qyt: '1'
  },
  {
    addr: '0xe18f77ffce35cf21b7248265ae30ff061ba2ee80',
    qyt: '2'
  },
  {
    addr: '0x59194704a5f260f7B8507572d2538856B64ab8b3',
    qyt: '2'
  },
  {
    addr: '0x267D86870c0e00d07B9f5763b391a4C437270e06',
    qyt: '1'
  },
  {
    addr: '0x17739F4Bd9384a736E33EF504F9BA91B1e99Ffa2',
    qyt: '1'
  },
  {
    addr: '0xA051957d09c393Bb93Aa13213284e42f5FDC8dDf',
    qyt: '1'
  },
  {
    addr: '0xa7B33CD26f27f1C6B709DB5Cae442e42387bA69A',
    qyt: '1'
  },
  {
    addr: '0x453fe7096ab46d0723af4a2e547eb52aefe4ce66',
    qyt: '1'
  },
  {
    addr: '0x06235055eCBaa6C0C579c242a286eC5BB65022f1',
    qyt: '1'
  },
  {
    addr: '0x8aA00e81647ea7dF878234F03396B3B19F5b80Ab',
    qyt: '2'
  },
  {
    addr: '0x48062C5Dd82CEDF473cA4a5D00A4cf4C33eB0200',
    qyt: '1'
  },
  {
    addr: '0xCe4C3d332c2d57e762cCBD6De9fEACcbf0cec572',
    qyt: '1'
  },
  {
    addr: '0x92F2d4310A0d86B13aC202DD0B1A793AcfF717dD',
    qyt: '1'
  },
  {
    addr: '0x53b8890698366d152d1665bc25f3cc1bd988a3ab',
    qyt: '1'
  },
  {
    addr: '0xB993C046248EC7e46Aa9a6B7f02A7019a93372B5',
    qyt: '1'
  },
  {
    addr: '0x5747EF44445A7b2191455A6cE6aD4D2D36e0b371',
    qyt: '1'
  },
  {
    addr: '0x4aCdBad9fDc9a854BA29f2090833779810a2b175',
    qyt: '3'
  },
  {
    addr: '0x2436cDeAFb6AF46893316383FAFCC61aC05f7c22',
    qyt: '1'
  },
  {
    addr: '0x9E152d2f9E2a362037c059BFF285F63b1b4dED61',
    qyt: '1'
  },
  {
    addr: '0xA5f92b3861BE83fb12572A64a120B9D6b890BC03',
    qyt: '1'
  },
  {
    addr: '0xdDB6d6Ff2026a2da47b57ee45Ab5DB6A0Fd6cE30',
    qyt: '1'
  },
  {
    addr: '0x55C11cD8c12d484458ddeAac37f772B62422Af6b',
    qyt: '1'
  },
  {
    addr: '0xFBC5215fE87A65656fc6bF6750445bBe35f618Ce',
    qyt: '2'
  },
  {
    addr: '0x47Fa0D3cd5E8a50A49E8BF27F64156176798f7ce',
    qyt: '1'
  },
  {
    addr: '0xc88c33c1b680fAc1ea9d05b77bF5Cd5C8Df35Ab3',
    qyt: '1'
  },
  {
    addr: '0x16d4dabb49e46586afb76d3667d9c5da015406a6',
    qyt: '1'
  },
  {
    addr: '0xb85a22da57fe445247e9347b10e3f3cf94f9877b',
    qyt: '1'
  },
  {
    addr: '0x1bc47d8C34139fC632F393d41D8141802b5Bd48D',
    qyt: '1'
  },
  {
    addr: '0xF61FFd3CcF75b4953B292aaCA7A45D626ACbEE20',
    qyt: '1'
  },
  {
    addr: '0x926185284eA43CF9fa9E30148f910f7a3F2333Ab',
    qyt: '1'
  },
  {
    addr: '0x3727BE45028418d93586a84c0bD85Ccbf9a3009C',
    qyt: '2'
  },
  {
    addr: '0xD2Ae4Bb40734d618012062379E2e1614C67F6F7B',
    qyt: '2'
  },
  {
    addr: '0xCa857Ea4EA054F193EBd383fBAFcf2B11A0B6d2A',
    qyt: '1'
  },
  {
    addr: '0xa254b20e9Ff813FD78B15436388eC680af788C04',
    qyt: '1'
  },
  {
    addr: '0xa7Fa13eCA3Fb891204CE8975f6a3843cad66883E',
    qyt: '1'
  },
  {
    addr: '0x89016541F0d8a9CEbD8E1CB159F653731095a3e7',
    qyt: '2'
  },
  {
    addr: '0x49136BCC759A0AA8A0Bc92Ee95ebc4BebB287D81',
    qyt: '2'
  },
  {
    addr: '0x7d191f3e69cded5505a3de1de18c3c52cadf39fd',
    qyt: '1'
  },
  {
    addr: '0x87cC9C48DD3c89D33E782e02797c2Ea65a357CA5',
    qyt: '6'
  },
  {
    addr: '0x7c754cf3df1f5146c92df105012d6b73cde8c991',
    qyt: '1'
  },
  {
    addr: '0x5A54A49A8e2B4a9BE5Af016CE3a48E10eba1E804',
    qyt: '1'
  },
  {
    addr: '0x4Ee5DCAEF82Cf491eBaf810318191e0AF961DD0D',
    qyt: '1'
  },
  {
    addr: '0x08fE2D1E7656c986CEf13e8Cba9290289050491f',
    qyt: '1'
  },
  {
    addr: '0xfEB270e9002Ee8c69b7FbB387ea8F10cf6842082',
    qyt: '1'
  },
  {
    addr: '0x2C870714D6d510513A25A2053F13e5aD22e31Dcd',
    qyt: '1'
  },
  {
    addr: '0xd573aDEF98a95cc5EB0DF4393Bd1F1198Acbde2B',
    qyt: '1'
  },
  {
    addr: '0xF8114F74107BAA1787b70176E4612E915d67c056',
    qyt: '1'
  },
  {
    addr: '0xBEE16672b84C45455586a97C08398A007bA0CA1D',
    qyt: '2'
  },
  {
    addr: '0x59614B5Cb4AC910fd7aFed0DB3726Becbd715937',
    qyt: '1'
  },
  {
    addr: '0xa8cff4e5ae68ecf6555dc0e4c442e9f56f3eb464',
    qyt: '1'
  },
  {
    addr: '0xe6b736a8AF28a623a165AA8A7C93B92461717017',
    qyt: '2'
  },
  {
    addr: '0x12292b3c51e6A9b64a03f92A5E4258e103961922',
    qyt: '1'
  },
  {
    addr: '0xDC1c5a3d2173D6bcC0d6a62b7e94c00f88DdBaE8',
    qyt: '1'
  },
  {
    addr: '0xf5fe45E1f40e7F9EAA94C559657035680bdBc23D',
    qyt: '2'
  },
  {
    addr: '0x81dCd4Bdde4B7d8f88A572FfFa1125B9b766d832',
    qyt: '1'
  },
  {
    addr: '0xc7fccd08bcab727e89f90faa9ea55d02b584d80c',
    qyt: '1'
  },
  {
    addr: '0x345164393349c549f1171e2649240de9bff7176e',
    qyt: '1'
  },
  {
    addr: '0x995d95c841846dF752dC5188e5Bc350bf42bC3B7',
    qyt: '2'
  },
  {
    addr: '0x0fDa9133f3e036630B6Aa602b354b584C4D41669',
    qyt: '1'
  },
  {
    addr: '0x3742f0fD8fcE40411c450E74D270D4D5Faaf92Fd',
    qyt: '1'
  },
  {
    addr: '0x17ae8AC01051AE0D2cEC707a006E161FF50a5e80',
    qyt: '1'
  },
  {
    addr: '0x68Fd1C89a6b5cA4f5C4b8c2338171585F77302fA',
    qyt: '5'
  },
  {
    addr: '0x7CE3639246B45b1bF5c676539883B3aB18A4555B',
    qyt: '2'
  },
  {
    addr: '0x8073b87e4910C7c84491b9b386d906C052912b32',
    qyt: '1'
  },
  {
    addr: '0x0e8E7E1Cb1F2e9027CBe9E3081Bf41c616701635',
    qyt: '1'
  },
  {
    addr: '0x06fc065a5d204459ca5a1a8863b0900fc5969018',
    qyt: '1'
  },
  {
    addr: '0x92547D8f4CE4e4B45a826b9caB533Dc6bc5C3199',
    qyt: '1'
  },
  {
    addr: '0x6c8E15EC8e35b6ffb246d60AbcA2Ba81d4e247F4',
    qyt: '7'
  },
  {
    addr: '0x100b7E251aAA3Dbe1C27240486593AA71bd0C226',
    qyt: '3'
  },
  {
    addr: '0x9A8A64D2E456EB9384656eBFC405A4fFb1b3BC79',
    qyt: '1'
  },
  {
    addr: '0xbc019195bf8c6104962c6246ea895ad7fa2fd0d9',
    qyt: '1'
  },
  {
    addr: '0x3A544Fbb2b7d324DbD1df54FF3Aa4fB0F612F755',
    qyt: '2'
  },
  {
    addr: '0xbce0dEfE314cFA9d9a6e6c461a5549Eb802B1b0a',
    qyt: '1'
  },
  {
    addr: '0x3591cAEb52a7EF39D726F9EE1FC67567C9ce3C6D',
    qyt: '1'
  },
  {
    addr: '0x7ab33fa396aE060E5035cd416e9700B1995FB8d5',
    qyt: '5'
  },
  {
    addr: '0x1caba71B9534De22f8B57e56782aaA5748bbd8D2',
    qyt: '1'
  },
  {
    addr: '0x966b8579BC1392569944d0726A2C4d0e8dbAD930',
    qyt: '2'
  },
  {
    addr: '0xA20B8793b7A02CfE91B545BCBEe9F704C37D43c8',
    qyt: '1'
  },
  {
    addr: '0x8ef04116462bf69d172558c52cb51d2e2ecdd10a',
    qyt: '1'
  },
  {
    addr: '0xa646EFB053E009B2f0FfAef2e7212DE66735DC25',
    qyt: '3'
  },
  {
    addr: '0x995C6b3510cD3Bf6B31D01ff27A33AB2dd15475b',
    qyt: '1'
  },
  {
    addr: '0xE6DadEA623Fc9b88B513DEFfdc4186633fC6c521',
    qyt: '1'
  },
  {
    addr: '0x24545e3785668c61ee2B31D3a13F1b69c5BC1863',
    qyt: '1'
  },
  {
    addr: '0xBa3b2D7853ff77e56D3375F2851B8dD390a5C0bb',
    qyt: '1'
  },
  {
    addr: '0x90ED7C12F1075315461a9022627aac43390e71D9',
    qyt: '2'
  },
  {
    addr: '0xf031447dBc5cEa55c6B35DA9604197B1f8f390C5',
    qyt: '1'
  },
  {
    addr: '0x431D97e6b860667a14142C40a81Fd450C6B99c6F',
    qyt: '1'
  },
  {
    addr: '0x0638cdDeFfcffC470F96F00FF742f92BEf7CC59a',
    qyt: '1'
  },
  {
    addr: '0x4450638F4b4A437fD3A4E189648bc711B1F474Fa',
    qyt: '2'
  },
  {
    addr: '0x4d73ECE1C974B42C918128B78184AD53cEE41B95',
    qyt: '1'
  },
  {
    addr: '0x5E926c008d84B15e6a86817E60f9F3De2A95CEF9',
    qyt: '1'
  },
  {
    addr: '0xa8AD77837bCB8fDdB778fC8924ce5b308917b728',
    qyt: '1'
  },
  {
    addr: '0x03830976F99cbabab4b5663Fe98950C040AeFfb9',
    qyt: '1'
  },
  {
    addr: '0xd7Cc57BfE31C7A9abC7edCE68F660329828694ec',
    qyt: '3'
  },
  {
    addr: '0x1Ca5D52d1FeB3af27AcC5295603F2932ae9892fE',
    qyt: '1'
  },
  {
    addr: '0x6b67aa9d2b4a3f0589da67f13e7abf7e938387b8',
    qyt: '1'
  },
  {
    addr: '0x11e106f81e87Ae98B5da83562926Ab913a135Ef4',
    qyt: '1'
  },
  {
    addr: '0x70cf816622Cecc06744F346aBdA47639431D3ae1',
    qyt: '1'
  },
  {
    addr: '0x64fc19EA889c4ff24A99444276F9FC429e952e72',
    qyt: '1'
  },
  {
    addr: '0xE07Ef334C602690C2b7730A5ea34e03B84b20Cb1',
    qyt: '1'
  },
  {
    addr: '0xFA799352d5472c67B99b2D140F5ab3e8E3e262E6',
    qyt: '1'
  },
  {
    addr: '0xe37fCB41fe81DD5C7a221257762E8f65FCD17B4D',
    qyt: '1'
  },
  {
    addr: '0xFC490927D7D522fCEf43Ef70930F9E73e29A4Ce1',
    qyt: '1'
  },
  {
    addr: '0xA7315821fA2EDdAeaFD7614c0305C85e0d3329fc',
    qyt: '2'
  },
  {
    addr: '0xe913347dA191b2aF30113EBE6dc0bb209e307a91',
    qyt: '3'
  },
  {
    addr: '0xB6C0857521cb48ca53455a7591cbE223f5F8066C',
    qyt: '1'
  },
  {
    addr: '0x8ED0aacf3F59b642e61C3901282a04F62BFBf9a4',
    qyt: '1'
  },
  {
    addr: '0xDa14fc3A7415d20c805e26dc2E52A26e62276DF5',
    qyt: '3'
  },
  {
    addr: '0x51bFe066e802FdFc66506C912a3153Dc8B1e157d',
    qyt: '1'
  },
  {
    addr: '0xE7F3FF6069aCE27350D8ace672c79d863c569264',
    qyt: '1'
  },
  {
    addr: '0x119a6a482637525Fd5C911993BA02C0Fde37DEB5',
    qyt: '1'
  },
  {
    addr: '0x294596D30aD4C1E99f826265d5bb086f14D2d206',
    qyt: '2'
  },
  {
    addr: '0x2FcD93979e8060e4b0E37aa7F5c7dBae18A749D1',
    qyt: '1'
  },
  {
    addr: '0x05D2B21733a55a7708a72A92cdB40f3e18985981',
    qyt: '1'
  },
  {
    addr: '0xbc300B5e15853C97Ab98b69DE39BabbD8371cc52',
    qyt: '1'
  },
  {
    addr: '0x04266c5f62e5d99139d4354f3928164af5A8e485',
    qyt: '1'
  },
  {
    addr: '0x0446e1809Df5239CAad0e23d2BD769d32599b8Ea',
    qyt: '5'
  },
  {
    addr: '0x224e6F3a3e044b58fC2Fd162BB9d548bfAbCC7fe',
    qyt: '1'
  },
  {
    addr: '0x6465eF66F5e4CD7410E6ae0660583De7b7DB8ca4',
    qyt: '1'
  },
  {
    addr: '0xa6802DFC9578493545F96b147a6265b5367880C7',
    qyt: '1'
  },
  {
    addr: '0x3a78b2ffbea2257a7ed9d34e5a223a4d7dbdb63e',
    qyt: '1'
  },
  {
    addr: '0x268a0d70C9f6A5605F9D66c093F5d6963BB23EF6',
    qyt: '1'
  },
  {
    addr: '0xa4703a4e8e6068D650B7473c6da35b9fd98A578E',
    qyt: '1'
  },
  {
    addr: '0x4899754eecda3c46f42a68fb816be138ff0a84c6',
    qyt: '1'
  },
  {
    addr: '0x99D0fF68C5cE29c22e3CeeFcA04C69Fe0AC11B38',
    qyt: '1'
  },
  {
    addr: '0xbf196988da5553d9c9a4a87a17fc229d8181d471',
    qyt: '1'
  },
  {
    addr: '0xc6112bbE713d8D2FBfC8Fa61cEAA5a7DEfe0612A',
    qyt: '1'
  },
  {
    addr: '0x10B3F6D8b5AfDABb1bCCf9EBE254eF299b7D91a9',
    qyt: '1'
  },
  {
    addr: '0x55D6c0ff7F8003a25e86e36BdA6aA310bC8D9247',
    qyt: '3'
  },
  {
    addr: '0x34c21C5CEA03542C87c880acf8d15496d8250794',
    qyt: '1'
  },
  {
    addr: '0xBFbb3E2ADc16777200dEB3b0Ff6FAcfC82E8B5B3',
    qyt: '1'
  },
  {
    addr: '0xB03554A4ebdFb0ED336946e2ABe0689b1da341e7',
    qyt: '1'
  },
  {
    addr: '0xFEc3AF6358f81F1201Fddfc2E554b8e2e8524b10',
    qyt: '1'
  },
  {
    addr: '0x7BC554DCDd90AD82f450A1C0238A122a039cF95d',
    qyt: '1'
  },
  {
    addr: '0x02E141B918bF0E49aA15c25E121D07694CBe8936',
    qyt: '2'
  },
  {
    addr: '0xe03c6F4D937F6ED3Ea782D115D597C829fDAc015',
    qyt: '1'
  },
  {
    addr: '0x19B251341e338968c77518dBbd7B1110eE72fCD7',
    qyt: '1'
  },
  {
    addr: '0x0Ed36Df8938F03659b1a57290f3a77915Baf7ba3',
    qyt: '2'
  },
  {
    addr: '0xd5eb602cb89cc95040831e66dc9b77054dc61093',
    qyt: '1'
  },
  {
    addr: '0xB521B7D236E64EC53DDa6E6702be90C2621e0726',
    qyt: '1'
  },
  {
    addr: '0xe93D6Fb62BB4be720CD6fEFa3d0d9dF7bF36a462',
    qyt: '1'
  },
  {
    addr: '0xc735c467f8faa60bfe0e9c08ea194e5dbdd01772',
    qyt: '1'
  },
  {
    addr: '0x4e92c44F58A8373F86c39c91E653677846710b95',
    qyt: '1'
  },
  {
    addr: '0x46A5B92a6cBCeb3FDED30A97091F73cF7a642BF4',
    qyt: '1'
  },
  {
    addr: '0xA3FE686041D09CaA01E0b4a217A8cF80B42029c1',
    qyt: '1'
  },
  {
    addr: '0xc0dF153dD7f4b7c12790c2Ac03072365BFdCaEd1',
    qyt: '1'
  },
  {
    addr: '0x171e6aE4560D8De8fD0d9b06511C0E728e916fb2',
    qyt: '1'
  },
  {
    addr: '0x1B8b0292C1668Cb195632cFD71969176A0A55953',
    qyt: '1'
  },
  {
    addr: '0x90074776538bb596264C55Aa5256d903DD96D9c4',
    qyt: '1'
  },
  {
    addr: '0x68582aAeCA29AFb6b1336476CAEF549A23D883a3',
    qyt: '1'
  },
  {
    addr: '0xdf9a6b175DC3fEE25C60bD0a6C32fC8dd5B8a49d',
    qyt: '3'
  },
  {
    addr: '0x64c26626CefC70017674f408876Ba0E3DA1f7646',
    qyt: '1'
  },
  {
    addr: '0xBD367Fe5Ce18AA17b6c6a3F35b16038f4C69Ce4b',
    qyt: '2'
  },
  {
    addr: '0xc2D11c3EcD1424EAefE16928a63fE7c66D3a3689',
    qyt: '3'
  },
  {
    addr: '0x3d38690f2CC47EA4591700646Bd300e00EE30307',
    qyt: '5'
  },
  {
    addr: '0x59006D2bb22b87dF897B61d5e13B96f7Dd0c982F',
    qyt: '1'
  },
  {
    addr: '0x21aa12B820Ce1F9D921BF2621166E1c578C08355',
    qyt: '1'
  },
  {
    addr: '0xb6BF4E7F9742D567D3FE9d1FDF123919973105fd',
    qyt: '1'
  },
  {
    addr: '0x19c362A1E58614a4668fF0c8D227e109361345C5',
    qyt: '1'
  },
  {
    addr: '0x2cb3dd35C90adAee3cc22695a7Cd9702870bbA32',
    qyt: '1'
  },
  {
    addr: '0x7adAa6050be55163B7DFF5E7Ad16b9f3B39eb255',
    qyt: '3'
  },
  {
    addr: '0xa7Ce9d56E5f6f15733Dc6dc53bB407F0B8B79967',
    qyt: '5'
  },
  {
    addr: '0x741EA9FfF67940E690849C186d90083Ae9300255',
    qyt: '1'
  },
  {
    addr: '0x6234305c6E1ecf48e727a253632DFA9C0D48d23e',
    qyt: '3'
  },
  {
    addr: '0x4520094aB1158b80595C70dF4d25065ab47eEF0d',
    qyt: '2'
  },
  {
    addr: '0x8716eCA6Ac4a59DaB9aBbD95a4a47cCA5334063a',
    qyt: '1'
  },
  {
    addr: '0x444d8eAc9682023D58a3859C24a0B1949A821431',
    qyt: '1'
  },
  {
    addr: '0x076317cd3787f41aA50258bc8BBDd2e3Bc22DD78',
    qyt: '1'
  },
  {
    addr: '0x429a5f4A6e320656c023A6ef4F181573d4415E8f',
    qyt: '2'
  },
  {
    addr: '0xbD5b2e3ED4f599AD54bb1600D85644F4eBF4B044',
    qyt: '1'
  },
  {
    addr: '0x1957233178F849c765F0FC0ec8Bab179561713B5',
    qyt: '1'
  },
  {
    addr: '0x8869B505D22C93b9cABa5DA5da2fb8a9F20Dc2E1',
    qyt: '5'
  },
  {
    addr: '0x421Ad8782E402583Def89B7e045b895005190693',
    qyt: '1'
  },
  {
    addr: '0x21c71F0A3d43f3D4CFFdBd87A7412ab866dD9ed0',
    qyt: '1'
  },
  {
    addr: '0x364e5923DFB6B33c7E452E655df38D0651463f0a',
    qyt: '2'
  },
  {
    addr: '0x14BA749932FC8Ed3366a00Ed914CD4171fFc519D',
    qyt: '2'
  },
  {
    addr: '0xEE626B5d72a48750929dA10b49f6B99Db77eB582',
    qyt: '1'
  },
  {
    addr: '0x83B5caC1012E3c538E9949Df8668928Ab04d7a36',
    qyt: '1'
  },
  {
    addr: '0xe3452b3ec0686Dc1cCC3F9635093dC8201321A79',
    qyt: '1'
  },
  {
    addr: '0x985798D0da8e4Ec71DB6362CE08982e13EA745A4',
    qyt: '1'
  },
  {
    addr: '0x102c5223E20Dc06bF4aF6024eb7659a39508bc04',
    qyt: '1'
  },
  {
    addr: '0xC301877d504f37451ec0aCee6C00dB5b53ACFa80',
    qyt: '1'
  },
  {
    addr: '0xE53434648bFb02E93971958CBe5c98aBE27E53C5',
    qyt: '3'
  },
  {
    addr: '0xff703A13D5d3365CFA8B7377C990cD9a73bC3580',
    qyt: '1'
  },
  {
    addr: '0xc4cd99abd5c65d54e134c2412fa3157b4336a85b',
    qyt: '1'
  },
  {
    addr: '0x76cc07437385885A5dCAaEFd3b94f8fb0Bc08930',
    qyt: '1'
  },
  {
    addr: '0x9618D1204f9f1e8Ea81bce268Ef953aA43c23b53',
    qyt: '2'
  },
  {
    addr: '0x3622f08e8aa6323cae19565419247992983da2dc',
    qyt: '1'
  },
  {
    addr: '0x95E399CA44847C0bCac7052137a5e2483535C7E8',
    qyt: '1'
  },
  {
    addr: '0x4b50408ced35a9099C3235420Bfca74180981F93',
    qyt: '1'
  },
  {
    addr: '0x622B637C37887a111E315325D13D94f1417650e9',
    qyt: '1'
  },
  {
    addr: '0x90da680F1A9530969a900D5C7F20355B422f0F21',
    qyt: '1'
  },
  {
    addr: '0xe2AD4f1dF2793fe8F8322589F90Caf78B13f7f87',
    qyt: '1'
  },
  {
    addr: '0x08750eb0FE6861Be277E69b5fbaE401B5f98BD40',
    qyt: '1'
  },
  {
    addr: '0x710DCc41f41b581E3d24Ea553cD3e401E30c9De6',
    qyt: '3'
  },
  {
    addr: '0x28313f20d4119C442b5A24f4A21e3462DeF552B2',
    qyt: '2'
  },
  {
    addr: '0x7D79d15D75aBAC041C90B001B25702fd4cBee92F',
    qyt: '1'
  },
  {
    addr: '0x9fB2a48BD3D6c625766cC97972fA2E047De69C34',
    qyt: '1'
  },
  {
    addr: '0xB922FBf60Dca7C2f7453c784861cFa4D5c75a987',
    qyt: '1'
  },
  {
    addr: '0x67017BEbE2A4B8645B28F83D5D472618b35a1230',
    qyt: '2'
  },
  {
    addr: '0x0e29f6dF1F612B2A5ECeEb58Ed0E41d8513Cb290',
    qyt: '1'
  },
  {
    addr: '0x6DAdd1eBa478E11ed25e149AB81142b295FD76D0',
    qyt: '1'
  },
  {
    addr: '0x3D88d010De964bA3b067d0888B89ae23C8652002',
    qyt: '2'
  },
  {
    addr: '0x53a1fF1D3a863bdf7E07f20C4ad21667865a59cD',
    qyt: '1'
  },
  {
    addr: '0xea9426C3E35758Ea49cf0fD21E596130c1240d28',
    qyt: '2'
  },
  {
    addr: '0x3d4E78B4f9ED5B9d7593AB87f1dd7031bE72BA23',
    qyt: '1'
  },
  {
    addr: '0x755c4a4083D9AbCb1f1821425c75e487a326Fa55',
    qyt: '1'
  },
  {
    addr: '0x08430906F19f4409E15Be59B3a4cF3bFfed8d609',
    qyt: '2'
  },
  {
    addr: '0x3436F358BCCBF47c2dDc0db467a3E9A5Db8fb667',
    qyt: '1'
  },
  {
    addr: '0x1ce9dc2cee46f15001aecd0aeb40b5ab6b8ebe6d',
    qyt: '1'
  },
  {
    addr: '0xc522A159391C4C57FF95d508852DF0b380a27f04',
    qyt: '1'
  },
  {
    addr: '0x2c4823c81d2ab17902a7345f155f7e907731c5b1',
    qyt: '1'
  },
  {
    addr: '0xcae38F7122465e97F33f2a4daaD7038Da8bcF491',
    qyt: '1'
  },
  {
    addr: '0x58DCfBb83FE189E5ab30307fD5fb9AaC516F7aa6',
    qyt: '3'
  },
  {
    addr: '0x6EDEbD27597dc41e331e4ddaB7a05f968557a420',
    qyt: '1'
  },
  {
    addr: '0x5d8A29C3E33024Ce7F1725c66278e5b563BF6c4D',
    qyt: '1'
  },
  {
    addr: '0x06ce7677d8e0148b902cd5f7b7e95215c90d7939',
    qyt: '3'
  },
  {
    addr: '0x945de8a27E68D2041bE2832C79fe80F17E47981E',
    qyt: '1'
  },
  {
    addr: '0xe178d44Ec55FC3F27D399094d43b44cD65e4666B',
    qyt: '2'
  },
  {
    addr: '0x283ddB36BaaCD5FcDEfE7eeD1DE27816BC5A431E',
    qyt: '1'
  },
  {
    addr: '0x6C05aC23CDFd06388261E2A515355102727C6Db3',
    qyt: '2'
  },
  {
    addr: '0xEE5965Df7dC18d75c1453C4AF692AAa0B04F03DC',
    qyt: '1'
  },
  {
    addr: '0x929671c316347cbcad3249F59545158e26573Ea6',
    qyt: '2'
  },
  {
    addr: '0xf10cd2682610fb2d27FB25bE51787a4af1Fdd6b7',
    qyt: '1'
  },
  {
    addr: '0x431b173e00A8b85072C8Bb199EcB66cfB5C9Fd57',
    qyt: '6'
  },
  {
    addr: '0xE7B387967d1c8217a964Bcfe980C420DF2372cfb',
    qyt: '1'
  },
  {
    addr: '0xBf74269fbA40872F9beDFB2260e4f4F3AdFF88dB',
    qyt: '1'
  },
  {
    addr: '0x6754c115184aee01633f94e41b5ea1e223bd9922',
    qyt: '1'
  },
  {
    addr: '0x0da6e207344835e8e0eaa2b50b8ea64caef80c6d',
    qyt: '1'
  },
  {
    addr: '0xeCb345270C14273c7374b38677F2d018E4eE2175',
    qyt: '3'
  },
  {
    addr: '0xd3AaB03b244D60a8F445945C508e50A45f70705d',
    qyt: '5'
  },
  {
    addr: '0x5e259ac8b3371ce6545f0a2dbf3d1d87696d3869',
    qyt: '1'
  },
  {
    addr: '0x476d29E239Ac87B62b895A52Cd085ff9B8DD4c68',
    qyt: '2'
  },
  {
    addr: '0x92272428764257bE4E09355858691a7C751aF36a',
    qyt: '4'
  },
  {
    addr: '0x88A90F1aCd4FB7F5Bacb4876298b6512e04d8757',
    qyt: '1'
  },
  {
    addr: '0xBD5ba0F9dbfD872f009603A1B552257f59247DDf',
    qyt: '1'
  },
  {
    addr: '0x4899D66105Fd0F22A4a11dfF6BC855B1BABa3b99',
    qyt: '1'
  },
  {
    addr: '0x18AC29D852e32fE85A0C6986417Ab437043327f8',
    qyt: '4'
  },
  {
    addr: '0xd68ec933837299d490014BE54056a72CaDdb417B',
    qyt: '1'
  },
  {
    addr: '0xbeec1ea227051057520b96a9ff7bb873f4f1b267',
    qyt: '1'
  },
  {
    addr: '0x700392c3ace476b141acc2ff48468467874f0b82',
    qyt: '1'
  },
  {
    addr: '0x8ffb1513322e53c8cab52c91518e69b542d20a2c',
    qyt: '1'
  },
  {
    addr: '0x7edbd9f4982ba425f8c80a2529cefa7bf5edd6b3',
    qyt: '1'
  },
  {
    addr: '0x44c48b86698a75c64325f6973ef8d53619a0c872',
    qyt: '1'
  },
  {
    addr: '0x70ffff4a1ea4220ef579d82236eb4750c0f5a723',
    qyt: '1'
  },
  {
    addr: '0x36ced2cc2b4a86d15d9e25eb009f95fe187f6e47',
    qyt: '1'
  },
  {
    addr: '0xc3dea0d997f9b7b3ff78c974271e9d15b82439d6',
    qyt: '1'
  },
  {
    addr: '0xd93ca7bd968ff15a3d12c8943c0a7c61004ae076',
    qyt: '1'
  },
  {
    addr: '0x7a82683f3477d38ec20481ca5e506f930b64d26d',
    qyt: '1'
  },
  {
    addr: '0x4988542d1507a19beee8bf0305392666f36983d9',
    qyt: '1'
  },
  {
    addr: '0xaee8d581fb325829abc1bdb28537fa91ce68e617',
    qyt: '1'
  },
  {
    addr: '0xd282146939a2be908094b2c2dae042bb92fb13de',
    qyt: '1'
  },
  {
    addr: '0x52cbb02e04820236cc8e5fdf27b363e066ac796f',
    qyt: '1'
  },
  {
    addr: '0xc2c19F2Fd1E82f0938fFB7C8DBB37dD7367E8760',
    qyt: '1'
  },
  {
    addr: '0x1774444C580f66090Dd7bA59d85D072FE1214649',
    qyt: '1'
  },
  {
    addr: '0xEeBebd2dBa90239f5008418e3807Ada348d9dE05',
    qyt: '3'
  },
  {
    addr: '0xf73bA88E4113Ac2b9563f7728c548e9dE5EF9412',
    qyt: '3'
  },
  {
    addr: '0xb1780e0d48163d8f777d36af99f79507f4b84015',
    qyt: '1'
  },
  {
    addr: '0x42fCEE7Fb5D33626ebDF9465CD141726F99728a0',
    qyt: '1'
  },
  {
    addr: '0xF3F5F35796f6b805d412cf485E91Db1b75b2FcA3',
    qyt: '1'
  },
  {
    addr: '0x05610F81B1c013d93395a831a87126760650E817',
    qyt: '1'
  },
  {
    addr: '0x2CD4B492c489b54B6eEBffD918C527EB3Bd48D22',
    qyt: '1'
  },
  {
    addr: '0x58BF7bd2eba40278a8b3C449df04014110049Df8',
    qyt: '1'
  },
  {
    addr: '0xF1f931eFc84975cEd51A4c93CF73136D98c24792',
    qyt: '5'
  },
  {
    addr: '0x7DA64Ba376697a73380289F60dAb556E3cc1D632',
    qyt: '3'
  },
  {
    addr: '0x7Cc3a4E60B0f30A7005d57fF5dE798bEc133983a',
    qyt: '1'
  },
  {
    addr: '0xA1f0ED9546C53541AF6084226dDeA2eDF7346CFF',
    qyt: '1'
  },
  {
    addr: '0x6Bab6BCfB0C38ff8E781C3B3b188627Dbd7C8c2d',
    qyt: '1'
  },
  {
    addr: '0x1690ED2F81643dC5a0BC30ea3639de3C7c4bA63d',
    qyt: '2'
  },
  {
    addr: '0xA466C33339DF47f50aE6137640969C03fe8B0126',
    qyt: '1'
  },
  {
    addr: '0x15999840613d7a7f0cF707C5D15a23e7C32495aB',
    qyt: '1'
  },
  {
    addr: '0xa5A257D843369b7F360C76A83f718A18A0Df7078',
    qyt: '1'
  },
  {
    addr: '0xd5AE5746a1DB22894898E4c3f3DD344656974b18',
    qyt: '1'
  },
  {
    addr: '0x8E3876C7Af77dbA8C3191AC712aEBD227A945633',
    qyt: '2'
  },
  {
    addr: '0x12F755C5070CADCf1A040a69AcfD924A3F505c26',
    qyt: '2'
  },
  {
    addr: '0x8546C2a1515192d7b634Fd3596990977f3e3356E',
    qyt: '1'
  },
  {
    addr: '0xB33c7c95099DeDD2Ab38585eC8aE7467Cc15D452',
    qyt: '2'
  },
  {
    addr: '0x80cb30231F96189078FD0721C143a250D8752a3C',
    qyt: '2'
  },
  {
    addr: '0x49884648490fa5F1A2Ed58f4e9866cF141592895',
    qyt: '1'
  },
  {
    addr: '0xB6Bad7AC0aB4225D7213287e60372218cf04091d',
    qyt: '1'
  },
  {
    addr: '0x39d9234388478576Ea45453CD14f8bD6e6777ba5',
    qyt: '6'
  },
  {
    addr: '0x6480a28928b8ed9aa4660cb68ce430bf93227965',
    qyt: '1'
  },
  {
    addr: '0x3902254174fb65b5B04A06CD89a442886a92EaBD',
    qyt: '1'
  },
  {
    addr: '0x40C6da8afBDBd54a9AB1B2dAe61187B42337b520',
    qyt: '2'
  },
  {
    addr: '0x843e5eA091dEdB68135F4BE07Df4742dC6d4F36d',
    qyt: '1'
  },
  {
    addr: '0x42A615987A9A2458264E68767b4b3C9daFf6bA26',
    qyt: '5'
  },
  {
    addr: '0xC69918C752874CB0238E9e159157B34EbB94AF23',
    qyt: '2'
  },
  {
    addr: '0xD72dc706f09e908E954FdE14908A69184262E660',
    qyt: '1'
  },
  {
    addr: '0x37e40CB55acbd3fd431968851DE070EB33d9edA0',
    qyt: '1'
  },
  {
    addr: '0x8BEA0bB96e9075AD906947A4229eDbf7282Ee6Ae',
    qyt: '2'
  },
  {
    addr: '0xa1FAc195ee93df0A9cD36D1FBe050280de1978fF',
    qyt: '4'
  },
  {
    addr: '0xbd3eF1E79bebA13c4EAd7501cF7B302a4cA33298',
    qyt: '1'
  },
  {
    addr: '0xDA057a4149F5A03E7FDCfE92273A59Db22b147aA',
    qyt: '4'
  },
  {
    addr: '0x4888e18d035966AFAD2Af00560A7aD6f95DcbE3a',
    qyt: '2'
  },
  {
    addr: '0xd160CF4c8a6b2BF8E65F961462147D261c44F47D',
    qyt: '1'
  },
  {
    addr: '0x40CdF80D5e1A7B703A470210BAD906a59088D2fA',
    qyt: '1'
  },
  {
    addr: '0xCf1fF6B976aA83947683836e6b40F6f933D8C185',
    qyt: '1'
  },
  {
    addr: '0x8bb550f7FA82EB04587b1Cdc04bf06B27C653114',
    qyt: '1'
  },
  {
    addr: '0xb278ada59e7Af95a0c3DE7699d8946b853f1e38a',
    qyt: '1'
  },
  {
    addr: '0x248517Db647d61A79B59cf1de98388Dc8c0aE7eB',
    qyt: '1'
  },
  {
    addr: '0x423331ab5A01311e4E65c50A448107d2d6c0Cf99',
    qyt: '1'
  },
  {
    addr: '0x03A089CCAAD6eCfecb789A234D152B7b58D0869A',
    qyt: '1'
  },
  {
    addr: '0x4Cb86d4dc2C5daB72924Ff271EE8DCeAC821e643',
    qyt: '1'
  },
  {
    addr: '0x63753c5aE31c6ED72EBD66977B33106cb05c7D27',
    qyt: '1'
  },
  {
    addr: '0xfFBAEcBD0482e3dF6D71a571C3e2A88944aed37A',
    qyt: '1'
  },
  {
    addr: '0x10Daa2d4C9E154b6731DbA3D38EA6b9B9fbbE63c',
    qyt: '1'
  },
  {
    addr: '0x2A4052ffCf084E609253944624cdF05A4220dB26',
    qyt: '5'
  },
  {
    addr: '0xc8f2bfa3ea582dd80fb0459a44188ff01457e876',
    qyt: '1'
  },
  {
    addr: '0xb96718A86A57766001DfF16B163a6e696A365f10',
    qyt: '5'
  },
  {
    addr: '0xC4D19011f060F8bEee2cfE2D97AFcAF5FD82776C',
    qyt: '1'
  },
  {
    addr: '0x6ED135bf2Ac16f53E4D901535E0654f04bA6CEeD',
    qyt: '2'
  },
  {
    addr: '0xCeC409c5a4Cd6FC95D4CA41311bCfab118c68D01',
    qyt: '1'
  },
  {
    addr: '0x0d1B9dB85bAcC970257A8a5Ae77cF89B8a0d33DC',
    qyt: '5'
  },
  {
    addr: '0xac69f022a62c7aBAe7E754cbCD243f6e0Fd78577',
    qyt: '1'
  },
  {
    addr: '0xD2A922091D7cb85473b2c2105Bd17db3181Dddfe',
    qyt: '1'
  },
  {
    addr: '0x6d95d0742414676943aBAb4818ec16bb111e57FD',
    qyt: '1'
  },
  {
    addr: '0x9B0cB2Db24E6eA19bA5a3090E039938801A8ca27',
    qyt: '1'
  },
  {
    addr: '0x277fEf2E7562CC7DeE9312eE40db4E09540f46De',
    qyt: '1'
  },
  {
    addr: '0x318AA87FeBc5d4EB1AFa25B57002BB62a5Cd1852',
    qyt: '1'
  },
  {
    addr: '0xb513371b251a6B545F79C544bda568724f90170B',
    qyt: '1'
  },
  {
    addr: '0x1A02846226305625cEb4544d0Fd4b5CCF4ba713f',
    qyt: '5'
  },
  {
    addr: '0x0B534E3ea11Cc1bd9D53683E26194Fd483bf84fD',
    qyt: '1'
  },
  {
    addr: '0x5A6fc6440eCdF592083c86C98b8198dC3fd77b8A',
    qyt: '1'
  },
  {
    addr: '0x1dd49d4f02e7c0e0ccb4717be111755130edac77',
    qyt: '1'
  },
  {
    addr: '0x061cDf5fC9710079d3bFf605A00fcaA6E635248F',
    qyt: '1'
  },
  {
    addr: '0xb07BFdC0f7Ac6F2c59eb0e78e04E0917b88c855E',
    qyt: '5'
  },
  {
    addr: '0xC09F45bE2e48B0f74bC99D6cB0aCBEf5408eF5C0',
    qyt: '3'
  },
  {
    addr: '0x251e08cD6DCE679a96Be081c68cac4dd04c8a3A3',
    qyt: '1'
  },
  {
    addr: '0xf4921cfb65326320F466E97ebDf188F82f47E9bb',
    qyt: '3'
  },
  {
    addr: '0x132c923f54901a90c3bd59f9e7ed9d2ff9753611',
    qyt: '1'
  },
  {
    addr: '0x0fa9c5c0c90c91efcc1ad9c59aff1705fb6ae6b5',
    qyt: '1'
  },
  {
    addr: '0x801DA82b1e24Ea2a22502E92eA14a69Ac573cb6a',
    qyt: '1'
  },
  {
    addr: '0x2e26a2A3a61C17f9cae8AF0e09CEa8D0b92eCEDF',
    qyt: '5'
  },
  {
    addr: '0xc6726720650Be890b474894Ef42097766A7689D6',
    qyt: '1'
  },
  {
    addr: '0x18b08DA774A58280D597f3EE28a7BBA0ea5D7004',
    qyt: '1'
  },
  {
    addr: '0xC43DF0a4464E27D9bAF822e4B3833A45cE861700',
    qyt: '1'
  },
  {
    addr: '0xb3D35Ae49a834437fdcD0ADE3e53c33708dc090C',
    qyt: '1'
  },
  {
    addr: '0xBc74dA5a258a3aABA1c75c68EFB08c9f37537be8',
    qyt: '1'
  },
  {
    addr: '0xAFAF4434759c170929c95419b6b3105051eabE06',
    qyt: '5'
  },
  {
    addr: '0x97091C8fB8C02bF2ba2b4F0008C7C451A692B9d2',
    qyt: '2'
  },
  {
    addr: '0x9749440756D88f503cD39c0D444D7AcCd9bb3dE2',
    qyt: '1'
  },
  {
    addr: '0x9cBed66F12DDeBd0103D3638E4B86c53742bCf4E',
    qyt: '1'
  },
  {
    addr: '0xa38C926fB2cF6ffA95e25fE97855A0598e3F0DF5',
    qyt: '1'
  },
  {
    addr: '0xA537923D7a211654EfD95cE2848a79894dBbe345',
    qyt: '1'
  },
  {
    addr: '0x983660cDE8d1d71354089DC73C05C529bBDadF59',
    qyt: '2'
  },
  {
    addr: '0xa2B937a831e6e549cBDB706E2C0761fBE5A2486d',
    qyt: '2'
  },
  {
    addr: '0xEE7b1407CE39B34E2B4Df1546Aa69d2027e1c366',
    qyt: '5'
  },
  {
    addr: '0xd13D0d1dC940f21CC481a5b230fb87ae7C6F5889',
    qyt: '1'
  },
  {
    addr: '0x2642D17bAF2582E7B2fD7EB20eA23f10DF4FF962',
    qyt: '5'
  },
  {
    addr: '0x0cdfeec93dba1c899b0f21822fe5f548d716fdba',
    qyt: '1'
  },
  {
    addr: '0x5f5f019bf352682E60656500edeBBD0D5Ae73996',
    qyt: '1'
  },
  {
    addr: '0x1fCF6FB025c1c01542Bc68a43dFed2da7C9b006d',
    qyt: '1'
  },
  {
    addr: '0xD4DDCfcD7Ce5c205b7eec032eBDcf3a153197153',
    qyt: '1'
  },
  {
    addr: '0x8478Ab8AdF9Cc5ad5079297E2ec11e9aA810058a',
    qyt: '1'
  },
  {
    addr: '0x2051d3d7162df9f473c98e021e6652f105b76a89',
    qyt: '1'
  },
  {
    addr: '0x801986A185E89559bAc215848C312309b9f8D6cA',
    qyt: '1'
  },
  {
    addr: '0xeF84E55b106B086464B30D00BF0371139192A757',
    qyt: '1'
  },
  {
    addr: '0xB62261C1A353E49AA7bfcdAc6Eb9d6CC75cB844E',
    qyt: '5'
  },
  {
    addr: '0x481E03E14a4f0cB8b8b0EcA3a2E70459C63F8Ba3',
    qyt: '2'
  },
  {
    addr: '0x7e0E74CA57F0E456643aDB6771eBe3427f97eFce',
    qyt: '1'
  },
  {
    addr: '0xBFe046249C1A07624cF5145E62a9a307d0Ba0f69',
    qyt: '2'
  },
  {
    addr: '0x3FF439D49c9Da4dE985719fC8F0a7c3b8e387c90',
    qyt: '1'
  },
  {
    addr: '0x6f238697EeFA8037E82BDa0E7277418305D18778',
    qyt: '1'
  },
  {
    addr: '0x4d2bea87ae0383aacdb637319d96eaceb77f4778',
    qyt: '1'
  },
  {
    addr: '0x3D9F8f6D893940F1CfFbcE740aae78D3ABD75053',
    qyt: '1'
  },
  {
    addr: '0x66b17A02DA5349e53439610D6CEc13c9048198cb',
    qyt: '3'
  },
  {
    addr: '0x61f57397Ee769D5350b85C8002a7E613Aa076a27',
    qyt: '1'
  },
  {
    addr: '0x9E5E47FCf5b48457F3a1c7b1863033FF94b40A79',
    qyt: '2'
  },
  {
    addr: '0x9Ff49C84EC82b4BCa2b0bEA128D843b7be330e20',
    qyt: '2'
  },
  {
    addr: '0xbfF3FE4667440C0BD019ab5C943912C988ee2324',
    qyt: '2'
  },
  {
    addr: '0x02AAA8FfC22C995ac79C1f7dAA6386fbAd884CD7',
    qyt: '1'
  },
  {
    addr: '0x572ccd0c1e214aAc2c17F01733C7ccD84b122d1b',
    qyt: '1'
  },
  {
    addr: '0x3cACa04041074d2bb3a31223a3F57C7e463A3853',
    qyt: '2'
  },
  {
    addr: '0x56985AB52651DfeD0E2889D1AFFEbb53d6AaC7cB',
    qyt: '3'
  },
  {
    addr: '0xB7c2A7d8cb7fe32D3412BD401156B3fC55857Dde',
    qyt: '1'
  },
  {
    addr: '0xf40a744E86780B48A41230f1269Ca2121dF03068',
    qyt: '1'
  },
  {
    addr: '0x0a5BD327B39CB8C996118f1b42E68dd3E8727e6f',
    qyt: '3'
  },
  {
    addr: '0xf78747A7C56B990De7D66b5DC944a02fd7D784dB',
    qyt: '1'
  },
  {
    addr: '0x058E432c2792Fb8E14d6A342c00EE770515AA080',
    qyt: '4'
  },
  {
    addr: '0xF26b2D57716Cc565270f75bD900C0246ADE3c800',
    qyt: '1'
  },
  {
    addr: '0x21611C986D23CB43ceB7c8FA00362e565EE5EA1D',
    qyt: '5'
  },
  {
    addr: '0x8dA52C531CE3ac1f9735307fd712Aa39D0bc4115',
    qyt: '1'
  },
  {
    addr: '0x2790E859aC5DeB40f646912bed151d6Fb97f40b2',
    qyt: '1'
  },
  {
    addr: '0x43289616133Cd43291E2Dc11E8190d8083dF8681',
    qyt: '1'
  },
  {
    addr: '0xFf8F30097A9DffaCA84CBd64ACaa467788592249',
    qyt: '3'
  },
  {
    addr: '0x587E0F0fB4996253138fF0cFb03bF60bd60aD9Dc',
    qyt: '1'
  },
  {
    addr: '0xE551c69C36bF5B7F6c0E19d3F6550746489a53CC',
    qyt: '1'
  },
  {
    addr: '0x485bB5695012770a574823B16FEf2FAde74886F1',
    qyt: '2'
  },
  {
    addr: '0x72aC855202D52AC4E87ec2e640Ea6EeC7Ff6B0e3',
    qyt: '1'
  },
  {
    addr: '0x71b0Ed6b9b30ac3B8e5cd6EFf90904D3B694bC5F',
    qyt: '1'
  },
  {
    addr: '0xFA30Bc51D17555f04eA8E04C4143d9c49c17C163',
    qyt: '1'
  },
  {
    addr: '0x0c2D0db3C18c691e4d1E4490FE6217c9489b6Ff8',
    qyt: '1'
  },
  {
    addr: '0x64c20a2a7D37068fAD910F69421b879EeaDBDC16',
    qyt: '8'
  },
  {
    addr: '0x7b2340957e780d67B2f52d33b60c1d7d4e31BD34',
    qyt: '1'
  },
  {
    addr: '0xe28c8aFC4EfA0551c64c1CE3bA5f34D59D6Dc197',
    qyt: '1'
  },
  {
    addr: '0x99C9BaAEce1f0E6FE69e93c79F80E1C8C4d54668',
    qyt: '1'
  },
  {
    addr: '0xB26Bf661cB1aE05e5Fb03316d1701C1c4Cd839f3',
    qyt: '5'
  },
  {
    addr: '0x881D72660F81d5BEb7d5EFdA2f814c18F23b7210',
    qyt: '1'
  },
  {
    addr: '0xA635e4B178DA252E912C9FC2F80dE54ba589b3ED',
    qyt: '3'
  },
  {
    addr: '0x9694FF06a4F3ad6E51a8d03B48F4169Af49dE99e',
    qyt: '1'
  },
  {
    addr: '0xe0769a8c534bbc10e281d6af0796f12d3179dd94',
    qyt: '1'
  },
  {
    addr: '0x3C6F9FaE5890f4b2c9546cd29273ff1533CBB8ce',
    qyt: '2'
  },
  {
    addr: '0x8FAbc900041D2E25B6056A67b49A845D56B77317',
    qyt: '2'
  },
  {
    addr: '0x0Ded95276d96D152DE7939F3cfEAD35Df94e146b',
    qyt: '1'
  },
  {
    addr: '0xeefB48CDF31603616b1715383C3FBFCcb884dD04',
    qyt: '1'
  },
  {
    addr: '0x3092632b690820b844dd47FE67fE9FC097EC5b7D',
    qyt: '1'
  },
  {
    addr: '0x819b174ce553628ff061dd70d29fe2690658068b',
    qyt: '1'
  },
  {
    addr: '0xf9efc0b420209c126b34262a999bc4fd5c30fef1',
    qyt: '1'
  },
  {
    addr: '0x7D5Abd1721592E404d5E470253488B9eaA5D63C1',
    qyt: '1'
  },
  {
    addr: '0x84630547bb3a4be2608852F505DDda0B6Fb99087',
    qyt: '4'
  },
  {
    addr: '0x8A6Bdd60e90e059752830d2E784651F11A99CD20',
    qyt: '3'
  },
  {
    addr: '0xd9Faa34Ba256280B21118eF13C404a4A201a3A27',
    qyt: '1'
  },
  {
    addr: '0x5BfF661b00E62513f164A942A5Ba559BC6863f91',
    qyt: '1'
  },
  {
    addr: '0x706C7A9257313771280519D621913b09B4ea5585',
    qyt: '1'
  },
  {
    addr: '0x75922e1a5E898cCbd924AF60DE010a6BfCf848e9',
    qyt: '1'
  },
  {
    addr: '0xe845777306aD51aa8e4E37038E0Ff3BD4cF17c97',
    qyt: '2'
  },
  {
    addr: '0x6Ac05152222F85fA8fE073F413559587da870E51',
    qyt: '1'
  },
  {
    addr: '0xA4Bb9f4FE144CDDD37edC98c7209FBAa7CB7f754',
    qyt: '1'
  },
  {
    addr: '0x903D6677242140697a14746E936cEA21F53d096F',
    qyt: '1'
  },
  {
    addr: '0x3c7516edE9B762f4EedcfB0E19F7E0BC2700d7cA',
    qyt: '5'
  },
  {
    addr: '0xBe8dc23Ab86Cbd0a4f36c3375aa8723bDaCA216A',
    qyt: '1'
  },
  {
    addr: '0x3ABdeC634f30Ed416f723cc5b4b95859651623b2',
    qyt: '2'
  },
  {
    addr: '0x208fd72CEb883435FA67E24ADC89dFd361809457',
    qyt: '1'
  },
  {
    addr: '0x8c7f2faacd50e15bb92adb0f8c9cd2bb74995148',
    qyt: '1'
  },
  {
    addr: '0x60907cD6e90D2E26E7067740C6af5B2eCEF7b74c',
    qyt: '1'
  },
  {
    addr: '0x9F4f8473Ee4b100CAD67Dd198052ef292E760C50',
    qyt: '2'
  },
  {
    addr: '0x38905330ed6DbAcbB270d832BfA6877B68Ba5b7e',
    qyt: '1'
  },
  {
    addr: '0xa4534dE34bFDe58F35A20Ab4Bc1e67d571211A72',
    qyt: '1'
  },
  {
    addr: '0xA62491372DD18aB753291aEfDE19f7d7B99C5FFE',
    qyt: '1'
  },
  {
    addr: '0x620129DfbC91cdE4e4aB61A0Ddd8804DEA7905D1',
    qyt: '1'
  },
  {
    addr: '0x653f0a1397203d53B31dd616a9096b5924aDdb0B',
    qyt: '5'
  },
  {
    addr: '0x72a7Cf1850F5f5a0628a83eaa75b530Fd1F39cA1',
    qyt: '1'
  },
  {
    addr: '0xC8994fC1E592716E744876B323878fAcc71fcAb9',
    qyt: '2'
  },
  {
    addr: '0x7d45dc53271ddc3ece3ea3920c45dec3d8f620fd',
    qyt: '1'
  },
  {
    addr: '0x0A9CbD0c1f2eDFB6Ac2081c6A51f432473E98721',
    qyt: '1'
  },
  {
    addr: '0x47D25598De3f8c92365b785c3a51e0C2B6bCeE8c',
    qyt: '3'
  },
  {
    addr: '0x4C25E17ff037fB31F7BdFF34377B58416aD7E3b9',
    qyt: '1'
  },
  {
    addr: '0x8D3c10D8bd4459fbD7E02B9de43186D795270094',
    qyt: '2'
  },
  {
    addr: '0xC3dB2245cf753C452635022BDA6068ACe65EA1BB',
    qyt: '1'
  },
  {
    addr: '0xC1f38D3b9F590110572c71499302aF51E9F0E78D',
    qyt: '2'
  },
  {
    addr: '0x6b243AA5c6a55E8626Eb5ff2cF63aC7372cc1064',
    qyt: '5'
  },
  {
    addr: '0x59450E6dB4135C37590544BEB6F480fc8A5557aF',
    qyt: '1'
  },
  {
    addr: '0xa992e88C9Dd7422538966e935378680d1fb5A117',
    qyt: '1'
  },
  {
    addr: '0x21E2742F09b83187997a10b754620Ee5FaFC7eD7',
    qyt: '1'
  },
  {
    addr: '0x094d32487C6a74a0DEE1aD0760c069b2953C709A',
    qyt: '1'
  },
  {
    addr: '0x60FD1a0174469f0Ca7da2dc94371b761b52E68eD',
    qyt: '1'
  },
  {
    addr: '0x416FaA5269908FCb891790c9ab71f75aFF373Da4',
    qyt: '1'
  },
  {
    addr: '0x76b80BDF12B32042DD349077158674F1BF36D3aa',
    qyt: '3'
  },
  {
    addr: '0x774bC6B50dB41219321e2754d68217b00972Bf80',
    qyt: '1'
  },
  {
    addr: '0x1Eb75482F4796a282CFb3a1f34fC09E7D61c41E8',
    qyt: '1'
  },
  {
    addr: '0x841D2c899beA63F52a7b2D597E2dE7A1171baC64',
    qyt: '1'
  },
  {
    addr: '0xA100D02C16B59F8b25c6a43650048Ca7Eca1ee96',
    qyt: '1'
  },
  {
    addr: '0xd12ff0fB2E2298DC648Aa778e0bC5531f7F83679',
    qyt: '1'
  },
  {
    addr: '0xa5103E4c305B741B45B96aD0AD50d545bd0631F6',
    qyt: '3'
  },
  {
    addr: '0x1f7e26faA15565c84D98786a6fE87f86751159c9',
    qyt: '2'
  },
  {
    addr: '0x47E76b3A94FB14e11c67F16Ed80f2689f9F18341',
    qyt: '1'
  },
  {
    addr: '0x74E198128ecffDde0Ff7B46a6763Bbc9237492Ca',
    qyt: '1'
  },
  {
    addr: '0xfa00a0eb497c79f63BB6C1c58CaAf3aF83070913',
    qyt: '1'
  },
  {
    addr: '0xf95ca4167b99A9a250bFb0f28556ad5a4A476604',
    qyt: '2'
  },
  {
    addr: '0xab104EEA31E033f2359A43cB32901Dd6D18D105F',
    qyt: '1'
  },
  {
    addr: '0x3c13ec303A0EE316884B3E8aEAcDd670F5b43A04',
    qyt: '1'
  },
  {
    addr: '0xa61720bccdf6839c1c000a318ec827584bacc3a5',
    qyt: '1'
  },
  {
    addr: '0x8A4D15935663BA198B7288043386986c77D625a1',
    qyt: '1'
  },
  {
    addr: '0x5a09749eEb1Cdda204B40bb0Ec59cE599F06BdF5',
    qyt: '1'
  },
  {
    addr: '0x75722fd483dd8676397135aca01c27d26b195cbc',
    qyt: '1'
  },
  {
    addr: '0xBedF1Ac72a99132b43B9152e503197E3CAb1f4fE',
    qyt: '5'
  },
  {
    addr: '0xF9AaCD6612d610089189C36DFc2df83a8D84e18F',
    qyt: '1'
  },
  {
    addr: '0x008d563bb3c69a7b4c6d3b36845c93592b1918c5',
    qyt: '1'
  },
  {
    addr: '0x627199eD241424DE95771F06E4fbBf64527b4a65',
    qyt: '2'
  },
  {
    addr: '0x36659d872B1f828dAE9c25cF2dB275Ced0569529',
    qyt: '1'
  },
  {
    addr: '0x2Cfa868780E726d437F191C6BA16Ca7022B3919B',
    qyt: '1'
  },
  {
    addr: '0xe950F2935924908d2B73C9aB290B865A1fBba899',
    qyt: '1'
  },
  {
    addr: '0xd33e125388a094c1909F65C24cf56293cD03e16a',
    qyt: '1'
  },
  {
    addr: '0xf03829529875C4e386EcD357FAf8728E0f53F0B2',
    qyt: '2'
  },
  {
    addr: '0x4EeB1cc886E30651ae5767A40D784718ab4Da3A7',
    qyt: '1'
  },
  {
    addr: '0xC83075BB0d3A1cFE64FAefC5FC8915f3C9Ae8b90',
    qyt: '2'
  },
  {
    addr: '0xda6Df50fDf55c4A60733938B56C32C850Ab53a1e',
    qyt: '1'
  },
  {
    addr: '0x6feb0cb4b92117da91eb92650373fb51f2fe85c1',
    qyt: '1'
  },
  {
    addr: '0x5df930cf270971f057fb3bd4134ecea3bef3835b',
    qyt: '1'
  },
  {
    addr: '0x15c102f4244e19f9b0c22306e1f37da1b1e246e9',
    qyt: '1'
  },
  {
    addr: '0x2c40c38ca16b8839409d0351480061748131aeb1',
    qyt: '1'
  },
  {
    addr: '0x522568f0118c64a2a1e3945ccc59d15d4d9a57b5',
    qyt: '1'
  },
  {
    addr: '0xdddbe59c860b32cedb7c76f56eaa86a8c3381053',
    qyt: '1'
  },
  {
    addr: '0xee7357f1fe1f2fcbed25d7e92722f945ff425e5b',
    qyt: '1'
  },
  {
    addr: '0x2ab5d0e6925033bc3e2a4d899b7cf78e80170150',
    qyt: '1'
  },
  {
    addr: '0x8f12b39e0436e24c93bfff974acc94755394ec56',
    qyt: '1'
  },
  {
    addr: '0x32aa1b53781f4011be6186a1c944917ed4736f5b',
    qyt: '1'
  },
  {
    addr: '0xab56eccc72ec1a969f15539ee782c64ec3849b42',
    qyt: '1'
  },
  {
    addr: '0xd56f7a5adefeb4363f21ab22a6627ea748596984',
    qyt: '1'
  },
  {
    addr: '0xbdbd15ca3d37cfefec29448ab4c865eacb490d31',
    qyt: '1'
  },
  {
    addr: '0x0cb2aa126676cfe1ba5b03efe306fcd548fc1cf8',
    qyt: '1'
  },
  {
    addr: '0xbAbE99258a955e9A4D0aD46f4FfeDa547EB836ff',
    qyt: '1'
  },
  {
    addr: '0x20B26eE4A14a0935c933207A25C7be022eB522C4',
    qyt: '1'
  },
  {
    addr: '0xcEC4711a0901816232b2108938a5e6D841d97699',
    qyt: '1'
  },
  {
    addr: '0x289922f253a8DB972A6Be189eA6b404ecd6EB6FD',
    qyt: '1'
  },
  {
    addr: '0x89d8e51d2f6732f20Dd7937D626D9e5EE6efdb0f',
    qyt: '1'
  },
  {
    addr: '0x491052Bb9f78324c358D05E8975dF664DFB46D6B',
    qyt: '2'
  },
  {
    addr: '0x4EB5B3eBf7c595Dd2c7Cb4E1cDD94713B2246512',
    qyt: '1'
  },
  {
    addr: '0x3De9a21B8c091749449aC4bcf3f10af740F54C1a',
    qyt: '1'
  },
  {
    addr: '0x70E0AEb075e1F818F8a0486564b4d258FbF5627D',
    qyt: '1'
  },
  {
    addr: '0x5551e1b3f9eaf6277e5f9a83259a353a08d87f9e',
    qyt: '1'
  },
  {
    addr: '0x283273DD2111E8F3231e4E10096fA9C7a1a58D08',
    qyt: '1'
  },
  {
    addr: '0x5661fc304CbaeE874Ffa43a337D6cD2BFf222fF8',
    qyt: '1'
  },
  {
    addr: '0xa9CC9573b05472e161CF5f4a58E30b321f48562f',
    qyt: '1'
  },
  {
    addr: '0x510112B8D01c922B4FF6a6E14D6F78E8AC182FaF',
    qyt: '1'
  },
  {
    addr: '0xa3BdD0dBA8b3194Ae3c94caB9F950E990f6D8bc2',
    qyt: '1'
  },
  {
    addr: '0x3ac3B88DA9f4641d36f77f0Ed0EfD0Bc8656Dce3',
    qyt: '1'
  },
  {
    addr: '0x94238F45823291943779C559663c6bb19e685406',
    qyt: '1'
  },
  {
    addr: '0x4c9242ea534d445F29e7179ec1D300Bf47534a55',
    qyt: '2'
  },
  {
    addr: '0x0252e7eD7611C9d3d37685c3cf71F73e087eEA5b',
    qyt: '3'
  },
  {
    addr: '0x9Ff1B048bdB3849d5Ce36E7B258a3e8e5CccA91C',
    qyt: '1'
  },
  {
    addr: '0x7878573671fE82EEaAe4c1402F413D34D5bb3EEF',
    qyt: '1'
  },
  {
    addr: '0x1462F1cAb1f923eea837409a09632AE5483DE122',
    qyt: '1'
  },
  {
    addr: '0x03A7B8E02107c2A7A11541A4d5a32F8eE5B52621',
    qyt: '3'
  },
  {
    addr: '0x9da0398ec2b7fc7d11b8a90903d12ca68e2cba7b',
    qyt: '1'
  },
  {
    addr: '0x8c28393f88cdCBDD677f1e936B6d05cF34909a23',
    qyt: '6'
  },
  {
    addr: '0x3f381b0CC21a3dF44aBbD0E6E8AfE5bA2fE046F0',
    qyt: '1'
  },
  {
    addr: '0xB9bA888F43B2eF0477B4Bfd0DBb0D83890321598',
    qyt: '1'
  },
  {
    addr: '0xD8642CeC4CdeF657C1Ed91213412266A1Bec2371',
    qyt: '2'
  },
  {
    addr: '0xb7C314b8e38F317bBdC443f315e2c0F841f9d32B',
    qyt: '1'
  },
  {
    addr: '0xB19396141443eD5020905119757a4eE435383532',
    qyt: '1'
  },
  {
    addr: '0x6810f847173216905064DD6d59Ac792CD848bA0B',
    qyt: '2'
  },
  {
    addr: '0xA071dF3eA7405E5806FE0eDa60daA2cDe949A6AA',
    qyt: '2'
  },
  {
    addr: '0xe5b612E676c764f1A1Fd13A829d95cF0672063B8',
    qyt: '1'
  },
  {
    addr: '0xdd6cdA50d3398ED3e098A6CC304B1552458F5496',
    qyt: '1'
  },
  {
    addr: '0x3fDa2B0221501f9Fc986415ffEaEAA0E76ca47Ed',
    qyt: '6'
  },
  {
    addr: '0x230b28c1A6c58Cc2cd3cea8507574c9CE1D3B890',
    qyt: '1'
  },
  {
    addr: '0x7E05382A7463bffD77fd09B44Ed261Dd72D74AA1',
    qyt: '1'
  },
  {
    addr: '0xA5E6FB71B8896d3d59321eDF6552B6Dfe758FF9a',
    qyt: '3'
  },
  {
    addr: '0x2939E2DDAF829C83C6Dc6bb508ADDD23e4c23b1D',
    qyt: '2'
  },
  {
    addr: '0x6349C65Fc0d31DAf1B4AAADc7Ec277D09BC303Eb',
    qyt: '3'
  },
  {
    addr: '0x1efdf463bacba67f35d9810fd462198c0395565b',
    qyt: '1'
  },
  {
    addr: '0x544f41275083587c5d84af309a59ec3689aaf9e1',
    qyt: '1'
  },
  {
    addr: '0x26Dd21E25722361fEb3cD983fA59a49B4Ab6EC7A',
    qyt: '6'
  },
  {
    addr: '0x04eF9Df89bdbc69334B7F5B7753401de20eC406C',
    qyt: '2'
  },
  {
    addr: '0x694B96C01d0124e0148cA6BD77539963E7874847',
    qyt: '2'
  },
  {
    addr: '0x4282341588F94c158bFAA40AE2C9F3ABfdAc647f',
    qyt: '6'
  },
  {
    addr: '0x37aD881FBDdDf20e9133bC85C86837D06fc53543',
    qyt: '5'
  },
  {
    addr: '0x2ab761d70c9d8d01b09CF96543f57c7eD126C73D',
    qyt: '1'
  },
  {
    addr: '0xF16072f93AC926A26613814952e9CBc709d807d1',
    qyt: '2'
  },
  {
    addr: '0xf5ea93e6F3Ca9BEc9Bc56362Ca4ac610fc4547c7',
    qyt: '1'
  },
  {
    addr: '0x2e074a6b333eef7B95afC551d1a70033CB9E00e2',
    qyt: '6'
  },
  {
    addr: '0x6De276840c816dA748A813663Dc19D043479ac09',
    qyt: '1'
  },
  {
    addr: '0x565101aF865bA1E0a6899def356B9bb5345F7062',
    qyt: '2'
  },
  {
    addr: '0xdC5c4B074219B5084f42eAbf8D0286FC52698c2b',
    qyt: '1'
  },
  {
    addr: '0x46D186092844E4C7e4E7c1AF5c96e64420aCad2D',
    qyt: '2'
  },
  {
    addr: '0x3d1590df649aeD34Fecf6B1E87aB063e173F1913',
    qyt: '1'
  },
  {
    addr: '0x607c11E70Aa947Ce3562EaAd3fc9c15ef5d139a8',
    qyt: '1'
  },
  {
    addr: '0x978673d7293819fbedb3c50150bca4881d99b25b',
    qyt: '1'
  },
  {
    addr: '0x21Ed1ce3D834a33DD6d39AeC8939c8519D952aC4',
    qyt: '1'
  },
  {
    addr: '0xFd7686Cf3390e39dE98E7fAC1A544985BB749d4D',
    qyt: '2'
  },
  {
    addr: '0xb535f544Ad285E5cCb4dBd93a4B80D34F34D4711',
    qyt: '1'
  },
  {
    addr: '0x2bfdD4320877328ca95C75Ed420df26758aCB704',
    qyt: '3'
  },
  {
    addr: '0xbe20d6dE75CA58e07Aa75fa98623C4B9027DB775',
    qyt: '1'
  },
  {
    addr: '0xC8eF8ddfD223440c74b91dA4Cde7B3048C711263',
    qyt: '1'
  },
  {
    addr: '0x9B79C47455323068d1a4a5D258140AF0026061c5',
    qyt: '1'
  },
  {
    addr: '0x178512C47C88f5abC0806Fa79A3a0Ac26744c581',
    qyt: '1'
  },
  {
    addr: '0x56e6A52d7fefbd7f140F5Da6313BF59b70C904c3',
    qyt: '1'
  },
  {
    addr: '0xD82F7759B06eAfD4098c0bd111C3019f4437C39d',
    qyt: '2'
  },
  {
    addr: '0xa797F1F9Fe56278215034923A5D2F8C39888f825',
    qyt: '2'
  },
  {
    addr: '0x954A12459A24844D27c8e5B67C364EDE2d481f78',
    qyt: '1'
  },
  {
    addr: '0x92F3b71bf390AbF58F5402CDc8C6B42398871768',
    qyt: '1'
  },
  {
    addr: '0x7c96292d915375227d86fa56c621ba5e9754ba68',
    qyt: '1'
  },
  {
    addr: '0x05641903Fe5d59e60bd03C287346c26dB03dAc9c',
    qyt: '1'
  },
  {
    addr: '0xb54968bce611a34ff2221b6af1e1ad9f00faaa99',
    qyt: '1'
  },
  {
    addr: '0x384224576bAa2bb17c12cf976266fbE3c3Fe70D9',
    qyt: '1'
  },
  {
    addr: '0xAD4A96AEdA17672cCcDCf49E574d2C5EE32Af55a',
    qyt: '2'
  },
  {
    addr: '0xcBec9d9aEB6a84200740589A2136fCb97F95A9a3',
    qyt: '1'
  },
  {
    addr: '0x4497d0c395BdBfaa2DCA29313593A5e02032311d',
    qyt: '2'
  },
  {
    addr: '0xa028A988872CCd453BaF742e8c1269028f32ff37',
    qyt: '1'
  },
  {
    addr: '0x742Dd5f8Ce5434472C2fF82DF7004EA9b11FAa9c',
    qyt: '1'
  },
  {
    addr: '0x5C623B9bD7181b86b094F86eFb8c539A9724FcA3',
    qyt: '1'
  },
  {
    addr: '0x04376925eeE59FD85306229262f4b033934992f1',
    qyt: '1'
  },
  {
    addr: '0x8CEB0a6FfdC76dA0182457C1975c0d438DA3FE3E',
    qyt: '1'
  },
  {
    addr: '0x28B8C8eccfd94121C0B65A8e851c2Efb382fd9Ba',
    qyt: '1'
  },
  {
    addr: '0x24037BbED9981EAc9af55999aB5f1E2DDe0853C2',
    qyt: '1'
  },
  {
    addr: '0x96B4544E062d0d2D6FB5Dbcfa18845c8e00F8DA4',
    qyt: '1'
  },
  {
    addr: '0xdAa18Fb1f296BE4634D44095D434f8e859632732',
    qyt: '1'
  },
  {
    addr: '0xd9e806B623fFb4CFCFD3A72586e43cd47b69cbb3',
    qyt: '1'
  },
  {
    addr: '0x660b3a2554187d740d35f29B3Ad4Ea82cD0f5783',
    qyt: '1'
  },
  {
    addr: '0xb7c7f98F8C2558fF4DCb744c7bf90A37B6c5B32e',
    qyt: '2'
  },
  {
    addr: '0xEe849761897Da73a1F286C962506d57Ffc6CB1a3',
    qyt: '1'
  },
  {
    addr: '0x34a2b96D83C496A7e38eA0d2Ec1D401082827e27',
    qyt: '1'
  },
  {
    addr: '0x85F48574508F57A3F159Fc9bf673963C62Ec9452',
    qyt: '1'
  },
  {
    addr: '0x1cAd4997b550a1e31F21a6a51C1708152371B655',
    qyt: '1'
  },
  {
    addr: '0x5B9De7c6b24F1baf04b3bDde9F656F5550f6C9EB',
    qyt: '2'
  },
  {
    addr: '0x9ee621aa4432316F50758A95dBeC69753bC525bb',
    qyt: '3'
  },
  {
    addr: '0xD00401efF701deb28AB88c18cB87B94cEEdc1380',
    qyt: '1'
  },
  {
    addr: '0x028a158d9B47Ebedeaa17b67c7444ae1c82405D0',
    qyt: '1'
  },
  {
    addr: '0x1bA46c1e3C0d4e0cE087A8dd0DdA4cf0A5411b55',
    qyt: '1'
  },
  {
    addr: '0xD12a1Ec79d4e18e39E58406456b1F7D08C4C9E7d',
    qyt: '2'
  },
  {
    addr: '0x827ea32E220cb12FF1b2B7E28c55254DB72FF4A2',
    qyt: '6'
  },
  {
    addr: '0x0a5139a99156564f6be54c6e9ca513336ea79e72',
    qyt: '1'
  },
  {
    addr: '0x687591102413eD41B8bd9961c5f0d9be893B266F',
    qyt: '1'
  },
  {
    addr: '0xab6FD2F7ba94FB8f5F3201d8e7E29d9A61ADaE15',
    qyt: '1'
  },
  {
    addr: '0x1ce3145b50e0731510b65aa3778eD372d7291DAA',
    qyt: '1'
  },
  {
    addr: '0xBcd672FdF512be5acB54013704D8619f9a9e1209',
    qyt: '1'
  },
  {
    addr: '0x55FAe004c30FdC0347F2a92A10F54306AB59810E',
    qyt: '1'
  },
  {
    addr: '0xa07eba8a00764795074CE3628B1648c1C21126AB',
    qyt: '2'
  },
  {
    addr: '0x9b5fb7376a4bDA08940A657F95c9F58caA25E6d8',
    qyt: '1'
  },
  {
    addr: '0x73b3E8BFBB008546DB49C5Ae5aF2bfCb058a9A28',
    qyt: '1'
  },
  {
    addr: '0x1E16E689eE51c9eD32BBC28931896f0227aB85Cf',
    qyt: '1'
  },
  {
    addr: '0xd5F8ad7f75312Fe7b7130e161ee069B3Aac4D992',
    qyt: '1'
  },
  {
    addr: '0xe3A4a2658A66d7d803F4aF5dDEdc1197CBE87e23',
    qyt: '2'
  },
  {
    addr: '0xF0D2E1100ef66f2EA9064a734234d821b9ad0e92',
    qyt: '3'
  },
  {
    addr: '0xC4bB98595EE09b24d15Fec203F79EfF1f6C4d21d',
    qyt: '1'
  },
  {
    addr: '0x1a27881E041737bc7eF5c616A8aF08Ec7E51Db40',
    qyt: '2'
  },
  {
    addr: '0x9553A6736d9bFE14e4C2b5f7092CD3B93ad709a6',
    qyt: '1'
  },
  {
    addr: '0x0c138f54731126Ff76ef574eFb21cA8D5083AE6e',
    qyt: '1'
  },
  {
    addr: '0x3779e47f9b5d3E2b15f6210863c52fBC47B2ae76',
    qyt: '1'
  },
  {
    addr: '0x7b95c2f67b6db0fe5355a1fefc7d112ee369890b',
    qyt: '1'
  },
  {
    addr: '0xcA51F9e1db915D642B63Fe6DDb4Ac7808aDAb47c',
    qyt: '1'
  },
  {
    addr: '0x89FfaDB01a0341f5A43d14B94BEe5089091a4132',
    qyt: '2'
  },
  {
    addr: '0xc963289D6e68E3e0a9aFc817038365B9eDb7cF69',
    qyt: '2'
  },
  {
    addr: '0x05C07a6471d97F3A3716483f7dE8a088082C5B4A',
    qyt: '1'
  },
  {
    addr: '0x61FAfcF1C57683AdEcbdB151a008BDd7ee1bFac9',
    qyt: '5'
  },
  {
    addr: '0x112f6C93bB40849d9E633875732CeBac69062988',
    qyt: '2'
  },
  {
    addr: '0x5C54466d7224BF07181F6fAd10989bcba6e988B7',
    qyt: '1'
  },
  {
    addr: '0x5CeA9445F5f49ed82E25FeA47C9F5c79cE7880Ca',
    qyt: '3'
  },
  {
    addr: '0x22390aBAA52d4bE608b4530DC1522adc01898c3A',
    qyt: '5'
  },
  {
    addr: '0x26CdBf2ef0957D29307f20316EdECC2Fe55C97A5',
    qyt: '6'
  },
  {
    addr: '0x4F979e912D035d9AD0AbDF8c17E5B7a5a180762b',
    qyt: '2'
  },
  {
    addr: '0xf912f21e174E61956940feCE921dd1b6e480773E',
    qyt: '1'
  },
  {
    addr: '0x2F0A4E4885b89390fc6BfA87579b6390B4f3d0DA',
    qyt: '1'
  },
  {
    addr: '0xDEE0c09Ac6eAfea2EBe755CBc996d6C8C9Af8eA0',
    qyt: '1'
  },
  {
    addr: '0xB5667cb7824F626cF90008FeFdc6765Fee3C0Db1',
    qyt: '5'
  },
  {
    addr: '0x773AAbc6d27F487EbA49D6e759c64Add04baE034',
    qyt: '1'
  },
  {
    addr: '0x546EBD67a6b146265Ccd78dA51264c4D00E87d68',
    qyt: '1'
  },
  {
    addr: '0xC65D7A33B4c0CAd029685B0a89429ACc16f89fe0',
    qyt: '2'
  },
  {
    addr: '0x005a36C1EAa64C5d0e580a80c58Ebd8EE3D8220B',
    qyt: '1'
  },
  {
    addr: '0x13A3f8B6B9c564ab47a12Eded02519FAF9D8B25B',
    qyt: '1'
  },
  {
    addr: '0xa9156F26fd6c5692e77Ee5aCA1000c32D0F59953',
    qyt: '1'
  },
  {
    addr: '0x57680F1Af1F459e0c5829538FB50ecaBF50b027C',
    qyt: '1'
  },
  {
    addr: '0xd044f4cbA252a55e06B78B334f26A0CEcFc7992F',
    qyt: '1'
  },
  {
    addr: '0x517850590818B36fff9DE2b3dFB22A066b96e626',
    qyt: '1'
  },
  {
    addr: '0x45511D5ff135E852A92644c62993612CE3f5EAAE',
    qyt: '2'
  },
  {
    addr: '0xaB3d2E2172e5968A64EC2bCe29F59FAFD8790e4B',
    qyt: '1'
  },
  {
    addr: '0x2301ad09F48936F1902851704DEC92d982c556eF',
    qyt: '1'
  },
  {
    addr: '0x42Eb1F7615861eb74C4a099Ac9dD84Ee6B0ffd16',
    qyt: '4'
  },
  {
    addr: '0xF3DB8D861327e32AE29dd1FD861ab8A1236aFbA2',
    qyt: '3'
  },
  {
    addr: '0x477b41273ba16d77de4ad72c67475f91ff15f548',
    qyt: '1'
  },
  {
    addr: '0xc39A0d73527Aa38E4f93553c7AA7F918C9D9fa01',
    qyt: '1'
  },
  {
    addr: '0xe4EdCDF0A816AE71693d47FEc7a56f7Afe863005',
    qyt: '1'
  },
  {
    addr: '0xe0c67E7e4BC2e926136c5C9F2d0bB4d68199DFE2',
    qyt: '1'
  },
  {
    addr: '0xa9f6aF9367722e7F9e10257511E57C2811fA54Ff',
    qyt: '1'
  },
  {
    addr: '0x347Db437b493Df67C310b6a00E4793EcbBf47a7A',
    qyt: '1'
  },
  {
    addr: '0xa2F13A7053114c956d48a02AaC02b88B1b8Cc123',
    qyt: '1'
  },
  {
    addr: '0x4Fd4142ECB4F82B7540845564F511f9B8dceE8e8',
    qyt: '1'
  },
  {
    addr: '0x7495fd7C1759D017DA4B48672FC941e7Bdc5a35D',
    qyt: '1'
  },
  {
    addr: '0x9C20BB5Cd160DA0432A66F94807fcA484739d780',
    qyt: '2'
  },
  {
    addr: '0x4fE3e485e7039a4788cfA80EF6dDE1D440d99937',
    qyt: '1'
  },
  {
    addr: '0x25a678F0afad27BC4c8a576c29831bA755307Bc2',
    qyt: '1'
  },
  {
    addr: '0x283426dcbB2C8e0aA940A7591DA7971Ec19050f6',
    qyt: '1'
  },
  {
    addr: '0xFEB1343355Dd210dFcBac75fC303b8B694b6785C',
    qyt: '1'
  },
  {
    addr: '0xc23E8321CAb02CDBb8689c43C7B72c6A631D9503',
    qyt: '1'
  },
  {
    addr: '0x9c94c3fAd11200aBD9299376282A1313d2b8Ad97',
    qyt: '1'
  },
  {
    addr: '0xE60ae30fD15DA4861e23Bb8C6Bd140428d1e26B7',
    qyt: '5'
  },
  {
    addr: '0x15d2e111dB832ea68ce170AA81De1d4799333961',
    qyt: '1'
  },
  {
    addr: '0x72AE6d38Cd16b91E5945517DA0F3C93f8C56f319',
    qyt: '1'
  },
  {
    addr: '0x6c90cdbd8f1fc92901e5655f1b628f5cf739fde4',
    qyt: '1'
  },
  {
    addr: '0xed7598D735062be2C3C7108D8Cf07DCf9b0BC93a',
    qyt: '2'
  },
  {
    addr: '0x951FdDCdB58F05Fa859ad6B12EBa2D3236026c5a',
    qyt: '3'
  },
  {
    addr: '0xE82153467d1b7c17cEaf6f3cA96B474cB49B8C51',
    qyt: '1'
  },
  {
    addr: '0x5Ae560c948832e7EA780092d07FE503979bc93d1',
    qyt: '1'
  },
  {
    addr: '0xC4Ae0D238BeC08C9919449F5563FEE7c9729febd',
    qyt: '1'
  },
  {
    addr: '0xB453F0241750947b4eF4195A2Be798E43366d321',
    qyt: '2'
  },
  {
    addr: '0x31589d2165bc2fa1b2a1a3e9bf1de078cf5a6955',
    qyt: '1'
  },
  {
    addr: '0x9092CE24b187AD1DdC8DdB82C5e08fF1D27E288C',
    qyt: '1'
  },
  {
    addr: '0xE593fc5E2148564E1B51d5ff137a65F212F83b32',
    qyt: '3'
  },
  {
    addr: '0x26c8c1eb3fe4c887651fb549e30992ba2e5b908e',
    qyt: '1'
  },
  {
    addr: '0xA11eF5BdBB34521052110F7CA68c06Ee920ad6C5',
    qyt: '1'
  },
  {
    addr: '0xeB5f1348C8a29Bc704c5Cd7BB024e6989462188b',
    qyt: '1'
  },
  {
    addr: '0xAD4f0D13b0771b184f2D221C0CAceBF5B4620Ff4',
    qyt: '1'
  },
  {
    addr: '0xb98Cc02B8F96248d23816a26fEbe1E3Af93a6485',
    qyt: '1'
  },
  {
    addr: '0x3434f8d21379c78ffCc373cF057cd2938D060fea',
    qyt: '1'
  },
  {
    addr: '0xa2b4c41451943E4Aa8d668eb4643e587acE61209',
    qyt: '3'
  },
  {
    addr: '0x79BD6DD693F90077CB2eEeaA8B7a87d3c797e0e1',
    qyt: '1'
  },
  {
    addr: '0x151e50D7F5d34E1f695D5b352f90768f1fA0F8dd',
    qyt: '1'
  },
  {
    addr: '0xb73fEb5328b2C8a9FE6d5C78195214655b91BF83',
    qyt: '1'
  },
  {
    addr: '0x5605Ed839500F5d21035bDbcbD0465056b3Dc099',
    qyt: '1'
  },
  {
    addr: '0x0e8d367b9aa27d1ab71b22e6660d888e23eaa8ae',
    qyt: '1'
  },
  {
    addr: '0x2A3dD675a1fa22Ed2F80B03cc9975838Fd743aFD',
    qyt: '1'
  },
  {
    addr: '0x7C8e4498BD6019603eBdd1F933499395B346f06B',
    qyt: '4'
  },
  {
    addr: '0x9b6BEA03F2e073a7C62F909c1e4919854D223976',
    qyt: '1'
  },
  {
    addr: '0xC035f9209946b51E9EEd99e0eeDbeD06F70388D1',
    qyt: '1'
  },
  {
    addr: '0xd9b9ff965f65d315802d23799c2e00f1a27a5e30',
    qyt: '1'
  },
  {
    addr: '0x7b46935414e4fabe0f833d7431dd5dd658d18e0a',
    qyt: '1'
  },
  {
    addr: '0x3a6d66b323a4df4ce2a6a0935fda34300dfe092d',
    qyt: '1'
  },
  {
    addr: '0xe6763FEFF590fCEDF5885bCCEb1729d7Ac20cF79',
    qyt: '1'
  },
  {
    addr: '0xecde1E6f391aE2324D3b0548A950c3015E8EeE07',
    qyt: '1'
  },
  {
    addr: '0x3906D6dEdc86884082cc3Ac37811f26E414bbEA6',
    qyt: '1'
  },
  {
    addr: '0x2ac7D8688C10d5615b19F3ab550ccd4f540c5155',
    qyt: '1'
  },
  {
    addr: '0x6d8f39c01aa67043ab1022870bc9db44908b4dda',
    qyt: '1'
  },
  {
    addr: '0x8c0DA62b2F992eC731cE92Fc30B1B5227D8C14a7',
    qyt: '1'
  },
  {
    addr: '0x070724810C6364CafE2108F89A460f26abCFa353',
    qyt: '1'
  },
  {
    addr: '0xba82718b8B5471d0E3dc0775AC32fc6cF9233092',
    qyt: '1'
  },
  {
    addr: '0x507FE3E0dB6a1B9E923Bea6206BCA9FBe317e90C',
    qyt: '1'
  },
  {
    addr: '0x3a43A3931eF276F22909720E160Ef215700Dc26e',
    qyt: '1'
  },
  {
    addr: '0x038c972E230F4a3caa9A17DAE96821388107C998',
    qyt: '1'
  },
  {
    addr: '0x4389a0a6FfadBF3f1cB4f06c0d74026b435eF958',
    qyt: '1'
  },
  {
    addr: '0xd0c599b7e6813bd60a533293feadfbabf16bcb9b',
    qyt: '1'
  },
  {
    addr: '0x457d71b999eef1a54ef39722639ee37ce1240617',
    qyt: '1'
  },
  {
    addr: '0x24708431f5266aa53288b357b0a02b39a5bd19d2',
    qyt: '1'
  },
  {
    addr: '0x2edae6923ab66dd6697a5f48529463018ce26096',
    qyt: '1'
  },
  {
    addr: '0x40a89d76739464b59cf098afe47872d2fb2bf14e',
    qyt: '1'
  },
  {
    addr: '0x666Bd64373f1470c377A56fFc00B3c7ddDDEb739',
    qyt: '1'
  },
  {
    addr: '0x9ea383fd4a74da52100881dd7f298616470adf76',
    qyt: '1'
  },
  {
    addr: '0xb3b226c165269b34863a8d932eced6be70be7dc6',
    qyt: '1'
  },
  {
    addr: '0xe068376943B99399c5efdDfD77E80b38E0F41Dea',
    qyt: '1'
  },
  {
    addr: '0x792d080C43139BdcBABA43903528C45C69a8Fc91',
    qyt: '1'
  },
  {
    addr: '0xf1a707DbD93186B9303B18Ec2102E5A572067f59',
    qyt: '1'
  },
  {
    addr: '0x0Eb819F112A7FC4F61404E0e1f1565E827Df0a95',
    qyt: '1'
  },
  {
    addr: '0xd21879b9fC10f485d1B3a5a97F3A274FD74E4A73',
    qyt: '1'
  },
  {
    addr: '0xBa678FF3eEe60b03b14CBe56d543d561A69E27c7',
    qyt: '1'
  },
  {
    addr: '0xF01c8e8dAAe97E79f2A9b0604D17B4CE0ab9883a',
    qyt: '1'
  },
  {
    addr: '0x16376b679C187ce0F9A440Cff19D8B5A01d37559',
    qyt: '5'
  },
  {
    addr: '0x871bE485C1eD6D35Bcda1de86C13380387ae2341',
    qyt: '1'
  },
  {
    addr: '0x46f24F7E3203ad4217eA93111C1e68D04606be19',
    qyt: '1'
  },
  {
    addr: '0x618E8BDC04626d1ce04b3E649f0e11Bee32E9b14',
    qyt: '1'
  },
  {
    addr: '0x558BC05E6aaCAC3Dda96C43FcD242a11AFd3865C',
    qyt: '1'
  },
  {
    addr: '0x33bfaf47cc020a19c4561d79b25bb53ca4cbdfea',
    qyt: '1'
  },
  {
    addr: '0xE5Fd2f04800f0e81A90b244Aa6227F0eF9Ca7D00',
    qyt: '1'
  },
  {
    addr: '0x571ad36DeD93eC62df5deBa152352DE69C720056',
    qyt: '2'
  },
  {
    addr: '0xc04926D785D4de0828F999086eE2C806CE9d80CC',
    qyt: '1'
  },
  {
    addr: '0xeFfa6c77d2e44DA991C1b1F814a3fafa211970E0',
    qyt: '2'
  },
  {
    addr: '0xAD217dA088D021a7898252002f12502E9D2261dD',
    qyt: '1'
  },
  {
    addr: '0x5D792D64f63e3DD56528d7867eD1925e0c684Dea',
    qyt: '1'
  },
  {
    addr: '0x9F511a92d4F778B686170f18AA53f3C03e28cF85',
    qyt: '1'
  },
  {
    addr: '0xa1C7347C1490109e874b60fB76359E019173CA45',
    qyt: '3'
  },
  {
    addr: '0xEa4Fdc035f5Cf0920C7e709612C876A4d81805E6',
    qyt: '2'
  },
  {
    addr: '0x23F12D3DC94266b426996963cf81f89418863871',
    qyt: '2'
  },
  {
    addr: '0x4A68Aa8075dFB4CEACEE8300b8232b551F55af8D',
    qyt: '1'
  },
  {
    addr: '0x87fF0233486543532B64D26F2eD56f33a07011Ef',
    qyt: '1'
  },
  {
    addr: '0xa4e38589606D80f792Bbd4c2482574d0d92C5cFD',
    qyt: '2'
  },
  {
    addr: '0xD89De7eab4AB9db9A33D5B8Fd43194e76F89F69C',
    qyt: '2'
  },
  {
    addr: '0x66AFb8cA281a88bd5A590a7aE59821913263c81c',
    qyt: '6'
  },
  {
    addr: '0xdE68f208eb4ef8f675d30Cf96E36219c045CF2bd',
    qyt: '5'
  },
  {
    addr: '0xdce44b0c754b99a6932512ba462071a7caea4d0b',
    qyt: '1'
  },
  {
    addr: '0xD0d842bcDcBCe9933b0fa354293bFf609B60447d',
    qyt: '1'
  },
  {
    addr: '0xC6B16994D80039ff0435b776F84089F05c470956',
    qyt: '1'
  },
  {
    addr: '0xc51729c7c61d8698d556a2ecbf727106aa140749',
    qyt: '1'
  },
  {
    addr: '0xeED5C1177d944ad1E63Bb4770d319309838FDBb4',
    qyt: '1'
  },
  {
    addr: '0xaDDF367dEa72f6b1380d72895F9568e907B9f409',
    qyt: '1'
  },
  {
    addr: '0xC0898029dbdAd268c06d3a15FF8536CaD9dAAFf1',
    qyt: '1'
  },
  {
    addr: '0x777c10C84c5fa985670e144b29D593C03A1AaF3A',
    qyt: '1'
  },
  {
    addr: '0x60E6Bc1A47830AdA4F187c8F7a832f56003087a5',
    qyt: '2'
  },
  {
    addr: '0x030e49592a0c75cfe8530c8db1b6717ae1cc5101',
    qyt: '1'
  },
  {
    addr: '0x5a6E3b093c067709882Deb816a27a6482C7583B8',
    qyt: '2'
  },
  {
    addr: '0xdAa92b6E77a690c3005b1e22D72CD984CEb054FC',
    qyt: '1'
  },
  {
    addr: '0x5225dDC2DA33FbB445ACea68380C4589F1094b6F',
    qyt: '1'
  },
  {
    addr: '0x340dC4f0a7Eff64e0b682CB7C6396C84975F3823',
    qyt: '3'
  },
  {
    addr: '0xDb8D3b43dD58e3f4D6C5A22893D6603eC60f3fd3',
    qyt: '2'
  },
  {
    addr: '0xeC525d22313d2C4d95236Db878674D37e438642D',
    qyt: '3'
  },
  {
    addr: '0xf019EBD5A0e6984142e25E1cbc68c70d6c5F95D4',
    qyt: '2'
  },
  {
    addr: '0x495e2e127e06560A27def93D536Cca6f581d9D8d',
    qyt: '1'
  },
  {
    addr: '0x7D9A67175447cAb8DBa6a8404bEee0e934a2653A',
    qyt: '1'
  },
  {
    addr: '0x78A1b70C9E37F114d07833c24567308A3e55a9C4',
    qyt: '5'
  },
  {
    addr: '0x82C4911B3F87A7a029aA20E972Ba7460eaa7c263',
    qyt: '1'
  },
  {
    addr: '0x51875a06ABf8586258A17442aFa063A06F18930A',
    qyt: '1'
  },
  {
    addr: '0xD815f4254dcDCd16Ca19D436E03BfD664730817d',
    qyt: '5'
  },
  {
    addr: '0x982F5C9cCaCa614a9F3767b508CBF71C566fA838',
    qyt: '1'
  },
  {
    addr: '0x4B32BD05c832304537Ec945197516C96eD191668',
    qyt: '5'
  },
  {
    addr: '0x9b3e89c9eb7B58E3633D558D5541B6c345a06484',
    qyt: '3'
  },
  {
    addr: '0x8CcfAE8E16C6c1004Bbc8E41BD418C5FCB9fCF95',
    qyt: '2'
  },
  {
    addr: '0x0403Cbc9C56eaBB90bbD403acFe2F5e1B1e41485',
    qyt: '2'
  },
  {
    addr: '0x7Aa3A89393347af278da997Ad87B39a9ddE216Eb',
    qyt: '1'
  },
  {
    addr: '0xD3d0aa0C0280Bd0B44Ee17A77bf15F2e762B43E1',
    qyt: '6'
  },
  {
    addr: '0x6c227b06518b39212A16038a07af8A7DdD3e464D',
    qyt: '3'
  },
  {
    addr: '0xC8E9B93354912cd6E7007EB52967996FD972e227',
    qyt: '2'
  },
  {
    addr: '0x1FeFaaD64ee529495cE583Ed88456222b9515088',
    qyt: '8'
  },
  {
    addr: '0xd5ff2970a1f4D8f9873be0BFe75E83494017242B',
    qyt: '2'
  },
  {
    addr: '0x2Ee18a952d584A8C83F2968c8794293cAb1f24FD',
    qyt: '7'
  },
  {
    addr: '0x40A80341262793b6b99A35F5243Ce6E4600B9ff7',
    qyt: '6'
  },
  {
    addr: '0x3EF2d194b5D543fa800C8C9ebCcEee89E68Dd266',
    qyt: '5'
  },
  {
    addr: '0x3BD09b06D2d7384A19d43337F689608d07D0Fa98',
    qyt: '5'
  },
  {
    addr: '0x70F85Ca118b98330124E966d5369d2E505672Ded',
    qyt: '5'
  },
  {
    addr: '0x3b78119ac14Ab702cCc8acE4182f1af7620fFEfA',
    qyt: '6'
  },
  {
    addr: '0x8938f4a30BC139d21C743B28fB30513AF1C5d404',
    qyt: '6'
  },
  {
    addr: '0xbF900Dd37fbeD837Fc23F5C40De045b5fAf18974',
    qyt: '6'
  },
  {
    addr: '0x5fbE1d1Dc6b0647D2B9046F9b1F1F10456493959',
    qyt: '6'
  },
  {
    addr: '0xe653F1f382Cea4d5b3Ebf54b83a5f36760827DDb',
    qyt: '8'
  },
  {
    addr: '0xfcC5424b928Ae666a1462eff51a83272202638bB',
    qyt: '6'
  },
  {
    addr: '0x16Fc4d16941F3aFF0829D5a3B8E967BC15C7D0C4',
    qyt: '7'
  },
  {
    addr: '0x9A8Ae1d0F6517a1c7b1d256B665a0eb4C168c955',
    qyt: '3'
  },
  {
    addr: '0x634e3Bda75b3A66cC49cFd7787EC83E6C9F4318e',
    qyt: '3'
  },
  {
    addr: '0xb2b2cCC3591DFA73c98d054B6Dc773AEC727888F',
    qyt: '2'
  },
  {
    addr: '0x6be8bB1FF7A784DbfbEA89B7AE65cD26c200931c',
    qyt: '6'
  },
  {
    addr: '0x41bC9b35Dcc7D5682443E1bdc798f55159fAA402',
    qyt: '5'
  },
  {
    addr: '0x249efE6b118CcBC417f40a6b79dC8a7D57Bb7325',
    qyt: '5'
  },
  {
    addr: '0xC6dbB33d92F221614265bbABfC3A747916F144eF',
    qyt: '5'
  },
  {
    addr: '0x21fb67BA18E64Ea1f5Fa65B070b076C23E4B57b7',
    qyt: '8'
  },
  {
    addr: '0xabb06490391D5D88E029a4e1EBB48340cE4BC0a1',
    qyt: '3'
  },
  {
    addr: '0x3f408304DfC8bb5f76807e8dF1CbDd6Ecf89231d',
    qyt: '5'
  },
  {
    addr: '0x95FFAe01Cf8e599E80693CbD9A940ee2B6bb2fDF',
    qyt: '8'
  },
  {
    addr: '0xCEB2Da26D39d199C6C080B82826fF2F6F801c543',
    qyt: '5'
  },
  {
    addr: '0x56a744916d64d93940b4ed0adFec4593A03a7F89',
    qyt: '5'
  },
  {
    addr: '0x66Ed90473f52804253aeCedA700c8215c9b76a0E',
    qyt: '8'
  },
  {
    addr: '0x4f65A6F4763D760A04e5756Eb68D1b46AA65BcA1',
    qyt: '3'
  },
  {
    addr: '0x9ecFEd3e84c15A52fBD2423520d8eCC45B1b5Fd8',
    qyt: '3'
  },
  {
    addr: '0x8CbE6E36585E0d03e0b6775cE6813CF8e6D99Fe0',
    qyt: '3'
  },
  {
    addr: '0x976032Cd740d930182C3E494Ef6DFf7572131a1a',
    qyt: '6'
  },
  {
    addr: '0x60Ffc78CB65E6357197CAe3b1FDC0B6C2cb9188A',
    qyt: '5'
  },
  {
    addr: '0xe11F578a8F9C9457Cd2DC5aca70dff775d6EC7aa',
    qyt: '1'
  },
  {
    addr: '0x4026E21C1d17A983f92e062B23Eb2dB0a9db0CF6',
    qyt: '1'
  },
  {
    addr: '0xE18FB16ad2f0b9d735494Fdf2468d9428265fA73',
    qyt: '1'
  },
  {
    addr: '0x01eBbF679237Dc99e6FB4E48fC6886FE3DEABFE8',
    qyt: '1'
  },
  {
    addr: '0xDb4B49258CaEDcD94435bd2B5cB682E277c396e1',
    qyt: '1'
  },
  {
    addr: '0xDb9e48B700ac5FA60E17f19831b93A8888910D56',
    qyt: '1'
  },
  {
    addr: '0x8698327A7e55446F7558F03faAff08AB6BB45a76',
    qyt: '1'
  },
  {
    addr: '0xB13127C3FB570126Ec66465bD376AA88c56f4cD8',
    qyt: '1'
  },
  {
    addr: '0x350b3933a8A2d4fbA19E6664d87741A0e501ae0B',
    qyt: '1'
  },
  {
    addr: '0xD27EaC22aD3F253249020D2B6F167D8721fA0e15',
    qyt: '1'
  },
  {
    addr: '0xE88f8E58f7bEE6930B1D77c07DBE44C4A75ef597',
    qyt: '3'
  },
  {
    addr: '0x8679d106fAbD72f9C585d4d61C5071dF955e3b2f',
    qyt: '5'
  },
  {
    addr: '0x3442B585Dd777ce1C851e570524A85e3cb629Bf8',
    qyt: '3'
  },
  {
    addr: '0x81EF799270A6aDd2e9f606cdf62371022D288970',
    qyt: '3'
  },
  {
    addr: '0x288269Fb4535187A926e9748bb00aEDECEc6f8FA',
    qyt: '3'
  },
  {
    addr: '0x2015801a9AC617F3B6Ae5fc6aAe7A00B10D0FF50',
    qyt: '3'
  },
  {
    addr: '0x4C4f6dB1Cb320709b8ec596807d4eD4e2F782276',
    qyt: '5'
  },
  {
    addr: '0x0Fd7d5995426A0e0c32Ee5849300f40a972F135E',
    qyt: '6'
  },
  {
    addr: '0x6bcA02442327c1A8ffcD585C5a5b541127F4f0c4',
    qyt: '3'
  },
  {
    addr: '0x587e680A75D0571EC1030C3D77bc7aaD6ba56d60',
    qyt: '3'
  },
  {
    addr: '0xc7D75736087752e4435031b0f23f751f8A9c9a1A',
    qyt: '3'
  },
  {
    addr: '0x47a22837Add15099e1d970E5bd342211bA9dedA2',
    qyt: '4'
  },
  {
    addr: '0xA81f55e35b7E930786e78deFC44daE01B585DE79',
    qyt: '5'
  },
  {
    addr: '0x93c657CD50c69E635682de373B061B35A86580ed',
    qyt: '3'
  },
  {
    addr: '0xFBdF040A710d000640614efdcA540F20Cf1C52d7',
    qyt: '3'
  },
  {
    addr: '0x02326B975ca01d3E75FCBDC1E1C05AF8b23767b0',
    qyt: '3'
  },
  {
    addr: '0x13e6Ec609bd2D26d614455d634d7851bc2109723',
    qyt: '3'
  },
  {
    addr: '0xFE955fde405C11d60cD50a549480f718cE7cEc50',
    qyt: '3'
  },
  {
    addr: '0x100E6E01Cf52D3757e963B1bcCF5022A613C3087',
    qyt: '3'
  },
  {
    addr: '0xE67429989e73A8535AbAc455bD5f2f290ea96f51',
    qyt: '3'
  },
  {
    addr: '0x9E611102459547a8Bb39BeE64F2C050970e92BE5',
    qyt: '3'
  },
  {
    addr: '0x0F4030315ED70fB54B577Ca5fD629d48337d634F',
    qyt: '4'
  },
  {
    addr: '0x15ACc68B6912Fe1c1C984ed8a1996Cc4cdb5CF65',
    qyt: '3'
  },
  {
    addr: '0x72Ec87bc76F70022706D30cb742C6Ea9EeAEFA70',
    qyt: '4'
  },
  {
    addr: '0x4786F9A5610B0f7e7a9364b2C8328e82cF2bf5a1',
    qyt: '5'
  },
  {
    addr: '0x93600c223dA5C7dd894556c042E0A33Ed74c4697',
    qyt: '3'
  },
  {
    addr: '0xC43f4C126973392C76eCEc0E7e58B4253079e494',
    qyt: '4'
  },
  {
    addr: '0xaBAdCfF9dF4Dd0d893793De0d59D06e381204f07',
    qyt: '3'
  },
  {
    addr: '0x5F8E6e0b940DA6b5F8c01Fe1A427B92bE68Cb63D',
    qyt: '5'
  },
  {
    addr: '0xcA3c0D9Be8e85d5dc43a8806423B571C3337FA98',
    qyt: '3'
  },
  {
    addr: '0x31b2957f8bC922c8F0BD0688C65d761354d11b72',
    qyt: '3'
  },
  {
    addr: '0x0FA8FB6F1b784F9fc46146C450F00E1C7d3C9029',
    qyt: '3'
  },
  {
    addr: '0x7a4332Cc77031186FE91C2598859603e86a2eD35',
    qyt: '7'
  },
  {
    addr: '0x65F6b329F0c436118ed5083dc11ccAd6b2BbDcff',
    qyt: '3'
  },
  {
    addr: '0x4D271d251C60561F505E9fb0Ba27Fee6587565c8',
    qyt: '3'
  },
  {
    addr: '0x62Ee7d7CB5760b0bed97e21a08aa33f34886da0A',
    qyt: '5'
  },
  {
    addr: '0x969C5eF5725c2a89106389e118eE3623Deca347d',
    qyt: '3'
  },
  {
    addr: '0x2b2c83631F2E7D2f35F9f49cf1c211e948a1a014',
    qyt: '3'
  },
  {
    addr: '0xddA189B70d820675BB0412C3DEdc2bb4A53acafa',
    qyt: '8'
  },
  {
    addr: '0x988CC39B22C3ead4207a2FAA95c986D451c7905A',
    qyt: '4'
  },
  {
    addr: '0xFdCf89312Ea23e1CA9E7a6D7aEe5437F3257e5DA',
    qyt: '3'
  },
  {
    addr: '0xe39a7b128f82c2968295F7Dc5f172125395487bA',
    qyt: '3'
  },
  {
    addr: '0x446410Cd3B39172dEb702c0376900AC6C9BfDBeF',
    qyt: '4'
  },
  {
    addr: '0x53F166DE3c63b8d40b7fDad0c529A355aFE08217',
    qyt: '4'
  },
  {
    addr: '0xeC9931B24e57dE9eD979b4E0694F248eE9384f38',
    qyt: '3'
  },
  {
    addr: '0x69A118f570Af00DA8D86D735F2501ff4d7D7C8a2',
    qyt: '4'
  },
  {
    addr: '0x3c1350AafbA80eFD80e96779a93fEE61A11fa50F',
    qyt: '6'
  },
  {
    addr: '0xe49698678099f0950F3c57540d8b13C8A63FaE60',
    qyt: '5'
  },
  {
    addr: '0x00024D5C26E874f0006BBc81F38716d73D8C6B1B',
    qyt: '3'
  },
  {
    addr: '0x4D773D656E367E8bbDbBA866031581d5F8fdF5a2',
    qyt: '3'
  },
  {
    addr: '0x1375A45B0f3855ec12Ede3A70C294122fe439d9B',
    qyt: '3'
  },
  {
    addr: '0x413e24d22740E91622B49220F39651ff01FceEE5',
    qyt: '3'
  },
  {
    addr: '0x50f39d8B8EB8CbD3ACF589077AF7Fc74ce00b1cE',
    qyt: '4'
  },
  {
    addr: '0xfA99aA4277Ab4C289af977f6F568985841472be2',
    qyt: '3'
  },
  {
    addr: '0x72715a22A00e2C838ec1F5e8A8431120fCd8F438',
    qyt: '3'
  },
  {
    addr: '0x83FD91d1a1533e8DCA5893D2CE359aD44cC9F980',
    qyt: '3'
  },
  {
    addr: '0x6ec97c95eE20f879E459c34ee1f4509d797B03d6',
    qyt: '3'
  },
  {
    addr: '0xA1b6D5a64d1FE2Dd4b27F41824C01e7801798Ca8',
    qyt: '3'
  },
  {
    addr: '0x82Ae1704bCf55DA1B8806Ab2f57a28D04DC174a0',
    qyt: '3'
  },
  {
    addr: '0xF0844FA1Be40BF67fABeA19Da2C44A86cDf0C973',
    qyt: '3'
  },
  {
    addr: '0x9a9b110d96e21C61CC2Ccb57eAc153E6511d2Df1',
    qyt: '3'
  },
  {
    addr: '0x3427a1B19Da26D6B67bC2a82D94Df7116E1159de',
    qyt: '3'
  },
  {
    addr: '0x07d8dA217108Dd63e08074e9ebf3Dc48720dFF96',
    qyt: '3'
  },
  {
    addr: '0x698Fee42E8b4165764f0881460D084E4b0B86cAF',
    qyt: '3'
  },
  {
    addr: '0xd88BcC9375D4336296e8E4898c6DeAfb5DFa1C32',
    qyt: '3'
  },
  {
    addr: '0x50f0dc052b12d55656CD5F6F4A5973cAb217441d',
    qyt: '3'
  },
  {
    addr: '0x0108aBDAB17C76Db57442072506C68023927bd20',
    qyt: '4'
  },
  {
    addr: '0xbD793d8E9A131b9d31D8b3695676C0a408c0FDC4',
    qyt: '3'
  },
  {
    addr: '0x02Ec20AB6142dfB5e6A530e4e78dBeD74A509834',
    qyt: '3'
  },
  {
    addr: '0xabe9a06BCcb742198073DBF1c77FEaF33FE9EeD2',
    qyt: '5'
  },
  {
    addr: '0xcE5C5E07c3B9FBF20fD5E0A3F219c1Bbb6f5892a',
    qyt: '3'
  },
  {
    addr: '0x8b811A749B3D1a2eC76226EE5fABade515E34068',
    qyt: '5'
  },
  {
    addr: '0x856eeD34CC11F77B1a9b545fEbFA7Af1c6F699d8',
    qyt: '2'
  },
  {
    addr: '0x29e7ac33f1278c6512bfCE63aAD2ab18BFCA8f03',
    qyt: '3'
  },
  {
    addr: '0xCDc001170D47096382f3dB6601f6eE755bc6256c',
    qyt: '3'
  },
  {
    addr: '0x613F63Eb92BaC099123A2A4e221D0f9b7Ccc3ff1',
    qyt: '3'
  },
  {
    addr: '0x6c51a39ABf9cAD2f624b5D7cD275C79cdDE5d393',
    qyt: '3'
  },
  {
    addr: '0x0B062dB111E1Bc4D968aa804b3E6F753399154f1',
    qyt: '3'
  },
  {
    addr: '0x6cBBe51598d0aBA176B8e2A6FFFf70a4962d3452',
    qyt: '6'
  },
  {
    addr: '0x1688571a589276fdddc8fcc492b1e023c4ddd6c9',
    qyt: '8'
  },
  {
    addr: '0xa69303D076dFb54d50589C4D018205a409Aa4293',
    qyt: '5'
  },
  {
    addr: '0x41E3FE77DE1EcA115902eB058b1FB57395358d62',
    qyt: '8'
  },
  {
    addr: '0xa1d0B3B360595A82dbAAC1667535579bC568F8DD',
    qyt: '5'
  },
  {
    addr: '0x4a3aE87F2A1d6B93a1c9eb5189d198b44468F33C',
    qyt: '6'
  },
  {
    addr: '0x9A110810E47cc35298433f75ed11159554f36bF3',
    qyt: '6'
  },
  {
    addr: '0x475B11332de3b456D1c3472FB30c6d9E52b75C5C',
    qyt: '6'
  },
  {
    addr: '0x82Ba7508f7F1995AB1623258D66Cb4E2B2b8F467',
    qyt: '5'
  },
  {
    addr: '0x81DC6F15eE72F6E6d49CB6Ca44C0Bf8E63770027',
    qyt: '5'
  },
  {
    addr: '0x9c2D043aAd476515da882DaA28e70C0dc7A63d67',
    qyt: '5'
  },
  {
    addr: '0xA040E70e576B239aa699c4d5f42Ae431611Ce6C7',
    qyt: '5'
  },
  {
    addr: '0xf4dB64663e888eEEe62A77EE49055f2e6B21f4e6',
    qyt: '6'
  },
  {
    addr: '0x545E333e083776F8ae69bD41B6268AeA07723C02',
    qyt: '5'
  },
  {
    addr: '0xFD5D6417fdcF02f0627aaFa9e594A67D4485642d',
    qyt: '3'
  },
  {
    addr: '0xa787A56c43847A3B5a0110d0B5E004E47F57B22e',
    qyt: '6'
  },
  {
    addr: '0xEdcc3DCe14437E78F878f5aDE23669061c10eD55',
    qyt: '6'
  },
  {
    addr: '0xA3374bddF3d2381B1bF2bd77e2020FFF2002df1E',
    qyt: '7'
  },
  {
    addr: '0x292955135CaBa25d6247f674e34fcFB339A00953',
    qyt: '7'
  },
  {
    addr: '0x52E7bdE89Fcbd1e1C656Db1C08DdE45D82447e25',
    qyt: '5'
  },
  {
    addr: '0x736011B7d04d8a014EFdAe6a653E3405f3CDC720',
    qyt: '3'
  },
  {
    addr: '0x0F3f647e19ddf45D1a963527Db2Af07c8175Db20',
    qyt: '3'
  },
  {
    addr: '0x9455FD942cA87446fD914e07C403C785bdcdE84E',
    qyt: '8'
  },
  {
    addr: '0x39330326D19e3e4eD83064bd9a4B46d8a816bf02',
    qyt: '8'
  },
  {
    addr: '0xD1Ab19Afe65571741B521239534EE4604dC25c8D',
    qyt: '8'
  },
  {
    addr: '0x628bB18b814c817338CF30912a8ef142345Bc3c9',
    qyt: '8'
  },
  {
    addr: '0x9e67D018488aD636B538e4158E9e7577F2ECac12',
    qyt: '8'
  },
  {
    addr: '0xD266d61ac22C2a2Ac2Dd832e79c14EA152c998D6',
    qyt: '8'
  },
  {
    addr: '0xF3C9eA1658997558E5EB76ae49bed1A9832E1801',
    qyt: '8'
  },
  {
    addr: '0xEaf3B28A87D498530cDC7f0700E70502CF896D3f',
    qyt: '8'
  },
  {
    addr: '0x90FFFbbdf770eFB530d950C24bf56a292CDab3F7',
    qyt: '8'
  },
  {
    addr: '0xfBa68B7D4F94Df4870b1e66dfC1299061eEdd98B',
    qyt: '8'
  },
  {
    addr: '0x7C28A20A66d687107dfA810566f237fb3810CBf4',
    qyt: '8'
  },
  {
    addr: '0xF20dc9b0e0AcB3Bb854E4c8dbf4B83E42Ec52Ae5',
    qyt: '5'
  },
  {
    addr: '0xFB578b964BF03b2495FA3eC57b4f8c9cD6710184',
    qyt: '8'
  },
  {
    addr: '0xceEf208EA056862D0B17B31d54534853Ef8DF348',
    qyt: '8'
  },
  {
    addr: '0xE3462680B4A93591B9Da3b054AA9f3757bc6B8b1',
    qyt: '8'
  },
  {
    addr: '0xF6f1640ed4B6Aba6807e84a85dDA657729A28935',
    qyt: '8'
  },
  {
    addr: '0x409428706FED12947d50f771868A397b35102d6c',
    qyt: '1'
  },
  {
    addr: '0x1Cd4240DA97DD6dEfec08ecc466A3a64Ed95e466',
    qyt: '1'
  },
  {
    addr: '0xe4E01E5F6a9c306Ad7EbCa53BEb943CF6B181045',
    qyt: '1'
  },
  {
    addr: '0xaA9eAbE68a36FD53667217aBb3aafBEb30B40eb9',
    qyt: '1'
  },
  {
    addr: '0x01717564d09a9fe70c8e49da91438e2ec0db4322',
    qyt: '1'
  },
  {
    addr: '0x02b889a95194009cf9f56746517261a7f5a903a9',
    qyt: '1'
  },
  {
    addr: '0x312cc68128bf11fde410fc8c5a9ebc61f25f86ff',
    qyt: '1'
  },
  {
    addr: '0xe2d8c832df23784057e470c9f3dc71c8070606a5',
    qyt: '1'
  },
  {
    addr: '0x223b0412bf1b3b66b571be70e6142290d019b5ae',
    qyt: '1'
  },
  {
    addr: '0x75BeD1c6C95eAEe030A75ba32EA3D9ADc4F48bd1',
    qyt: '3'
  },
  {
    addr: '0x496772f4f13aCB6DB798d766B396818Cf60ea594',
    qyt: '3'
  },
  {
    addr: '0x765F74c8866b7010826669B9dFA319c6d3508090',
    qyt: '5'
  },
  {
    addr: '0x58b70b505Ee36277f263d013aB5B3f4dCABE7556',
    qyt: '5'
  },
  {
    addr: '0x3Bf311B913bE360E4d6193415Ed6d62B9AA5d2bD',
    qyt: '1'
  },
  {
    addr: '0x78Ba2f918D852b4A1c75Fe006d743a53Aa1e7D17',
    qyt: '8'
  },
  {
    addr: '0xe97bDdEd8EB4E2367081D1149355571e126f1A33',
    qyt: '6'
  },
  {
    addr: '0x99611Cd42B6281a9cbDee97F9E4bff51a8B1c5cC',
    qyt: '8'
  },
  {
    addr: '0x8BA68EFA66530baD21839307183884F402A85492',
    qyt: '7'
  },
  {
    addr: '0x71Ee16945287e54279Ef0f35665C76D878A1C1ea',
    qyt: '7'
  },
  {
    addr: '0x9c10629C03c46ebEfe58A814Be9f45fd170CDc92',
    qyt: '6'
  },
  {
    addr: '0x47671B351A75A1dDD1c86eE38D5DD90e4910F01f',
    qyt: '6'
  },
  {
    addr: '0xfc7e832837B2458B1455e3B670833b6a0c71f7Cb',
    qyt: '8'
  },
  {
    addr: '0x8dAFD66b9c31C5A1bBF87040D1cC159bEb09c400',
    qyt: '1'
  }
]