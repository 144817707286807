import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-responsive-modal/styles.css'
import { useTranslation } from 'react-i18next'
import { logoutUser, updateUserAccount, loginStatus } from '../redux/actions'

import Header from './header'
import Footer from './footer'

const Layout = ({ onLogout, children }) => {
  const history = useHistory()

  const { t } = useTranslation('common')
  return (
    <div className='bg-custom-primary w-screen relative'>
      <div className='2xl:container m-auto relative'>
        <ToastContainer />
        <Header
          question=''
          buttonText={t('logOut')}
          onButtonClick={() => onLogout(history)}
        />
        <div className='py-2 md:py-10 2xl:container m-auto'>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth
  return {
    user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (history) => dispatch(logoutUser(history)),
    onUpdateUserProfile: (payload) => dispatch(updateUserAccount(payload)),
    checkKycStatus: (history) => dispatch(loginStatus(history))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)